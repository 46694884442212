import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Avatar from 'views/Avatar';
import Blog from 'views/Blog';
import Browser from 'views/Browser';
import Covid from 'views/Covid';
import Globe from 'views/Covid/Globe';
import EmpresasView from 'views/EmpresasView';
import FacturacionView from 'views/FacturacionView';
import HomeServices from 'views/HomeServices';
import Info from 'views/Info';

import { RouteWithLayout } from './common';
import { Main as MainLayout, Minimal, Minimal as MinimalLayout } from './layouts';

import {
  Home as HomeView,
  SignupSimple as SignupSimpleView,
  Login as LoginView,
  Viewer,
  AboutUs as AboutUsView,
  NotFound as NotFoundView,
  Promotions,
  Locations
} from './views';
import DicomView from 'views/DicomView';
import PacientesView from 'views/PacientesView';
import MedicosView from 'views/MedicosView';
import ViewerPublic from 'views/ViewerPublic';

const Routes = () => {
  return (
    <Switch>
      {/* <Redirect exact from="/" to="/home" /> */}
      <Redirect exact from="/" to="/promociones-mes" />
      <RouteWithLayout
        component={AboutUsView}
        exact
        layout={MainLayout}
        path="/home"
      />
      <RouteWithLayout
        component={Viewer}
        exact
        mustBeLoggedIn
        layout={MainLayout}
        path="/viewer"
      />
      <RouteWithLayout
        component={DicomView}
        exact
        mustBeLoggedIn
        layout={MainLayout}
        path="/dicom-viewer"
      />
      {/* <Route
        component={DicomView}
        path="/dicom-viewer"
      /> */}
      <RouteWithLayout
        component={Covid}
        exact
        layout={MainLayout}
        path="/covid-19"
      />
      <RouteWithLayout
        component={Promotions}
        exact
        layout={MainLayout}
        path="/promociones-mes"
      />
      <RouteWithLayout
        component={Browser}
        exact
        layout={MainLayout}
        path="/estudios"
      />
      <RouteWithLayout
        component={Locations}
        exact
        layout={MainLayout}
        path="/sucursales"
      />
      <RouteWithLayout
        component={HomeServices}
        exact
        layout={MainLayout}
        path="/servicios"
      />
      <RouteWithLayout
        component={Avatar}
        exact
        layout={MainLayout}
        path="/avatar"
      />
      <RouteWithLayout
        component={SignupSimpleView}
        exact
        layout={MainLayout}
        path="/signup-simple"
      />
      <RouteWithLayout
        component={LoginView}
        exact
        layout={MainLayout}
        path="/login"
      />
      <RouteWithLayout
        component={Info}
        exact
        layout={MainLayout}
        path="/info"
      />
      <RouteWithLayout
        component={AboutUsView}
        exact
        layout={MainLayout}
        path="/about-us"
      />
      <RouteWithLayout
        component={FacturacionView}
        exact
        layout={MainLayout}
        mustBeLoggedIn
        path="/facturacion"
      />
      <RouteWithLayout
        component={EmpresasView}
        exact
        layout={MainLayout}
        path="/empresas"
      />
      <RouteWithLayout
        component={PacientesView}
        exact
        layout={MainLayout}
        path="/pacientes"
      />
      <RouteWithLayout
        component={MedicosView}
        exact
        layout={MainLayout}
        path="/medicos"
      />
      <RouteWithLayout
        component={Globe}
        exact
        layout={MainLayout}
        path="/covid-globe"
      />
      <RouteWithLayout
        component={ViewerPublic}
        exact
        layout={MainLayout}
        path="/visor"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" status="404" />
    </Switch>
  );
};

export default Routes;
