import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, Paper, Dialog, Typography, InputBase, Button, Grid, useTheme, Divider, IconButton } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import Blog from '../Blog';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import useMeasure from 'react-use-measure'
import styled from 'styled-components';
import { teal } from '@material-ui/core/colors';
import Browser from 'views/Browser';
import Delivery from 'components/Delivery';
import { ArrowDownwardOutlined, ArrowForward, ArrowForwardIosOutlined } from '@material-ui/icons';
import DicomViewer from 'components/organisms/DicomViewer';
import Globe from './Globe';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import ModulesLink from 'components/ModulesLink';
const MaskPCR = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/torunda.png'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const MaskSuc = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/location.svg'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const MaskRes = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/notepad.svg'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const MaskGlobe = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/pandemia.svg'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const MaskAnt = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/muestra.png'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const MaskAntg = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/nariz.svg'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;

const useStyles = makeStyles(theme => ({
    root: { padding: 20 },
    formContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
        maxWidth: 500,
        margin: `0 auto`,
    },
    "@keyframes myEffect": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    },
    containerData: {
        animation: `$myEffect 300ms ${theme.transitions.easing.easeIn}`,
    },
    floatContainer: {
        position: 'relative',
        height: 'auto',
        padding: 16,
        width: '100%',
        zIndex: 50,
        [theme.breakpoints.up('md')]: {
            marginBottom: 16,
            paddingTop: 100,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '50%',
            height: '100%',
        },
    },
    floatBox: {
        backgroundColor: 'rgba(255,255,255,1)',
        borderRadius: 16,
        border: `solid 0.5px rgba(255,255,255,0.2)`,
        width: '100%',
        maxHeight: 300,
        maxWidth: 400,
        boxShadow: '0px 4px 24px rgba(100,100,100,1)',
        [theme.breakpoints.up('md')]: {
            width: 400,
        },
    },
    text: {
        marginBottom: 32,
        fontSize: 20,
        fontWeight: 800,
        minHeight: 'calc(100% - 100px)',
        color: 'gray',
        [theme.breakpoints.up('md')]: {
            fontSize: 20,
        },
    },
    input: {
        backgroundColor: 'rgba(255,255,255,0.8)',
        borderRadius: 16,
        width: '100%',
        minWidth: 200,
        marginBottom: 16,
        maxHeight: 300,
        boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
        overflowY: 'scroll',
        paddingLeft: 16,
        paddingRight: 16,
        minHeight: 50,
        transitionDuration: 500,
        border: `solid 1px ${theme.palette.primary.main}`,
        "&:focus,&:hover": {
            backgroundColor: 'rgba(255,255,255,1)',
            border: `solid 1px ${theme.palette.primary.main}`,
            boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 16,
        },
    },
    button: {
        borderRadius: 16,
        width: '100%',
        minWidth: 200,
        marginBottom: 16,
        maxHeight: 300,
        boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
        minHeight: 50,
        transitionDuration: 500,
        "&:focus,&:hover": {
            border: `solid 1px ${theme.palette.primary.main}`,
            boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 16,
        },
    },
    box: {
        display: 'flex',
        height: '70vh',
        backgroundColor: 'red'
    },
    imageFrame: {
        width: '100%',
        float: 'right',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    imageLayout: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        [theme.breakpoints.up('md')]: {
            width: '50vw',
            height: 'calc(100vh - 60px)',
        },
    },
    image: {
        width: '100%',
        minWidth: '100%',
        // maxWidth: 500,
        float: 'right',
        maxHeight: 'calc(100vh - 120px)',
        overflow: 'hidden',
        zIndex: 1000,
        marginBottom: 32,
        [theme.breakpoints.up('md')]: {
            minWidth: '60%',
        },
    },
    background: {
        width: '100%',
        height: 'calc(100vh - 60px)',
    },
    iconButton: {
        position: 'fixed',
        top: 8,
        right: 8,
        color: 'rgba(200,200,200,0.8)',
        backgroundColor: theme.palette.primary.main,
        opacity: 0.9
    },
    section: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    label: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    footer: {
        height: '13%',
        backgroundColor: '#ec008c',
        posi: 'right',
        bottom: 0,
        width: '100%',
        zIndex: 0
    }
}));
const month = (new Date()).getMonth() + 1;

const Covid = () => {
    const classes = useStyles();
    const [ref, { height, width }] = useMeasure();
    const [open, setOpen] = useState(false);
    const [column, { columnH, columnW }] = useMeasure();
    const theme = useTheme();
    const data = {
        "month": "07",
        "media": [
            { "type": "jpg", "name": "1" },
            { "type": "jpg", "name": "2" },
            { "type": "jpg", "name": "3" }
        ]
    };
    const dataBase = {
        "month": "07",
        "media": [
            { "type": "png", "name": "1" },
            { "type": "png", "name": "2" },
        ]
    };
    const backgrondsColors = [
        'rgba(168,126,226,1)',
        'rgba(95,176,143,1)',
        'rgba(211,116,177,1)',
    ]
    const portland = !!(window.screen.height > window.screen.width);
    console.log('is port', portland);
    return (
        <Box key={'pro'} ref={ref} style={{ height: '100vh', overflow: 'hidden', overflowY: 'scroll' }}>
            <Box style={{ position: 'relative' }}>
                {/* <Grid container justifyContent='center' className={classes.floatContainer}>
                    <Grid p={5} className={classes.floatBox} >
                    <Grid item >
                        <Box p={6} className={classes.floatBox}>
                            <Typography className={classes.text} >
                                {'Cotiza tu prueba COVID-19...'}
                            </Typography>
                            <InputBase autoFocus endAdornment={<ArrowForward color='primary' />} placeholder='Buscar' className={classes.input} onChange={() => setOpen(true)} onClick={() => setOpen(true)} />
                         
                        </Box>
                    </Grid>
                </Grid>
                <Carousel autoPlay infiniteLoop interval={3000} showThumbs={false} showStatus={false}>
                    {
                        [
                            'flujo',
                        ].map((item, i) => {
                            return (
                                <Box key={i} padding={0}
                                    style={{
                                        height: portland ? '100%' : '50vw',
                                        width: '100%',
                                        // backgroundColor: 'red'
                                        // backgroundColor: `${backgrondsColors[i] || 'whiteSmoke'}`,
                                    }}
                                >
                                    <Box
                                        style={{
                                            // backgroundImage: 'linear-gradient(to bottom right, red, yellow)'
                                            position: 'absolute',
                                            width: '100%',
                                            top: 0,
                                            height: '100%',
                                            zIndex: -1000
                                        }}
                                    >
                                    </Box>
                                    <Box className={classes.image}>
                                        <Box
                                            // className={classes.layoutImage}
                                            style={{
                                                backgroundColor: `${backgrondsColors[i] || 'whiteSmoke'}`,
                                                height: portland ? '100%' : '50vw',
                                                width: '100%',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                zIndex: -1
                                            }}
                                        />
                                        <img key={item} src={`/images/covid/${item}.jpeg`} className={classes.imageFrame} style={{ float: 'right', height: portland ? '100%' : width / 2, width: portland ? width : width / 2.5 }} />
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </Carousel> */}
                <ResponsiveLayout>
                    <Box p={2}>
                        <Typography color='primary' variant={'h5'} style={{ textAlign: 'center', width: '100%', fontWeight: 800, marginTop: 10, marginBottom: 10 }} className={classes.subTitles} >
                            {'Pruebas COVID-19'}
                        </Typography>
                    </Box>
                    <Box
                        p={2}
                        style={{
                            width: '100%', flexDirection: 'column', overflow: 'hidden', overflowX: 'auto',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        <Box p={4} style={{
                            borderRadius: 20,
                            border: '3px solid white',
                            boxShadow: '0px 0px 10px gray',
                            backgroundColor: theme.palette.primary.main,
                            position: 'relative',
                        }} >
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant={'h5'} style={{ textAlign: 'center', width: '100%', fontWeight: 800, color: 'white', paddingBottom: 16, whiteSpace: 'normal' }}  >
                                        {'No requieres cita, es fácil y muy rápido'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider style={{ backgroundColor: 'white' }} />
                        </Box>
                        <Grid spacing={2} container direction='row' style={{ height: '100%', marginBottom: 80 }}>
                            {[
                                {
                                    mask: MaskPCR,
                                    nombre: 'Prueba PCR',
                                    muestra: 'Muestra nasal y oral',
                                    tiempoResultados: 'El mismo dia (muestras tomadas antes de las 10 am)',
                                    recomentaciones: [
                                        'Prueba confirmatoria para COVID-19',
                                        'En personas con sospecha de COVID-19 con o sin síntomas.',
                                        'Se recomienda realizarla durante los primeros 7 días.',
                                        'Cuando tu médico lo indique.',
                                    ],
                                    observaciones: [
                                        '** Prueba más solicitada para viajes internacionales (consulta con tu agencia de viajes) e intervenciones médicas.',

                                    ]
                                },
                                {
                                    mask: MaskAntg,
                                    nombre: 'Prueba de Antígeno',
                                    muestra: 'Muestra nasal',
                                    tiempoResultados: 'El mismo dia (hasta en 15 minutos).',
                                    recomentaciones: [
                                        'En personas con sospecha de COVID-19.',
                                        'Se recomienda realizarla en pacientes con síntomas.',
                                        'Cuando tu médico lo indique.',
                                    ],
                                    observaciones: [
                                        '** Si el resultado es negativo en presencia de síntomas se recomienta realizar prueba de PCR.',
                                    ]
                                },
                                {
                                    mask: MaskAnt,
                                    nombre: 'Prueba de Anticuerpos',
                                    muestra: 'Muestra de sangre',
                                    tiempoResultados: 'El mismo dia',
                                    recomentaciones: [
                                        'Detectar la presencia de anticuerpos post-COVID-19 y/o post-vacuna.',
                                        'Cuando tu médico lo indique.',
                                    ],
                                    observaciones: [
                                        '** No se recomienda para el diagnóstico de COVID-19.',
                                    ]
                                }
                            ].map((item, index) => {
                                const Mask = item.mask;
                                return (
                                    <Grid key={index} item xs={portland ? 12 : 4} style={{ position: 'relative' }}>
                                        <Box style={{ height: 40, width: '100%', display: 'flex', justifyContent: 'center', zIndex: 1000 }} />
                                        <Box style={{ position: 'absolute', top: 40, left: 0, width: '100%', display: 'flex', justifyContent: 'center', zIndex: 1000 }}>
                                            <Box style={{
                                                backgroundColor: theme.palette.secondary.main,
                                                boxShadow: '0px 0px 10px gray',
                                                float: 'center',
                                                alignSelf: 'center', border: '2px solid white', borderRadius: 40, padding: 8
                                            }}>
                                                <Mask style={{ backgroundColor: 'white', alignSelf: 'center' }} />
                                            </Box>
                                        </Box>
                                        <Box p={4} style={{
                                            borderRadius: 20,
                                            border: '3px solid white',
                                            boxShadow: '0px 0px 10px gray',
                                            left: 8,
                                            right: 8,
                                            top: 48,
                                            bottom: 8,
                                            backgroundColor: theme.palette.primary.main,
                                            position: 'absolute',
                                            marginTop: 34,
                                            paddingTop: 40
                                        }} />
                                        <Box p={3} style={{
                                            // borderRadius: 20,
                                            // border: '3px solid white',
                                            // boxShadow: '0px 0px 10px gray',
                                            // backgroundColor: 'transparent' || theme.palette.primary.main,
                                            position: 'relative',
                                            marginTop: 34,
                                            paddingTop: 40
                                        }} >
                                            <Grid container>
                                                <Grid item xs>
                                                    <Typography variant={'h6'} style={{ textAlign: 'center', width: '100%', fontWeight: 800, color: 'white', paddingBottom: 16 }}  >
                                                        {item.nombre}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider style={{ backgroundColor: 'white' }} />
                                            <Box p={0}>
                                                <Grid container>
                                                    <Typography variant={'caption'} style={{ textAlign: 'center', width: '100%', color: 'white', fontSize: 18, whiteSpace: 'normal', padding: 5 }}  >
                                                        {`(${item.muestra})`}
                                                    </Typography>
                                                </Grid>
                                                {
                                                    item.recomentaciones.map((r, i) => {
                                                        return (
                                                            <Grid container>
                                                                <Grid item xs='auto'>
                                                                    <Box p={1.5}>
                                                                        <Box style={{ width: 6, height: 6, borderRadius: 3, backgroundColor: 'white', boxShadow: '0px 0px 3px gray' }} />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <Typography variant={'caption'} style={{ textAlign: 'justify', float: 'left', width: '100%', color: 'white', fontSize: '0.9rem', whiteSpace: 'normal' }}  >
                                                                        {r}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })
                                                }

                                            </Box>

                                        </Box>
                                        <Box position={'absolute'} style={{
                                            top: '100%',
                                            left: 8,
                                            right: 8,
                                        }}>
                                            {
                                                item.observaciones.map((o, i) => {
                                                    return (
                                                        <Grid container height={70}>
                                                            <Grid item xs>
                                                                <Typography variant={'caption'} style={{ textAlign: 'center', float: 'left', width: '100%', color: 'gray', fontSize: 14, whiteSpace: 'normal' }}  >
                                                                    {o}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>
                    <Box p={2}>
                        <Typography color='primary' variant={'h5'} style={{ textAlign: 'center', width: '100%', fontWeight: 800, marginTop: 10, marginBottom: 10 }} className={classes.subTitles} >
                            {/* {'Información COVID-19'} */}
                        </Typography>
                    </Box>
                    <Box
                        p={2}
                        style={{
                            width: '100%', flexDirection: 'column', overflow: 'hidden', overflowX: 'auto',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        <ModulesLink />
                    </Box>
                </ResponsiveLayout>
            </Box>
            <Dialog open={open}
                onClose={() => setOpen(false)}
                PaperProps={{ style: { backgroundColor: 'transparent', borderRadius: 8 } }}
            >
                <Delivery covid />
            </Dialog>

            {/* <Typography color='primary' variant={'h5'} style={{ textAlign: 'center', width: '100%', fontWeight: 800, marginTop: 30, marginBottom: -20 }} className={classes.subTitles} >
                {'Busca tus estudios COVID-19'}
            </Typography>
            <Browser title='Paquetes para tu salud' withOutHeader search={'covid'} /> */}
            {/* <Globe /> */}
            <Blog />
        </Box>
    );
};

export default Covid;



// import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import { Box, Grid, ListItem, Paper, Typography, Divider, Button, InputBase, Popover, ButtonGroup, List, debounce, CircularProgress, Dialog, Slide } from '@material-ui/core';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import useMeasure from 'react-use-measure'
// import { Carousel } from 'react-responsive-carousel';
// import internalAPI from '../../internalAPI';
// import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

// import areas from '../../constants/areas.json';
// import types from '../../constants/types.json';
// import { ArrowForward } from '@material-ui/icons';
// import { Modal } from '@material-ui/core';
// import ResponsiveLayout from 'layouts/ResponsiveLayout';
// import Blog from 'views/Blog';
// const useStyles = makeStyles(theme => ({
//     root: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         justifyContent: 'space-around',
//         alignContent: 'flex-start'
//     },
//     container: {
//         width: '100%',
//         border: `1px solid ${theme.palette.divider}`,
//         borderRadius: theme.shape.borderRadius,
//         backgroundColor: theme.palette.background.paper,
//         color: theme.palette.text.secondary,
//         '& svg': {
//             margin: theme.spacing(1.5),
//         },
//         '& hr': {
//             margin: theme.spacing(0, 0.5),
//         },
//     },
//     selector: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         height: 60,
//         width: '100%',
//         borderRadius: 16,
//         backgroundColor: 'white',
//         justifyContent: 'space-around',
//         alignContent: 'flex-start',
//         marginBottom: 60,

//     },
//     formContainer: {
//         height: '100%',
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'center',
//         minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
//         maxWidth: 500,
//         margin: `0 auto`,
//     },
//     box: {
//         display: 'flex',
//         height: '70vh',
//         backgroundColor: 'red'
//     },
//     image: {
//         width: '100%',
//         maxWidth: 500,
//         float: 'center',
//         boxShadow: '1px 1px 5px gray',
//         borderRadius: 10
//     },
//     iconButton: {
//         position: 'fixed',
//         top: 8,
//         right: 8,
//         color: 'rgba(200,200,200,0.8)',
//         backgroundColor: theme.palette.primary.main,
//         opacity: 0.9
//     },
//     section: {
//         paddingTop: 0,
//         paddingBottom: 0,
//     },
//     label: {
//         fontWeight: 'bold',
//         textTransform: 'uppercase',
//     },
//     containerTag: {
//         flexDirection: 'column',
//         borderRadius: 0,
//         boxShadow: '0px 0px 10px gray',
//         overflow: 'hidden',
//         [theme.breakpoints.up('md')]: {
//             flexDirection: 'row',
//             borderRadius: 16,
//             margin: '60px 0px 0px 0px',
//         },
//     },
//     tagLeft: {
//         backgroundColor: 'rgba(255,255,255,1)',
//         padding: 30,
//         [theme.breakpoints.up('md')]: {
//             padding: 40,
//         },
//     },
//     tagRight: {
//     },
//     input: {
//         backgroundColor: 'rgba(255,255,255,1)',
//         borderRadius: 16,
//         border: `solid 0.5px rgba(255,255,255,0.2)`,
//         padding: 8,
//         width: '100%',
//         margin: '24px 16px',
//         height: 'auto',
//         boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
//         transitionDuration: 200,
//         "&:focus,&:hover": {
//             backgroundColor: 'rgba(255,255,255,1)',
//             border: `solid 1px ${theme.palette.primary.main}`,
//             boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
//         },
//         [theme.breakpoints.up('md')]: {
//             margin: '60px 0px',
//             height: 60,
//         },
//     },
//     inputBase: {
//         flex: 1,
//         height: '100%',
//         padding: '5px 16px',
//         boxShadow: 'none',
//         "&:focus,&:hover": {
//             backgroundColor: 'primary',
//             border: 0,
//             boxShadow: 'none',
//         }
//     },
// }));

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

// const Covid = (props) => {
//     const selectType = types.find(e => e.id === props.view) || types[0];
//     const classes = useStyles();
//     const [screen, { height, width }] = useMeasure();
//     const [anchor, { ah, aw }] = useMeasure();
//     const [loading, setLoading] = useState(true);
//     const [select, setSelect] = useState(null);
//     const [openDialog, setOpen] = useState(false);
//     const [area, setArea] = useState(null);
//     const [type, setType] = useState(selectType);
//     const [searchText, setSearchText] = useState('');
//     const [anchorEl, setAnchorEl] = useState(null);
//     const [anchorElType, setAnchorElType] = useState(null);
//     const [data, setData] = useState([]);


//     const isMobile = width < 600;
//     console.log('ancho', anchorElType)
//     return (
//         <Box ref={screen} style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignContent: 'center' }}>
//             <ResponsiveLayout>
//                 <Box className={classes.root}>
//                     {
//                         <Box style={{ width: '100%', alignSelf: 'center', fontSize: 24, fontWeight: 800, textAlign: 'center' }}>
//                             <Grid container display='flex' className={classes.containerTag}>
//                                 <Grid className={classes.tagLeft} xs={isMobile ? 12 : 6} item >
//                                     <Typography color='primary' variant='h5' style={{ textAlign: 'left', width: '100%', marginBottom: 20 }}>
//                                         {'COVID-19'}
//                                     </Typography>
//                                     <Typography style={{ textAlign: 'left' }}>
//                                         {'Si la COVID-19 se propaga en su comunidad, manténgase seguro mediante la adopción de algunas sencillas medidas de precaución, por ejemplo, mantener el distanciamiento físico, llevar mascarilla, ventilar bien las habitaciones, evitar las aglomeraciones, lavarse las manos y, al toser, cubrirse la boca y la nariz con el codo flexionado o con un pañuelo. Consulte las recomendaciones locales del lugar en el que vive y trabaja. ¡Hágalo!'}
//                                     </Typography>
//                                 </Grid>
//                                 <Grid xs={isMobile ? 12 : 6} item style={{ marginBottom: -8 }}>
//                                     <img className={classes.tagRight} src={'images/layouts/5.jpeg'} height='100%' style={{objectFit:'cover'}} />
//                                 </Grid>
//                             </Grid>
//                         </Box>
//                     }
//                     <Blog/>
//                 </Box>
//             </ResponsiveLayout>
//         </Box>
//     );
// };

// export default Covid;
