import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { RemoveGlobalUser, removeIdPaciente, removeToken } from 'redux/session';
import { IconButton } from '@material-ui/core';
import { AccountCircleOutlined } from '@material-ui/icons';
import { setListPatients, setPatient, setResults, setResultsGrouped, setResultsImage } from 'redux/studies';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function AccountMenu(props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(removeToken());
    dispatch(removeIdPaciente());
    dispatch(RemoveGlobalUser());
    dispatch(setPatient(null));
    dispatch(setListPatients([]));
    dispatch(setResults([]));
    dispatch(setResultsGrouped([]));
    dispatch(setResultsImage([]));
    console.log('booooooooorroooo')
    history.push('/promociones-mes');
  };

  const handleClose = () => setAnchorEl(null);
  console.log('set', anchorEl);
  return (
    <React.Fragment>
      {/* {
        props.iconMode ?
          <IconButton
            onClick={handleClick}
            aria-label="Menu"
            aria-controls="account-menu"
          >
            <AccountCircleOutlined />
          </IconButton>
          :
          <Typography component="a" aria-controls="account-menu" aria-haspopup="true" onClick={handleClick}>
            Cuenta
          </Typography>
      } */}
      <IconButton
        onClick={handleClick}
        aria-label="Menu"
        aria-controls="account-menu"
      >
        <AccountCircleOutlined />
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <AccountBoxIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Perfil</Typography>
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Cerrar sesión</Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}