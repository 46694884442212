import { createSlice } from '@reduxjs/toolkit';

export const studiesSlice = createSlice({
  name: 'studies',
  initialState: {
    view: 'list',
    listPatients:[],
    resultsGrouped: [],
    resultsImage: [],
    patient: null,
    results: { groups: [] }
  },
  reducers: {
    setView: (state, action) => {
      state.view = action.payload;
    },
    setListPatients: (state, action) => {
      console.log('entro a actualizar listado de pacientes')
      state.listPatients = action.payload;
    },
    setPatient: (state, action) => {
      state.patient = action.payload;
    },
    setResults: (state, action) => {
      state.results = action.payload;
    },
    setResultsGrouped: (state, action) => {
      state.resultsGrouped = action.payload;
    },
    setResultsImage: (state, action) => {
      state.resultsImage = action.payload;
    }
  },
})

export const { setView, setResults, setResultsGrouped, setListPatients, setPatient, setResultsImage } = studiesSlice.actions;

export default studiesSlice.reducer;