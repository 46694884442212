import * as React from "react";
import { useForm } from "react-hook-form";
import model from 'constants/forms/registroPaciente.json'
import { Box, ListItem, Paper } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    "@keyframes inner": {
        "0%": {
            // opacity: 0,
            height: 0
        },
        "100%": {
            // opacity: 1,
        }
    },
    "@keyframes outer": {
        "0%": {
            // opacity: 1,
        },
        "100%": {
            // opacity: 0,
            height: 0
        }
    },
    in: {
        animation: `$inner 100ms ${theme.transitions.easing.easeIn} forwards`,
        boxShadow:'0px 3px 3px gray'
    },
    out: {
        animation: `$outer 100ms ${theme.transitions.easing.easeIn} forwards`,
        boxShadow:'0px 3px 3px gray'
    },
}));
const Select = ({ field, value, register, ...props }) => {
    const classes = useStyles();
    const [valueInner, setvalue] = React.useState(field.options.find(e => e.value == value) || { value: null, label: '' })
    const [options, setOptions] = React.useState(field.options || []);
    const [show, setShow] = React.useState(false);
    const select = (data) => {
        alert(JSON.stringify(data));
    };

    return (
        <Box style={{ position: 'relative' }} onBlur={() => setShow(false)}>
            <label className={props.classes?.label}>{field.vc_label}</label>
            <input value={valueInner?.value || ''} type={field.type || 'text'} {...register(field.vc_key, { required: field.required })} hidden style={{ width: 0, height: 0, display: 'none' }} />
            <input className={props.classes?.input} onFocus={() => {setShow(true)}} value={valueInner?.label} onChange={(e) => {
                setOptions(field.options.filter(d => JSON.stringify(d).toLowerCase().includes(e.target.value.toLowerCase())));
                setvalue({ value: null, label: e.target.value });
                setShow(true);
            }} type={field.type || 'text'} hidden style={{}} />
            <Paper className={show ? classes.in : classes.out} style={{ width: '100%', marginTop: -8, position: 'absolute', backgroundColor: 'white', zIndex: 5000, overflow: 'hidden' }}>
                {
                    (options || []).map((option, index) => {
                        return (
                            <ListItem button onClick={() => {
                                setvalue(option);
                                setShow(false);
                            }}>
                                {option.label}
                            </ListItem>
                        )
                    })
                }
            </Paper>
        </Box>
    );
};

export default Select;
