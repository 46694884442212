import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, IconButton, Collapse, Typography, Card, CardHeader, CardContent, CardMedia, CardActions, Avatar, Dialog, Divider, Paper, Grid, InputBase } from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import useMeasure from 'react-use-measure'
import serviciosDomicilio from '../../constants/serviciosDomicilio.json';
import Blog from '../Blog';
import clsx from 'clsx';
import { ArrowForward, ArrowForwardIosOutlined, Phone } from '@material-ui/icons';
import Delivery from 'components/Delivery';
import ReactPlayer from 'react-player';
import ResponsiveLayout from 'layouts/ResponsiveLayout';

const useStyles = makeStyles(theme => ({
  root: {
    animation: `$myEffect 300ms ${theme.transitions.easing.easeIn}`,
    display: 'flex', width: '100%', padding: 20, paddingTop: 0, flexDirection: 'column'
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    }
  },
  card: { width: 400, maxWidth: window.screen.width - 20, margin: 16, borderRadius: 30, boxShadow: '0px 0px 20px rgba(100,100,100,0.5)' },
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  box: {
    display: 'flex',
    height: '70vh',
  },
  floatContainer: {
    position: 'relative',
    height: 'auto',
    padding: 16,
    width: '100%',
    zIndex: 50,
    // [theme.breakpoints.up('md')]: {
    //   marginBottom: 16,
    //   paddingTop: 30,
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   width: '50%',
    //   height: '100%',
    // },
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0.5,
    },
    "100%": {
      opacity: 1,
    }
  },
  floatBox: {
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: 16,
    border: `solid 0.5px rgba(255,255,255,0.2)`,
    width: '100%',
    marginBottom: 24,
    maxWidth: 400,
    boxShadow: '0px 4px 24px rgba(100,100,100,1)',
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
  text: {
    marginBottom: 32,
    fontSize: 18,
    fontWeight: 800,
    minHeight: 'calc(100% - 100px)',
    color: 'gray',
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  input: {
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: 16,
    width: '100%',
    minWidth: 200,
    marginTop: 16,
    marginBottom: 0,
    maxHeight: 300,
    boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
    overflowY: 'scroll',
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: 50,
    transitionDuration: 500,
    border: `solid 1px ${theme.palette.primary.main}`,
    "&:focus,&:hover": {
      backgroundColor: 'rgba(255,255,255,1)',
      border: `solid 1px ${theme.palette.primary.main}`,
      boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  image: {
    width: '100%',
    maxWidth: 500,
    float: 'center',
    boxShadow: '1px 1px 5px gray',
    borderRadius: 10
  },
  iconButton: {
    position: 'fixed',
    top: 8,
    right: 8,
    color: 'rgba(200,200,200,0.8)',
    backgroundColor: theme.palette.primary.main,
    opacity: 0.9
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    justifyItems: 'center',
    alignContent: 'center',
    paddingTop: 24,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      padding: 0
    },
  },
}));
const month = (new Date()).getMonth() + 1;
const Promotions = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [ref, { height, width }] = useMeasure();
  const data = {
    "month": "06",
    "media": [
      { "type": "jpg", "name": "1" },
      { "type": "jpg", "name": "2" },
      // { "type": "jpg", "name": "3" },
      // { "type": "jpg", "name": "4" },
      // { "type": "jpg", "name": "5" },
      // { "type": "jpg", "name": "6" }
    ]
  };

  return (
    <div ref={ref} className={classes.root}>

      <Box style={{ alignSelf: 'center', position: 'relative' }}>
        <Box onClick={() => setOpen(true)} style={{ paddingBottom: -50, width: width }}>
          <Grid container justifyContent='center' className={classes.floatContainer}>
            {/* <Grid p={5} className={classes.floatBox} > */}
            <Grid item >
              <Box p={6} className={classes.floatBo} style={{ paddingTop: 24, paddingBottom: 24 }}>
                {/* <Typography className={classes.text} >
                  {'Te atendemos desde la comodidad y seguridad de tu casa'}
                </Typography> */}
                <InputBase autoFocus endAdornment={<ArrowForward color='primary' />} placeholder='Agenda tu cita...' className={classes.input} onChange={() => setOpen(true)} onClick={() => setOpen(true)} />
              </Box>
            </Grid>
          </Grid>
          <ReactPlayer
            key={'video'}
            width={width}
            url={'videos/domicilio.mp4'}
            height={'auto'}
            playing={true}
            controls={false}
            loop
          />

        </Box>
        <ResponsiveLayout>

          <Box p={2} className={classes.container}>
            {
              serviciosDomicilio.map((service, index) => {
                return (
                  <Card key={index} className={classes.card}>
                    <CardHeader
                      avatar={
                        <Avatar src={'/images/logos/logo-login.svg'} aria-label="recipe" className={classes.avatar} />
                      }
                      title={service.name}
                      action={
                        window.screen.width < 500 ?
                          <IconButton
                            href={window.screen.width < 500 ? 'tel:+52-667-758-2929' : undefined} aria-label="share"
                          >
                            <Phone />
                          </IconButton> :
                          undefined
                      }
                    />
                    <CardMedia
                      className={classes.media}
                      image={service.image}
                      title={service.name}
                    />
                    <Divider />
                    <CardContent>
                      <Typography key={index} variant='subtitle2' color="textSecondary" >
                        {'Servicios disponibles'}
                      </Typography>
                      <Box p={2}>
                        {
                          (service.services || []).map((ser, index) => {
                            return (
                              <Typography key={index} variant="body2" color="textSecondary" component="p">
                                {`- ${ser.name}`}
                              </Typography>
                            )
                          })
                        }
                      </Box>
                    </CardContent>
                    <Divider />

                    {
                      !(window.screen.width < 500) &&
                      <CardActions>
                        <IconButton
                        >
                          <Phone />
                        </IconButton>
                        <Typography style={{ marginLeft: 10 }}>
                          {'667-758-2929'}
                        </Typography>
                      </CardActions>
                    }

                  </Card>
                )
              })
            }
          </Box>
        </ResponsiveLayout>
      </Box>
      <Dialog open={open}
        onClose={() => setOpen(false)}
        fullWidth
        PaperProps={{ style: { backgroundColor: 'transparent', borderRadius: 8 } }}
      >
        <Delivery covidSelector={false} />
      </Dialog>
      <Blog />
    </div>
  );
};

export default Promotions;
