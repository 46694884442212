const repository = {
  getPromotions: async (setPromotions) => {
    console.log('entro')
    const url = `${process.env.REACT_APP_API_URL}/estudio/promociones`;
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors', 
    }).catch(e => console.log('error', e));

    console.log('res', res);
  }
}
export default repository;