import { Avatar, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
const config = {
    timePoints: 900,
    timeFlash: 2000,
    timeColoring: 1000,
    frame: 80,
    logoFrame: 80
}
const useStyles = makeStyles(theme => ({
    animation: {
        position: 'relative',
        animation: `$myEffect ${config.timePoints}ms infinite linear`,
        opacity: 1,
        transform: "rotate(-360)"
    },
    animation1: {
        position: 'relative',
        animation: `$myEffect ${config.timePoints}ms infinite linear`,
        opacity: 1,
    },
    animation2: {
        position: 'relative',
        animation: `$myEffect2 ${config.timeColoring}ms infinite linear`,
        opacity: 0.8,
    },
    animation3: {
        position: 'relative',
        animation: `$myEffect3 ${config.timeFlash}ms infinite linear`,
        width: config.frame,
        height: config.frame,
        opacity: 0.6,
    },
    "@keyframes myEffect": {
        "100%": {
            transform: "rotate(-360deg)"
        }
    },
    "@keyframes myEffect1": {
        "100%": {
            transform: "rotate(360deg)"
        }
    },
    "@keyframes myEffect3": {
        "50%": {
            opacity: 0.3,
            width: config.frame,
            height: config.frame,
        }
    },
    "@keyframes myEffect2": {
        "0%": {
            backgroundColor: 'red',
            opacity: 0.2
        },
        "20%": {
            backgroundColor: 'blue',
            opacity: 0.4
        },
        "40%": {
            backgroundColor: 'green',
            opacity: 0.2
        },
        "60%": {
            backgroundColor: 'violet',
            opacity: 0.4
        },
        "80%": {
            backgroundColor: 'gray',
            opacity: 0.2
        },
        "100%": {
            backgroundColor: 'red',
            opacity: 0.4
        }
    },
}));
const Loading = props => {
    const classes = useStyles();
    return (
        <Box display={'flex'} position={'absolute'} justifyContent={'center'} style={{ width: '100%', height: '100%' }}>
            <Box display={'flex'} position={'fixed'} justifyContent={'center'} style={{ width: '100%', height: '100%' }}>
                <Avatar className={classes.animation3} src='assets/images/logo192.png' style={{
                    display: 'flex', width: config.frame,
                    height: config.frame, alignSelf: 'center'
                }} />
            </Box>
            <Box display={'flex'} position={'fixed'} justifyContent={'center'} style={{ flexDirection: 'row', width: '100%', height: '100%' }}>
                <Box display={'flex'} position={'fixed'} justifyContent={'center'} style={{ width: '100%', height: '100%', marginTop: config.frame / 2 }}>
                    <Box className={classes.animation1} style={{ width: config.frame, height: config.frame, display: 'flex', alignSelf: 'center', position: 'relative' }} >
                        <Box className={classes.animation2} style={{ backgroundColor: 'gray', width: config.frame / 10, height: config.frame / 10, borderRadius: '50%', position: 'absolute', top: 0, right: 0 }} />
                        <Box className={classes.animation2} style={{ backgroundColor: 'gray', width: config.frame / 10, height: config.frame / 10, borderRadius: '50%', position: 'absolute', bottom: 0, left: 0 }} />
                    </Box>
                </Box>
                <Box display={'flex'} position={'fixed'} justifyContent={'center'} style={{ width: '100%', height: '100%', marginTop: (config.frame / 2) * -1 }}>
                    <Box className={classes.animation1} style={{ width: config.frame, height: config.frame, display: 'flex', alignSelf: 'center', position: 'relative' }} >
                        <Box className={classes.animation2} style={{ backgroundColor: 'gray', width: config.frame / 10, height: config.frame / 10, borderRadius: '50%', position: 'absolute', top: 0, right: 0 }} />
                        <Box className={classes.animation2} style={{ backgroundColor: 'gray', width: config.frame / 10, height: config.frame / 10, borderRadius: '50%', position: 'absolute', bottom: 0, left: 0 }} />
                    </Box>
                </Box>
            </Box>
            <Box display={'flex'} position={'fixed'} justifyContent={'center'} style={{ flexDirection: 'column', width: '100%', height: '100%' }}>
                <Box display={'flex'} position={'fixed'} justifyContent={'center'} style={{ width: '100%', height: '100%' }}>
                    <Box className={classes.animation1} style={{ width: config.frame, height: config.frame, display: 'flex', alignSelf: 'center', position: 'relative' }} >
                        <Box className={classes.animation2} style={{ backgroundColor: 'gray', width: config.frame / 10, height: config.frame / 10, borderRadius: '50%', position: 'absolute', bottom: 0, right: 0 }} />
                        <Box className={classes.animation2} style={{ backgroundColor: 'gray', width: config.frame / 10, height: config.frame / 10, borderRadius: '50%', position: 'absolute', top: 0, left: 0 }} />
                    </Box>
                    <Box className={classes.animation1} style={{ width: config.frame, height: config.frame, display: 'flex', alignSelf: 'center', position: 'relative' }} >
                        <Box className={classes.animation2} style={{ backgroundColor: 'gray', width: config.frame / 10, height: config.frame / 10, borderRadius: '50%', position: 'absolute', top: 0, left: 0 }} />
                        <Box className={classes.animation2} style={{ backgroundColor: 'gray', width: config.frame / 10, height: config.frame / 10, borderRadius: '50%', position: 'absolute', bottom: 0, right: 0 }} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
export default Loading;