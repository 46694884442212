import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Section, SectionAlternate } from 'components/organisms';
import { AccessTimeOutlined, Check, CheckOutlined, ExpandLessOutlined, ExpandMoreOutlined, ListOutlined, LocationSearchingOutlined, MapOutlined, MoreVertOutlined, Phone, PhoneAndroidOutlined, PinDropOutlined, Timelapse } from '@material-ui/icons';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import clsx from 'clsx';
import useMeasure from 'react-use-measure'
import sucursales from '../../constants/sucursales.json';
// import Maps from 'components/organisms/Maps';
import Maps from './Map';
import Blog from '../Blog';
import { Box, Collapse, Typography, Card, CardHeader, CardContent, CardMedia, CardActions, IconButton, Avatar, Dialog, Divider, Button, ButtonGroup, Menu, MenuItem, InputBase, debounce, Popover, Paper, List, ListItem, Grid, Slide, useMediaQuery, DialogTitle } from '@material-ui/core';
import { teal } from '@material-ui/core/colors';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import { useTheme } from '@material-ui/styles';
import { Image } from 'components/atoms';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw', overflow: 'hidden', minHeight: '100vh',
    animation: `$myEffect 300ms ${theme.transitions.easing.easeIn}`,
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    }
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  iconAction: {
    backgroundColor: 'white',
    boxShadow: '0px 0px 5px rgba(100,100,100,0.5)',
    marginLeft: 8
  },
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  map: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 500
    // backgroundColor: 'rgba(10,10,10,0.1)',
    // filter: 'blur(8px)',
  },
  float: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: 'rgba(10,10,10,0.1)',
    // filter: 'blur(8px)',
  },
  expandAction: {
  },
  cardContainer: {
    // width: '100%',
    maxWidth: '100%',
    padding: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  card: {
    width: '100%',
    maxWidth: '100%',
    boxShadow: '0px 0px 20px rgba(100,100,100,0.9)',
    alignSelf: 'center',
    flexDirection: 'column',
    display: 'flex',
    textAlign: 'left',
    margin: '0px 0px 30px 0px',
    borderRadius: 20,
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'left',
      width: 300
    },
  },
  cardPopup: {
    borderRadius: 24,
    position: 'relative'
  },
  box: {
    display: 'flex',
    height: '70vh',
    backgroundColor: 'red'
  },
  image: {
    width: '100%',
    maxWidth: 500,
    float: 'center',
    boxShadow: '1px 1px 5px gray',
    borderRadius: 10
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    justifyItems: 'center',
    alignContent: 'center',
    padding: theme.spacing(1),
    paddingTop: 24,

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      padding: 0
    },
  },
  iconButton: {
    position: 'fixed',
    top: 8,
    right: 8,
    color: 'rgba(200,200,200,0.8)',
    backgroundColor: theme.palette.primary.main,
    opacity: 0.9
  },
  logoImage: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      alignSelf: 'center',
    },
  },
  iconButtonPopup: {
    backgroundColor: 'white',
    boxShadow: '0px 0px 1px gray',
    margin: 3
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  inputContainer: {
    padding: '12px 4px',
    [theme.breakpoints.up('sm')]: {
      padding: 24,
      maxWidth: 800
    },
  },
  input: {
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: 16,
    border: `solid 0.5px rgba(255,255,255,0.2)`,
    padding: 0,
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
    position: 'relative',
    boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
    transitionDuration: 200,
    "&:focus,&:hover": {
      backgroundColor: 'rgba(255,255,255,1)',
      border: `solid 1px ${theme.palette.primary.main}`,
      boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  inputBase: {
    flex: 1,
    height: '100%',
    padding: '5px 16px',
    boxShadow: 'none',
    "&:focus,&:hover": {
      backgroundColor: 'inherit',
      border: 0,
      boxShadow: 'none',
    }
  }


}));
const citiesObj = {};

sucursales.map((suc) => {
  citiesObj[suc.city] = suc.city;
})
const month = (new Date()).getMonth() + 1;

const search = ({ lat, lng }) => {
  return new Promise((resolve, reject) => {
    window.$.ajax({
      url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyALFV0i5CA9nuaUYvabwL09uCsETeGWTQM`,
      method: "GET",
    })
      .then((data, status, xhr, error) => {
        const { results: options = [] } = data || {};
        resolve({ options });
      })
      .fail((data, status, xhr, error) => {
        reject("Error al obtener las localizaciones");
      });
  });
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const queryString = window.location.search; // Returns:'?q=123'
// Further parsing:
const params = new URLSearchParams(queryString);
const searchPath = params.get("location"); // is the number 123
const Locations = () => {
  const classes = useStyles();
  const [ref, { height, width }] = useMeasure();
  const [buttonRef, layout] = useMeasure();
  const cities = Object.values(citiesObj);

  const [options, setOptions] = React.useState(sucursales);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [select, setSelect] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [expandedFooter, setExpandedFooter] = React.useState(true);
  const [style, setStyle] = React.useState('map');
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const searchSucursal = debounce((text) => {
    let filter = sucursales.filter(e => selected.includes(e.city));
    if (searchText.length) {
      function changeComas(text) {
        return `${text}`.toLocaleLowerCase().replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u');
      }
      filter = sucursales.filter((e) => `${changeComas(JSON.stringify(e))}`.includes(changeComas(searchText)));
    }
    console.log('ssucsales', filter.length)
    setOptions(filter);
  }, 300);
  useEffect(() => {
    searchSucursal(searchText)
  }, [searchText])

  useEffect(() => {
    searchSucursal(searchText)
    if (selected.length === 0) {
      if (!searchPath) {
        setAnchorEl(true);
      } else {
        const citiesPrev = cities.find(e => searchPath.toLowerCase() === e.toLowerCase())
        if (!!citiesPrev) {
          setSelected([citiesPrev]);
        }
      }
    }
  }, [selected])


  const handleExpandFooterClick = () => {
    setExpandedFooter(!expandedFooter);
  };
  const mapsSelector = (location) => {
    if /* if we're on iOS, open in Apple Maps */
      ((navigator.platform.indexOf("iPhone") !== -1) ||
      (navigator.platform.indexOf("iPad") !== -1) ||
      (navigator.platform.indexOf("iPod") !== -1))
      window.open(`maps://maps.google.com/maps?daddr=${location.lat},${location.lng}&amp;ll=`);
    else /* else use Google */
      window.open(`https://maps.google.com/maps?daddr=${location.lat},${location.lng}&amp;ll=`);
  }
  const isMobile = width < 600;

  const renderSearchBar = (column) => {
    return (
      <ButtonGroup orientation={isMobile || !!column ? 'vertical' : 'horizontal'} className={classes.input} variant="contained" color="primary" aria-label="contained primary button group">
        {/* <ButtonGroup>
          <Button
            onClick={() => setStyle('list')}
            style={{ flex: 1, color: style === 'list' ? undefined : 'gray' }} color={style === 'list' ? 'primary' : undefined} variant='text' >
            <ListOutlined />
          </Button>
          <Button
            onClick={() => setStyle('map')}
            style={{ flex: 1, color: style === 'map' ? undefined : 'gray' }} color={style === 'map' ? 'primary' : undefined} variant='text' >
            <MapOutlined />
          </Button>
        </ButtonGroup> */}
        <Button
          ref={buttonRef}
          variant={'text'}
          endIcon={<ArrowDropDown />}
          onClick={(e) => {
            console.log('hola')
            setAnchorEl(true);
            // setAnchorEl(e.currentTarget);
          }}
        >
          {`${selected.length === 1 ? selected[0] : 'Ciudades'} ${selected.length ? `(${selected.length})` : ''}`}
        </Button>
        {
          console.log('JSON.stringify(selected)', JSON.stringify(selected))
        }
        <InputBase
          flex={1}
          placeholder={`Buscar sucursales, ciudades...`}
          onChange={(e) => setSearchText(e.target.value)}
          className={classes.inputBase}
          defaultValue={searchText}
          endAdornment={
            <Button
              onClick={() => {
                var options = {
                  enableHighAccuracy: true,
                  timeout: 5000,
                  maximumAge: 0
                };
                async function success(pos) {
                  var crd = pos.coords;
                  console.log('Your current position is:');
                  console.log('Latitude : ' + crd.latitude);
                  console.log('Longitude: ' + crd.longitude);
                  console.log('More or less ' + crd.accuracy + ' meters.');
                  const address = await search({ lat: crd.latitude, lng: crd.longitude });
                  console.log('adres', address, sucursales);
                  if (address.options) {
                    const city = address.options[0]?.address_components?.find(e => e.types.includes('locality'))?.long_name;
                    console.log('city', city);
                    setSelected(cities.filter(e => city.toLowerCase().includes(e.toLowerCase())))
                  }
                  console.log('window.google', window.google.maps);

                  const map = window.google.maps.map;
                  // the smooth zoom function
                  function animateMapZoomTo(map, targetZoom) {
                    var currentZoom = arguments[2] || map.getZoom();
                    if (currentZoom != targetZoom) {
                      window.google.maps.event.addListenerOnce(map, 'zoom_changed', function (event) {
                        animateMapZoomTo(map, targetZoom, currentZoom + (targetZoom > currentZoom ? 1 : -1));
                      });
                      setTimeout(function () { map.setZoom(currentZoom) }, 80);
                    }
                  }
                  map.setCenter(pos); // set map center to marker position
                  console.log('entro a zoom ------>')
                  animateMapZoomTo(map, 10);
                };

                function error(err) {
                  console.warn('ERROR(' + err.code + '): ' + err.message);
                  // setLoading(false);
                };


                window.navigator.geolocation.getCurrentPosition(success, error, options);
              }}
              endIcon={<LocationSearchingOutlined />}
              color={'primary'}
            >
              {'Ubicación'}
            </Button>
          }
        />
        <Dialog
          open={!!anchorEl}
          // anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          // anchorOrigin={{
          //   vertical: 'bottom',
          //   horizontal: 'left',
          // }}
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'left',
          // }}
          PaperProps={{ style: { borderRadius: 15, padding: 8 } }}
        >
          <DialogTitle>
            <Image
              className={classes.logoImage}
              src="/images/logos/logo.svg"
              alt="deliabarraza"
              lazy={false}
            />
            <Typography style={{ width: '100%', fontWeight: 600 }}>
              {'Selecciona tu ciudad'}
            </Typography>
          </DialogTitle>

          <Grid container >
            {/* <List style={{ minWidth: layout.width }}> */}
            {
              cities.map((item, index) => {
                return (
                  <Grid
                    item
                    container
                    xs={6}
                    key={index}
                    style={{ padding: 8 }}
                  >
                    <Button
                      fullWidth
                      style={{ backgroundColor: 'white', boxShadow: '0px 0px 3px gray', borderRadius: 15, padding: 8 }}
                      container
                      onClick={() => {
                        setSelected([item])
                        setAnchorEl(null);
                        // if (selected.includes(item)) {
                        //   setSelected(selected.filter(e => e !== item))
                        // } else {
                        //   setSelected([...selected, item])
                        // }
                      }}
                    >
                      <Grid item xs>
                        <Typography>
                          {item} {` (${sucursales.filter(e => e.city === item).length}) `}
                        </Typography>
                      </Grid>

                      {/* <Grid item xs='auto' style={{ paddingLeft: 8 }}>
                        <CheckOutlined color='primary' style={{ opacity: selected.includes(item) ? 1 : 0 }} />
                      </Grid> */}
                    </Button>
                  </Grid>
                )
              })
            }
            <Grid
              item
              xs={12}
              key={'todos'}
              // onClick={() => {
              //   setSelected(cities);
              // }}
              style={{ padding: 8 }}
            >
              <Button
                variant='contained'
                color='primary'
                item
                fullWidth
                xs={12}
                key={'todos'}
                onClick={() => {
                  setSelected(cities);
                  setAnchorEl(null);
                }}
                style={{ boxShadow: '0px 0px 3px gray', borderRadius: 15, padding: 8 }}
              >
                <Typography>
                  {`Todas (${sucursales.length})`}
                </Typography>
              </Button>
            </Grid>
            {/* </List> */}
          </Grid>
        </Dialog>
      </ButtonGroup>
    )
  }

  const renderItems = () => {
    return (
      <Grid item xs={isMd ? 'auto' : true} style={{ backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: isMd ? 'none' : '10px 10px 0px 0px', overflowY: 'scroll', ...isMd ? { height: '100%', backgroundColor: 'white', boxShadow: '0px 0px 5px gray' } : {} }}>
        <Box p={1}>
          {renderSearchBar()}
        </Box>
        {
          options.map((item, index) => {
            return (
              <Box key={index} width={isMd ? 400 : '100%'}>
                <Grid container key={index} >
                  <Grid item xs='auto' style={{ padding: 8 }}>
                    <Avatar variant='rounded' src={item.image} aria-label="recipe" style={{ height: 80, width: 80 }} />
                  </Grid>
                  <Grid item container xs direction='column' style={{ padding: 8, borderBottom: '1px solid rgba(200,200,200,0.5)' }}>
                    <Grid item xs='auto' >
                      <Typography variant="inherit" color="textPrimary">
                        {item.name}
                      </Typography>
                    </Grid>
                    <Grid item xs >
                      <Typography variant="inherit" color="textSecondary">
                        {item.address}
                      </Typography>
                    </Grid>
                    <Grid item xs='auto' >
                      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton style={{ alignSelf: 'flex-end' }} size='small' aria-label="share" onClick={() => {
                          mapsSelector(item.location)
                        }}>
                          <PinDropOutlined />
                        </IconButton>

                        {
                          window.screen.width < 500 ?
                            <IconButton
                              style={{ alignSelf: 'flex-end' }}
                              size='small'
                              href={window.screen.width < 500 ? 'tel:+52-667-758-2929' : undefined} aria-label="share"
                            >
                              <Phone />
                            </IconButton> :
                            undefined
                        }
                        <IconButton
                          style={{ alignSelf: 'flex-end' }}
                          size='small' aria-label="share" onClick={() => {
                            setSelect(item)
                          }}>
                          <MoreVertOutlined />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )
          })
        }
      </Grid>
    )
  }
  const renderMaps = () => {
    return (
      <Grid item xs >
        <Box width={'100%'} height='100%'>
          <Maps
            onClick={(item) => {
              setSelect(item)
            }}
            points={options.length ? options : sucursales}
          />
        </Box>
      </Grid>
    )
  }
  const renderModal = () => {
    return (
      <Dialog
        open={!!(select !== null)}
        TransitionComponent={Transition}
        onClose={() => setSelect(null)}
        PaperProps={{ className: classes.cardPopup }}
      >
        {
          select !== null &&
          <Card className={classes.cardPopup}>
            <CardHeader
              avatar={
                <Avatar src={'/images/logos/logo-login.svg'} aria-label="recipe" className={classes.avatar} />
              }
              title={select.name}
              action={
                <Box >

                  <IconButton className={classes.iconButtonPopup} aria-label="share" onClick={() => {
                    mapsSelector(select.location)
                  }}>
                    <PinDropOutlined />
                  </IconButton>
                  {
                    window.screen.availWidth < 500 &&
                    <IconButton className={classes.iconButtonPopup} href='tel:+52-667-758-2929'>
                      <PhoneAndroidOutlined />
                    </IconButton>
                  }
                </Box>
              }
            />
            <CardMedia
              className={classes.media}
              image={select.image}
              title={select.name}
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary">
                {select.address}
              </Typography>
            </CardContent>
            <Divider />
            <CardActions>
              <Typography variant="body2" color="textSecondary">
                {'Horarios:'}
              </Typography>
              <Collapse in={true || expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  {
                    (select.schedules || []).map((schedule, index) => {
                      return (
                        <Typography key={index} variant="body2" color="textSecondary" component="p">
                          {schedule}
                        </Typography>
                      )
                    })
                  }
                </CardContent>
              </Collapse>
            </CardActions>
          </Card>
        }
      </Dialog>
    )
  }
  return (
    <Grid container direction={isMd ? 'row' : 'column-reverse'} style={{ height: 'calc(100vh - 60px)', width: '100%' }}>
      {renderItems()}
      {renderMaps()}
      {renderModal()}
    </Grid>
  );

};

export default Locations;
