/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  List,
  ListItem,
  Typography,
  ListItemIcon,
  Button,
  IconButton,
  Box
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Avatar } from '@material-ui/core';
import { Image } from 'components/atoms';
import { Login } from 'components/organisms';
import { RemoveGlobalUser, removeIdPaciente, removeToken } from 'redux/session';
import { useHistory } from 'react-router-dom';
import modulos from '../../../../../../constants/modulos.json';
import { setListPatients, setPatient, setResults, setResultsGrouped, setResultsImage } from 'redux/studies';
const useStyles = makeStyles(theme => ({
  root: {
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '8px 16px',
    fontWeight: 800
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  listItemLink: {
    textDecoration: 'none',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  divider: {
    width: '100%',
  },
  containerAvatar: {
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
    padding: 30,
  },
  avatar: {
    display: 'flex',
    width: '70%',
    alignSelf: 'center'
  },
}));

const SidebarNav = props => {
  const { pages, onClose, className, ...rest } = props;
  const classes = useStyles();
  const token = useSelector(state => state.session.token);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(removeToken());
    dispatch(removeIdPaciente());
    dispatch(RemoveGlobalUser());
    dispatch(setPatient(null));
    dispatch(setListPatients([]));
    dispatch(setResults([]));
    dispatch(setResultsGrouped([]));
    dispatch(setResultsImage([]));
    onClose();
    history.push('/promociones-mes')
  };

  return (
    <Box position="relative">
      <List {...rest} className={clsx(classes.root, className)}>
        <ListItem className={classes.containerAvatar}>
          <a href="/about-us" title="deliabarraza">
            <Image
              className={classes.logoImage}
              src="/images/logos/logo.svg"
              alt="deliabarraza"
              lazy={false}
            />
          </a>
          {/* <Image
            className={classes.avatar}
            src="/images/logos/logo.svg"
            alt="deliabarraza"
            hr
            lazy={false}
          /> */}
        </ListItem>
        {
          modulos.map((item, index) => {
            return (
              <ListItem button component='a'
                onClick={() => {
                  history.push(item.href)
                  onClose();
                }}
                className={classes.listItem}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  component="a"
                  className={classes.listItemLink}
                >
                  {item.name}
                </Typography>
              </ListItem>
            )
          })
        }
        {!token && (
          <Login>
            <ListItem button onClick={onClose} component='a' className={classes.listItem}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.listItemText}
              >
                Inicio de sesión
              </Typography>
            </ListItem>
          </Login>
        )}

        {token && (
          <ListItem button component='a' className={classes.listItem} onClick={handleLogout}>
            <Button variant='outlined' fullWidth color='primary'>
              {'Cerrar sesión'}
            </Button>
          </ListItem>
        )}

      </List>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default SidebarNav;
