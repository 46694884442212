import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, useMediaQuery, Divider, Fab, IconButton, ListItem, Typography, Button } from '@material-ui/core';
import { Topbar, Footer, Sidebar } from './components';
import Helpbar from './components/Helpbar';
import { HouseOutlined, ShoppingCartOutlined, WhatsApp } from '@material-ui/icons';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LocationComponent from 'components/LocationComponent';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 0, left: 0, right: 0, bottom: 0,
    overflowY: 'scroll',
    // marginTop: 55,
    // backgroundImage: 'url(images/background50.png)',
    // backgroundSize: '40vh 100vh',
    // backgroundRepeat: 'repeat',
    backgroundImage: 'linear-gradient(rgba(208,66,142, 0.2), rgba(77,172,170, 0.2))',
    backgroundSize: '100vw 100%',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    backgroundColor: 'rgba(200,200,200,0.1)',
    [theme.breakpoints.up('sm')]: {
      // marginTop: 65,
    },
  },
  main: {
    width: '100%',
    alignSelf: 'center',
    height: '100%',
    overflowY: 'scroll',
    [theme.breakpoints.up('sm')]: {
      width: '100vw',
    }
  },
  floating: {
    position: 'absolute',
    right: 16,
    bottom: 16,
  },
  social: {
    position: 'absolute',
    left: 16,
    bottom: 16,
    height: 80,
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
    '&:hover': {
      background: 'transparent',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 24,
  },
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const pages = {
    landings: {
      title: 'Landings',
      id: 'landing-pages',
      children: {
        services: {
          groupTitle: 'Services',
          pages: [
            {
              title: 'Coworking',
              href: '/coworking',
            },
            {
              title: 'Rental',
              href: '/rental',
            },
            {
              title: 'Job Listing',
              href: '/job-listing',
            },
            {
              title: 'E-Learning',
              href: '/e-learning',
            },
            {
              title: 'E-commerce',
              href: '/e-commerce',
            },
            {
              title: 'Expo',
              href: '/expo',
            },
          ],
        },
        apps: {
          groupTitle: 'Apps',
          pages: [
            {
              title: 'Desktop App',
              href: '/desktop-app',
            },
            {
              title: 'Mobile App',
              href: '/mobile-app',
            },
          ],
        },
        web: {
          groupTitle: 'Web',
          pages: [
            {
              title: 'Overview',
              href: '/home',
            },
            {
              title: 'Basic',
              href: '/web-basic',
            },
            {
              title: 'Service',
              href: '/service',
            },
            {
              title: 'Startup',
              href: '/startup',
            },
            {
              title: 'Enterprise',
              href: '/enterprise',
            },
            {
              title: 'Cloud Hosting',
              href: '/cloud-hosting',
            },
            {
              title: 'Agency',
              href: '/agency',
            },
            {
              title: 'Design Company',
              href: '/design-company',
            },
            {
              title: 'Logistics',
              href: '/logistics',
            },
          ],
        },
      },
    },
    pages: {
      title: 'Pages',
      id: 'supported-pages',
      children: {
        career: {
          groupTitle: 'Career',
          pages: [
            {
              title: 'Lising',
              href: '/career-listing',
            },
            {
              title: 'Lising Minimal',
              href: '/career-listing-minimal',
            },
            {
              title: 'Opening',
              href: '/career-opening',
            },
          ],
        },
        helpCenter: {
          groupTitle: 'Help center',
          pages: [
            {
              title: 'Overview',
              href: '/help-center',
            },
            {
              title: 'Article',
              href: '/help-center-article',
            },
          ],
        },
        company: {
          groupTitle: 'Company',
          pages: [
            {
              title: 'About',
              href: '/about',
            },
            {
              title: 'About (Cover)',
              href: '/about-side-cover',
            },
            {
              title: 'Pricing',
              href: '/pricing',
            },
            {
              title: 'Terms',
              href: '/company-terms',
            },
          ],
        },
        contact: {
          groupTitle: 'Contact',
          pages: [
            {
              title: 'Reach View',
              href: '/contact-page',
            },
            {
              title: 'Sidebar Map',
              href: '/contact-sidebar-map',
            },
            {
              title: 'Cover',
              href: '/contact-page-cover',
            },
          ],
        },
        blog: {
          groupTitle: 'Blog',
          pages: [
            {
              title: 'Newsroom',
              href: '/blog-newsroom',
            },
            {
              title: 'Reach View',
              href: '/blog-reach-view',
            },
            {
              title: 'Search',
              href: '/blog-search',
            },
            {
              title: 'Article',
              href: '/blog-article',
            },
          ],
        },
        portfolio: {
          groupTitle: 'Portfolio',
          pages: [
            {
              title: 'Basic',
              href: '/portfolio-page',
            },
            {
              title: 'Masonry',
              href: '/portfolio-masonry',
            },
            {
              title: 'Grid View',
              href: '/portfolio-grid',
            },
            {
              title: 'Parallax Effect',
              href: '/agency',
            },
          ],
        },
      },
    },
    account: {
      title: 'Account',
      id: 'account',
      children: {
        settings: {
          groupTitle: 'Settings',
          pages: [
            {
              title: 'General',
              href: '/account/general',
            },
            {
              title: 'Security',
              href: '/account/security',
            },
            {
              title: 'Notifications',
              href: '/account/notifications',
            },
            {
              title: 'Billing',
              href: '/account/billing',
            },
          ],
        },
        signup: {
          groupTitle: 'Sign up',
          pages: [
            {
              title: 'Simple',
              href: '/signup-simple',
            },
            {
              title: 'Cover',
              href: '/signup-cover',
            },
          ],
        },
        signin: {
          groupTitle: 'Sign in',
          pages: [
            {
              title: 'Simple',
              href: '/signin-simple',
            },
            {
              title: 'Cover',
              href: '/signin-cover',
            },
          ],
        },
        password: {
          groupTitle: 'Password reset',
          pages: [
            {
              title: 'Simple',
              href: '/password-reset-simple',
            },
            {
              title: 'Cover',
              href: '/password-reset-cover',
            },
          ],
        },
        error: {
          groupTitle: 'Error',
          pages: [
            {
              title: 'Simple',
              href: '/not-found',
            },
            {
              title: 'Cover',
              href: '/not-found-cover',
            },
          ],
        },
      },
    },
  };

  const [openSidebar, setOpenSidebar] = useState(false);
  const [openHelpBar, setOpenHelpbar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const handleHelpBarOpen = () => {
    setOpenHelpbar(true);
  };

  const handleHelpBarClose = () => {
    setOpenHelpbar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} pages={pages} />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <Helpbar
        onClose={handleHelpBarClose}
        open={openHelpBar}
        variant="temporary"
        pages={pages}
      />
      <Box 
      style={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
        {/* <Box style={{ alignSelf: 'center' }}> */}
          <main className={classes.main}>
            {children}
          </main>
        {/* </Box> */}
      </Box>
      <Footer pages={pages} >
        <ListItem className={classes.social} >
          <IconButton className={classes.socialIcon}>
            <FacebookIcon className={classes.icon} />
          </IconButton>
          <IconButton className={classes.socialIcon}>
            <InstagramIcon className={classes.icon} />
          </IconButton>
          <IconButton className={classes.socialIcon}>
            <TwitterIcon className={classes.icon} />
          </IconButton>
          <IconButton className={classes.socialIcon}>
            <YouTubeIcon className={classes.icon} />
          </IconButton>
        </ListItem>
      </Footer>
      <Fab
        aria-label="add"
        size='large'
        className={classes.floating}
        onClick={handleHelpBarOpen}
        style={{ position: 'fixed' }}
        color='primary'
      >
        <WhatsApp/>
        
      </Fab>
      {/* <LocationComponent/> */}
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
