import http from "../http";
import store from 'redux/store';
import axios from "axios";
import data from '../../../constants/results.json';
import moment from "moment";
const studiesEndPoints = {
  historyStudies: async (startDate, endDate, callback) => {
    const token = store.getState().session.token;
    const url = `${process.env.REACT_APP_API_URL}/paciente/historial-estudios`;
    // callback(null, data);
    // return;
    var settings = {
      "url": url,
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Authorization": `${token}`,
        "Content-Type": "application/json",
      },
      "data": JSON.stringify({ fechaIni: startDate, fechaFin: endDate }),
    };
    if (!token) return;
    const response = await window.$.ajax(settings);
    console.log('reponse', response);
    console.log('response.data', response.data);
    if (response.data) {
      callback(null, response.data);
    } else {
      callback({ error: 'No hay datos para mostrar' })
    }
  },
  historyStudiesPatientsCustom: async ({ type, startDate, endDate, id_paciente }) => {
    const token = store.getState().session.token;
    let baseURL = process.env.REACT_APP_API_URL
    // baseURL = '/apis.dbsystem/apithink/web/index.php';
    var settings = {
      "timeout": 0,
      "headers": {
        "Authorization": `${token}`,
        "Content-Type": "application/json",
      },
    };
    if (type === 'paciente') {
      settings.data = JSON.stringify({ fechaIni: startDate, fechaFin: endDate });
      settings.method = 'POST';
      settings.url = `${baseURL}/paciente/historial-estudios`;
    } else {
      settings.method = 'POST';
      settings.url = `${baseURL}/${type}/historial-estudios-paciente`;
      settings.data = JSON.stringify({ fechaIni: moment(startDate || undefined).format('YYYY-MM-DD'), fechaFin: moment(endDate || undefined).format('YYYY-MM-DD'), idPaciente: id_paciente });
    }

    if (!token) return;
    return await window.$.ajax(settings);
  },
  patientList: async ({ type, startDate, endDate, searchText, page = 1 }, callback) => {
    const token = store.getState().session.token;
    let baseURL = process.env.REACT_APP_API_URL
    // baseURL = '/apis.dbsystem/apithink/web/index.php';
    const url = `${baseURL}/${type}/listado-pacientes`;
    var settings = {
      "url": url,
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Authorization": `${token}`,
        "Content-Type": "application/json",
      },
      "data": JSON.stringify({ fechaIni: moment(startDate || undefined).format('YYYY-MM-DD'), fechaFin: moment(endDate || undefined).format('YYYY-MM-DD'), pagina: page, mostrar: 30, paciente: searchText || '' }),
    };
    if (!token) return;
    return await window.$.ajax(settings);
  },
  getPDF: async (folio, callback) => {
    const token = store.getState().session.token;
    console.log('tokessn', token)
    const url = `${process.env.REACT_APP_API_URL}/resultados/ver-pdf`;
    var settings = {
      "url": url,
      "method": "POST",
      // "mode": "no-cors",
      "timeout": 0,
      "headers": {
        "Authorization": `${token}`,
        "Content-Type": "application/json",
      },
      "data": JSON.stringify({ id_orden: folio }),
    };

    const response = await window.$.ajax(settings);
    console.log('reponses', response);
    if (`${response}`.startsWith('https://')) {
      console.log('repsonse', response)
      callback(null, response);
    } else {
      callback({ error: 'No hay datos para mostrar' })
    }
  },
  getPDFFile: async (url, callback) => {
    var settings = {
      "url": url,
      "method": "GET",
    };

    const response = await window.$.ajax(settings);
    if (response.status && response.status === 'success' && !!response.dbsystemurl) {
      callback(null, response);
    } else {
      callback({ error: 'No hay datos para mostrar' })
    }
  },
  historyStudiesPatients: async (startDate, endDate, callback) => {
    const token = store.getState().session.token;
    const url = `${process.env.REACT_APP_API_URL}/paciente/historial-estudios`;
    // callback(null, data);
    // return;
    var settings = {
      "url": url,
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Authorization": `${token}`,
        "Content-Type": "application/json",
      },
      "data": JSON.stringify({ fechaIni: startDate, fechaFin: endDate }),
    };
    const response = await window.$.ajax(settings);
    console.log('reponse', response);
    if (response.data) {
      callback(null, response.data);
    } else {
      callback({ error: 'No hay datos para mostrar' })
    }
  },
  medicalPatients: async (startDate, endDate, callback) => {
    const token = store.getState().session.token;
    const url = `${process.env.REACT_APP_API_URL}/paciente/historial-estudios`;
    // callback(null, data);
    // return;
    var settings = {
      "url": url,
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Authorization": `${token}`,
        "Content-Type": "application/json",
      },
      "data": JSON.stringify({ fechaIni: startDate, fechaFin: endDate }),
    };
    const response = await window.$.ajax(settings);
    console.log('reponse', response);
    if (response.data) {
      callback(null, response.data);
    } else {
      callback({ error: 'No hay datos para mostrar' })
    }
  },
};

export default studiesEndPoints;
