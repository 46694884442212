import React, { Component, useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import useMeasure from 'react-use-measure';
import dicomParser from "dicom-parser";
import * as cornerstone from "cornerstone-core";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import cornerstoneMath from "cornerstone-math";
import cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import CornerstoneViewport from "react-cornerstone-viewport";
import { Box, Button, Divider, Grid, IconButton, Menu, MenuItem, Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import { CreateOutlined, InvertColorsOutlined, PanToolOutlined, QuestionAnswerOutlined, ShortTextOutlined, TonalityOutlined, ZoomInOutlined } from "@material-ui/icons";
import { blue, orange, teal } from "@material-ui/core/colors";
import IconMask from "../IconMask";
import IAButton from "../IAButton";
import { useTheme } from "@material-ui/styles";
const color = teal[400];

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

cornerstoneTools.init({
  globalToolSyncEnabled: true
});
// Preferences
const fontFamily =
  "Work Sans, Roboto, OpenSans, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif";
cornerstoneTools.textStyle.setFont(`20px ${fontFamily}`);
cornerstoneTools.toolStyle.setToolWidth(1);
cornerstoneTools.toolColors.setToolColor(color);
cornerstoneTools.toolColors.setActiveColor(orange[800]);
console.log('cornerstoneTools', cornerstoneTools)
cornerstoneTools.store.state.touchProximity = 40;
const LengthTool = cornerstoneTools.LengthTool;
cornerstoneTools.addTool(LengthTool)
cornerstoneTools.setToolActive('Length', { mouseButtonMask: 1 })

// Image Loader
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
const iconSize = 25;
const controls = [
  { id: 'ArrowAnnotate', name: 'Anotación', icon: '/icons/pencil.svg', size: iconSize },
  { id: 'EllipticalRoi', name: 'Elíptica', icon: '/icons/elipse.svg' },
  { id: 'Probe', name: 'Densidad', icon: '/icons/cuentagotas.svg', size: iconSize },
  { id: 'Wwwc', name: 'Contraste', icon: '/icons/contraste.svg', size: iconSize },
  { id: 'Zoom', name: 'Zoom', icon: '/icons/buscar.svg', size: iconSize - 5 },
  { id: 'Pan', name: 'Mover', icon: '/icons/flechas.svg' },
  { id: 'Length', name: 'Longitud', icon: '/icons/la-medida.svg' },
  { id: 'Angle', name: 'Ángulo', icon: '/icons/angulo.svg' },
  { id: 'Bidirectional', name: 'Bidirecciónal', icon: '/icons/flecha.svg', size: iconSize - 5 },
  { id: 'FreehandRoi', name: 'Manos Libres', icon: '/icons/lazo.svg', size: iconSize - 5 },
  { id: 'Eraser', name: 'Borrador', icon: '/icons/borrador.svg', size: iconSize - 5 },
  // { id: 'StackScrollMouseWheel', name: 'StackScrollMouseWheel', icon: null },
  // { id: 'PanMultiTouch', name: 'PanMultiTouch', icon: null },
  // { id: 'ZoomTouchPinch', name: 'ZoomTouchPinch', icon: null },
  // { id: 'StackScrollMultiTouch', name: 'StackScrollMultiTouch', icon: null },
  // { id: 'ScaleOverlay', name: 'Scalas', icon: null },
  // { id: 'WwwcRegion', name: 'Niveles Region', icon: null },
  { id: 'Magnify', name: 'Lupa', icon: '/icons/buscar.svg', size: iconSize - 5 },
];

const DicomViewer = ({ urls = [], ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  console.log('rutas ------>', urls);
  const ref = useRef()
  const [refFrame, { height, width }] = useMeasure();
  const [voicePlay, setVoicePlay] = useState(false);
  const handle = useFullScreenHandle({
    active: true
  });
  const [state, setState] = useState({
    activeViewportIndex: 0,
    viewports: [0, 1, 2, 3],
    tools: controls.map(e => {
      return {
        ...e,
        name: e.id,
        mode: 'active'
      }
    }),
    imageIds: [
      // ...urls.map(e => `dicomweb://localhost:3030/download?id=${e.ruta.split('id=')[1]}`),
      ...urls.map(e => `dicomweb://${e.ruta.replace('http://', '').replace('https://', '')}`),
      // "dicomweb://localhost:3000/demoFiles/1170744.dcm",
      // "dicomweb://localhost:3000/demoFiles/1170745.dcm",
      // "dicomweb://localhost:3000/demoFiles/1170746.dcm",
      // "dicomweb://localhost:3000/demoFiles/1170753.dcm",

      // "dicomweb://localhost:3000/demoFiles/1170753.dcm",
      // "dicomweb://localhost:3000/demoFiles/1170754.dcm",
      // "dicomweb://localhost:3000/demoFiles/1170755.dcm",
      // `dicomweb://187.216.113.211/dbsystem/web/index.php?r=resultadoscapturaimagen/descargar&id=MzAwMTY=`,

      // `dicomweb://${window.location.host}/demoFiles/1170753.dcm`,
      // `dicomweb://${window.location.host}/demoFiles/1170754.dcm`,
      // `dicomweb://${window.location.host}/demoFiles/1170755.dcm`,

      // "dicomweb://localhost:3000/demoFiles/1170756.dcm",
      // "wadouri:https://raw.githubusercontent.com/cornerstonejs/cornerstoneWADOImageLoader/master/testImages/CT2_J2KR",
      // "wadouri:https://server.dcmjs.org/dcm4chee-arc/aets/DCM4CHEE/rs/studies/1.3.6.1.4.1.25403.345050719074.3824.20170125113417.1/series/1.3.6.1.4.1.25403.345050719074.3824.20170125113545.4/instances/1.3.6.1.4.1.25403.345050719074.3824.20170125113608.3/frames/1"
    ],
    activeTool: 'Length',
    imageIdIndex: 0,
    isPlaying: false,
    frameRate: 2,
  })

  console.log('imageIds', (state.imageIds));
  const getFiles = async () => {
    let url = `https://server.dcmjs.org/dcm4chee-arc/aets/DCM4CHEE/rs/studies/1.3.6.1.4.1.25403.345050719074.3824.20170125113417.1/series/1.3.6.1.4.1.25403.345050719074.3824.20170125113514.6/instances/1.3.6.1.4.1.25403.345050719074.3824.20170125113522.7/frames/1`;
    url = `http://187.216.113.211/dbsystem/web/index.php?r=resultadoscapturaimagen/descargar&id=MzAwMTY=`;
    var settings = {
      "url": url,
      "method": "GET"
    };

    let blob = await fetch(url).then(r => r.blob());
    const response = {};
    console.log('response--------->', response)
    // const response = await window.$.ajax(settings);
    var arrayBuffer = new ArrayBuffer(blob.size);
    var byteArray = new Uint8Array(blob.arrayBuffer);

    try {
      // Allow raw files
      const options = { TransferSyntaxUID: '1.2.840.10008.1.2', untilTag: "x7fe00010" };
      // Parse the byte array to get a DataSet object that has the parsed contents
      var dataSet = dicomParser.parseDicom(byteArray, options);
      console.log('dataSet', dataSet)
      console.log('wndowPaco', window.pako)
      // access a string element
      var studyInstanceUid = dataSet.string('x0020000d');

      // get the pixel data element (contains the offset and length of the data)
      var pixelDataElement = dataSet.elements.x7fe00010;

      // create a typed array on the pixel data (this example assumes 16 bit unsigned data)
      var pixelData = new Uint16Array(dataSet.byteArray.buffer, pixelDataElement.dataOffset, pixelDataElement.length / 2);
    }
    catch (ex) {
      console.log(ex);
    }
    console.log('blob', blob);
    const dicomFile = new File([blob], "image.dcm", { type: "application/dicom" })
    let objectURL = URL.createObjectURL(dicomFile);
    console.log('objectURL', objectURL);
    const urlPath = `wadouri:${objectURL.replace('http://')}`;

    setState({ ...state, imageIds: [...state.imageIds, urlPath] })
    if (response.data) {
      //   callback(null, response.data);
    } else {
      //   callback({error:'No hay datos para mostrar'})
    }
  }
  useEffect(() => {
    // getFiles();
    console.log('res', ref)
  }, []);
  console.log('res', ref)

  return (
    <Box ref={refFrame} style={{ height: '100vh', width: '100%', maxHeight: '100vh', backgroundColor: 'rgba(0,0,0,0.9)' }}>
      <FullScreen handle={handle}>
        <Grid container direction="column" style={{ height: '100vh' }}>
          <Grid key={'header'} container item xs='auto' style={{ display: "flex", flexWrap: "nowrap", borderBottom: '1px solid gray', overflowY: 'hidden', overflowX: 'scroll', flexDirection: 'row' }}>
            {isMd ? <Grid key={'controls'} item container xs='auto' direction="row" style={{ flexWrap: "nowrap" }}>
              {
                controls.map((item, index) => {
                  const Icon = item.icon || QuestionAnswerOutlined;
                  return (
                    <Grid item xs='auto'>
                      <Tooltip key={index} title={item.name} >
                        <IconButton
                          size="small"
                          color='primary'
                          onClick={() => setState({ ...state, activeTool: item.id })}
                          style={{ margin: 8, flexWrap: "nowrap" }}
                        >
                          <IconMask url={item.icon} color={state.activeTool === item.id ? color : 'gray'} size={item.size || iconSize} />
                        </IconButton>
                      </Tooltip>
                    </Grid>

                  )
                })
              }
            </Grid>
              :
              <Grid key={'controls'} item container xs='auto' direction="row" style={{ flexWrap: "nowrap", paddingLeft: 16 }}>
                <IconButton
                  size="small"
                  color='primary'
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                  style={{ margin: 8, flexWrap: "nowrap" }}
                >
                  <IconMask url={controls?.find(e => e.id === state.activeTool)?.icon} color={color} size={controls?.find(e => e.id === state.activeTool)?.size || iconSize} />
                </IconButton>
              </Grid>
            }
            <Grid key={'s-1'} item xs='auto'>
              <Box padding={1} height={'100%'}>
                <Box style={{ backgroundColor: 'rgba(200,200,200,0.5)', width: 1, height: '100%' }} />
              </Box>
            </Grid>
            {
              isMd &&
              <Grid key={'play'} item xs='auto'>
                <Tooltip key={'play'} title={'Play'} >
                  <IconButton
                    size="small"
                    color='primary'
                    onClick={() => {
                      setState({ ...state, isPlaying: !state.isPlaying });
                    }}
                    style={{ margin: 8, flexWrap: "nowrap" }}
                  >
                    <IconMask url={`/icons/${!state.isPlaying ? 'play.svg' : 'stop.svg'}`} color={state.isPlaying ? color : 'gray'} size={iconSize} />
                  </IconButton>
                </Tooltip>
              </Grid>
            }
            {
              isMd &&
              <Grid key={'s-2'} item xs='auto'>
                <Box padding={1} height={'100%'}>
                  <Box style={{ backgroundColor: 'rgba(200,200,200,0.5)', width: 1, height: '100%' }} />
                </Box>
              </Grid>
            }
            <Grid key={'ia'} item xs='auto' direction="row" style={{ display: 'flex' }}>
              <IAButton
                commands={[
                  {
                    name: 'Para apagar el reconocimiento',
                    actionBase: 'off',
                    command: ['apaga el reconocimiento', 'deja de asistirme', 'deja de escuchar', 'apaga el asistente'],
                    response: ['ok, se inactiva el asistente'],
                  },
                  {
                    name: 'Para iniciar el modo cine',
                    command: ['activa secuencia', 'inicia secuencia', 'muestra todos los cortes', 'corre las imagenes'],
                    response: ['Se activó la secuencia'],
                    action: () => {
                      setState({ ...state, isPlaying: true });
                    }
                  },
                  {
                    name: 'Para detener el modo cine',
                    command: ['terminar secuencia', 'deten las imagenes', 'parar secuencia'],
                    response: ['Se pausa la secuencia'],
                    action: () => {
                      setState({ ...state, isPlaying: false });
                    }
                  },
                  {
                    name: 'Para salir del visor de imagenologia',
                    command: ['cierra el visor', 'sal de las imagenes', 'sal del visor'],
                    response: ['Se cierra el visor'],
                    action: () => {
                      props.setHiddeBottomTool && props.setHiddeBottomTool()
                    }
                  },
                  ...controls.map(control => {
                    const cleanName = `${control.name}`.toLowerCase().replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u')
                    return ({
                      name: `Para activar la herramienta ${cleanName}`,
                      command: [`activar ${cleanName}`, `usar ${cleanName}`, `activa ${cleanName}`],
                      response: [`Se activó la herramienta de ${control.name}`],
                      action: () => {
                        setState({ ...state, activeTool: control.id });
                      }
                    })
                  })
                ]}
              />
            </Grid>
            <Grid key={'s-3'} item xs='auto'>
              <Box padding={1} height={'100%'}>
                <Box style={{ backgroundColor: 'rgba(200,200,200,0.5)', width: 1, height: '100%' }} />
              </Box>
            </Grid>
            <Grid key={'s-4'} item xs />
            <Grid key={'exit'} container direction="row" item xs='auto' style={{}}>
              <Grid key={'space'} item xs />
              {/* <Grid key={'max'} item xs='auto'>
                <Tooltip key={'fullscreen'} title={'Pantalla completa'} >
                  <IconButton
                    size="small"
                    color='primary'
                    onClick={handle.active ? handle.exit : handle.enter}
                    style={{ margin: 8 }}
                  >

                    <IconMask url={`/icons/${handle.active ? 'minimize' : 'maximize'}.svg`} color={handle.active ? color : 'gray'} size={iconSize} />
                  </IconButton>
                </Tooltip>
              </Grid> */}
              <Grid key={'exit'} item xs='auto'>
                <Tooltip key={'salir'} title={'Salir'} >
                  <IconButton
                    size="small"
                    color='primary'
                    onClick={() => {
                      props.setHiddeBottomTool && props.setHiddeBottomTool()
                    }}
                    style={{ margin: '12px 16px' }}
                  >
                    <IconMask url={`/icons/close.svg`} color={handle.active ? color : 'gray'} size={16} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid key={'viewer'} item xs p={1} style={{ display: "flex", flexWrap: "wrap", overflow: 'hidden' }}>
            {state.viewports.map(viewportIndex => (
              <CornerstoneViewport
                onImageLoaded={(e) => console.log('Cargo----------> ', e)}
                onImageProgress={(e) => console.log('Cargo----------> ', e)}
                ref={ref}
                key={viewportIndex}
                style={{ fontSize: isMd ? '1.5rem' : '0.5rem', color: color }}
                tools={state.tools}
                imageIds={state.imageIds}
                imageIdIndex={state.imageIdIndex}
                isPlaying={state.isPlaying}
                frameRate={state.frameRate}
                className={state.activeViewportIndex === viewportIndex ? 'active' : ''}
                activeTool={state.activeTool}
                setViewportActive={() => {
                  setState({
                    ...state,
                    activeViewportIndex: viewportIndex,
                  });
                }}
              />
            ))}
          </Grid>
          {/* {

            !isMd &&
            <Grid key={'tools'} item container xs='auto' direction="row" style={{ flexWrap: "nowrap", overflow: 'scroll', padding: 4 }}>
              {
                controls.map((item, index) => {
                  const Icon = item.icon || QuestionAnswerOutlined;
                  return (
                    <Grid item xs='auto'>
                      <Tooltip key={index} title={item.name} >
                        <IconButton
                          size="small"
                          color='primary'
                          onClick={() => setState({ ...state, activeTool: item.id })}
                          style={{ margin: 8, flexWrap: "nowrap" }}
                        >
                          <IconMask url={item.icon} color={state.activeTool === item.id ? color : 'gray'} size={(item.size || iconSize) + 2} />
                        </IconButton>
                      </Tooltip>
                    </Grid>

                  )
                })
              }
            </Grid>
          } */}
        </Grid>
      </FullScreen>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {
          controls.map((item, index) => {
            const Icon = item.icon || QuestionAnswerOutlined;
            return (
              <MenuItem key={index} onClick={() => {
                setState({ ...state, activeTool: item.id });
                setAnchorEl(null);
              }}>
                <IconMask url={item.icon} color={state.activeTool === item.id ? color : 'gray'} size={item.size || iconSize} />
              </MenuItem>
            )
          })
        }
        <MenuItem key={'play'} onClick={() => setState({ ...state, isPlaying: !state.isPlaying })}>
          <IconMask url={`/icons/${!state.isPlaying ? 'play.svg' : 'stop.svg'}`} color={state.isPlaying ? color : 'gray'} size={iconSize} />
        </MenuItem>
      </Menu>
    </Box >
  );
};
export default DicomViewer;
