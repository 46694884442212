import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, Box } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Carousel } from 'react-responsive-carousel';
import data from 'constants/history.json';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    padding: 24,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
    borderRadius: theme.spacing(2),
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
  },
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const History = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid
        container
        justifyContent="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Box p={2}>
            <SectionHeader
              title={
                <span>
                  Bienvenidos
                </span>
              }
              // subtitleVariant='overline'
              subtitle="Gracias por visitar el sitio web de Laboratorios Delia Barraza. 
            Desde nuestros inicios en el año 1994, nuestra filosofía ha sido ofrecer tanto a médicos como a pacientes, servicios con altos estándares de calidad, tecnología de vanguardia y colaboradores en constante capacitación,  asimismo concientizar a la población en general en la cultura de la prevención para tener una mejor calidad de vida."
              ctaGroup={[
                <Grid align="center" xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography component="span" variant="h6" color="secondary" style={{ width: '100%', textAlign: 'center', alignSelf: 'center', display: 'flex' }}>
                   
                  </Typography>
                </Grid>,

              ]}
              align="center"
              disableGutter
              titleVariant="h3"
            />
            <SectionHeader
              title={
                <span>
                  
                </span>
              }
              // subtitleVariant='overline'
              subtitle={`Te invito a vigilar tu salud y la de tu familia, recuerda que siempre es mejor detectar las enfermedades a tiempo.`}
              ctaGroup={[
                <Grid align="center" xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography component="span" variant="h6" color="secondary" style={{ color: 'gray',width: '100%', textAlign: 'center', alignSelf: 'center', display: 'flex' }}>
                    Afectuosamente
                  </Typography>
                </Grid>,

              ]}
              align="center"
              disableGutter
              titleVariant="h3"
            />
            <SectionHeader
              title={
                <span>
                  
                </span>
              }
              // subtitleVariant='overline'
              ctaGroup={[
                <Grid align="center" xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography component="span" variant="h6" color="secondary" style={{ color: 'black', fontWeight: 600,width: '100%', textAlign: 'center', alignSelf: 'center', display: 'flex' }}>
                    Q.F.B. María Delia Barraza Sámano<br />
                    Especialista en Hematología Diagnóstica por Laboratorio<br />
                    Directora General
                  </Typography>
                </Grid>,

              ]}
              align="center"
              disableGutter
              titleVariant="h3"
            />
          </Box>
      </Grid>
      <Grid
        item
        container
        justifyContent="flex-start"
        alignItems="center"
        xs={12}
        md={6}
        data-aos={'fade-up'}
        style={{ justifyContent: 'center' }}
      >
        <Image
          src="/images/colaboradores/deliabarraza-072022.jpg"
          alt="historia"
          className={classes.image}
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        />
      </Grid>
    </Grid >
{/* <Grid
        container
        justifyContent="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={12}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              ''
            }
            subtitle="Como laboratorio clínico, estamos conscientes de la importancia de brindar una atención esmerada y seguridad a nuestros clientes, así como de entregar resultados confiables y oportunos, lo que nos llevó a obtener la acreditación en la norma internacional de calidad ISO 15189:2012, específica para laboratorios clínicos, por la Entidad Mexicana de Acreditación (ema) y estar calificados como uno de los laboratorios con mayor calidad en el país por el Programa  de Aseguramiento de la Calidad para los Laboratorios (PACAL). También contamos con el distintivo de Empresa Socialmente Responsable (ESR) desde el año 2008, promoviendo la sustentabilidad social y el respeto al medio ambiente.
            Quienes conformamos Laboratorios Delia Barraza, agradecemos a la comunidad médica, empresas, instituciones diversas y sociedad en general la confianza que han depositado en nosotros, lo que nos motiva a seguir creciendo y mejorando para apoyarlos en el cuidado de la salud de toda la  familia. 
            Reciban un afectuoso saludo."
            ctaGroup={[
              <Grid align="center" xs={12} md={12}>
                <Typography component="span" variant="h6" color="secondary">
                  Q.F.B. María Delia Barraza Sámano<br />
                  Especialista en Hematología Diagnóstica por Laboratorio<br />
                  Directora General
                </Typography>
              </Grid>,
            ]}
            align="left"
            disableGutter
            titleVariant="h3"
          />

        </Grid>
      </Grid> */}
    </div >
  );
};

History.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default History;
