import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Login } from 'components/organisms';

const RouteWithLayout = ({ layout: Layout, component: Component,path = '/', mustBeLoggedIn = false, ...rest }) => {
  const token = useSelector(state => state.session.token);

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          {mustBeLoggedIn && (
            <React.Fragment>
              <Login open path={ path}/>
              {mustBeLoggedIn && token && (<Component {...matchProps} />)}
            </React.Fragment>
          )}
          {!mustBeLoggedIn && (<Component {...matchProps} />)}
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
