import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  Hidden,
  List,
  ListItem,
  Typography,
  IconButton,
  AppBar,
  Button,
  Tabs,
  Tab
} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import { Image } from 'components/atoms';
import { Login } from 'components/organisms';
import { AccountMenu } from 'components/molecules';
import { AccountCircleOutlined, AccountCircleRounded } from '@material-ui/icons';
import modulos from '../../../../constants/modulos.json'
const useStyles = makeStyles(theme => ({
  root: {},
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  appBar: {
    backdropFilter: 'blur(20px)',
    borderBottom: 'solid 0.5px rgba(200,200,200,0.3)',
    // position: 'fixed',
  },
  toolbar: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 1),
    backgroundColor: 'rgba(255,255,255,0.7)'
  },
  listItem: {
    cursor: 'pointer',
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 10
  },
  listItemText: {
    flex: '0 0 auto',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  listItemButton: {
    whiteSpace: 'nowrap',
  },
  iconAccount: {
    color: theme.palette.primary.main
  },
  iconButton: {
    padding: 0,
    marginRight: 16,
    '&:hover': {
      background: 'transparent',
    },
  },
  logoContainer: {
    width: 220,
    height: 40,
    [theme.breakpoints.up('md')]: {
      width: 220,
      height: 40,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
}));
function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const topbarOptions = modulos;
const topbarOptionsFilter = topbarOptions.filter(e => !e.hiddenTopBar)
const Topbar = props => {
  const { onSidebarOpen, pages, ...rest } = props;
  const classes = useStyles();
  const token = useSelector(state => state.session.token);
  const history = useHistory();

  let locationItem = null;
  topbarOptionsFilter.find((e, i) => {
    if (e.href === history.location.pathname) {
      locationItem = i
    }
  })
  const [value, setValue] = React.useState(locationItem);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChange = (event, newValue) => {
    console.log('holaaaa', newValue)
    setValue(newValue);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    // <ElevationScroll {...props}>
    <AppBar variant='outlined' className={classes.appBar} position="sticky" color='transparent'>
      <Toolbar disableGutters className={classes.toolbar} {...rest}>
        <Hidden mdUp>
          <IconButton
            className={classes.iconButton}
            onClick={onSidebarOpen}
            aria-label="Menu"
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <div className={classes.logoContainer}>
          <a href="/about-us" title="deliabarraza">
            <Image
              className={classes.logoImage}
              src="/images/logos/logo.svg"
              alt="deliabarraza"
              lazy={false}
            />
          </a>
        </div>
        <div className={classes.flexGrow} />
        <Hidden smDown>
          <Tabs
            variant='scrollable'
            scrollButtons='auto'
            value={value}
            onChange={handleChange}
            TabScrollButtonProps={{style: {width: 'auto'}}}
            TabIndicatorProps={{ style: { borderRadius: '100%', overflow: 'hidden' } }}
          >
            {topbarOptionsFilter.map((option, index) => {
              return (
                <Tab key={index} wrapped onClick={() => {
                  if (option.href)
                    history.push(option.href);
                }} label={option.name} style={{ fontWeight: 800 }} />
              )
            })
            }
            <Tab
              tabIndex={topbarOptions.filter(e => !e.hiddenTopBar).length}
              label={
                <Button onClick={() => {
                  history.push('/viewer');
                  setValue(topbarOptions.filter(e => !e.hiddenTopBar).length)
                }} color='primary' size='small' variant='contained' style={{ fontWeight: 800 }}>
                  {'Resultados'}
                </Button>
              }
            />
          </Tabs>
          {
            !!token ?
              <Login>
                <Typography
                  variant="body1"
                  className={classes.listItemText}
                >
                  {token && (
                    <AccountMenu
                      className={classes.iconAccount}
                    />
                  )}
                </Typography>
              </Login> :
              <Login>
                <Typography
                  variant="body1"
                  className={classes.listItemText}
                >
                  {token && (
                    <AccountMenu
                      className={classes.iconAccount}
                    />
                  )}
                </Typography>
              </Login>
          }
        </Hidden>

        <Hidden mdUp>
          <Login>
            <IconButton
              color={!!token ? 'primary' : 'default'}
            >
              {token && (
                <AccountMenu
                  className={classes.iconAccount}
                />
              )}
            </IconButton>
          </Login>
        </Hidden>
      </Toolbar>
    </AppBar>
    // </ElevationScroll>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object.isRequired,
};

export default Topbar;
