import http from "../http";
import store from 'redux/store';

const authEndPoints = {
  login: async (body, callback) => {
    // callback(null, {
    //   "status": "success",
    //   "id_paciente": "546225",
    //   "api_token": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImp0aSI6IjRmMWcyM2ExMmFhIn0.eyJpc3MiOiJodHRwOlwvXC9leGFtcGxlLmNvbSIsImF1ZCI6Imh0dHA6XC9cL2V4YW1wbGUub3JnIiwianRpIjoiNGYxZzIzYTEyYWEiLCJpYXQiOjE2MzEzOTYyMjQsIm5iZiI6MTYzMTM5NjIyNCwiZXhwIjoxNjMxNDgyNjI0LCJ1aWQiOjM4OSwidGlwbyI6InBhY2llbnRlIiwiaWRfcGFjaWVudGUiOiI1NDYyMjUifQ.EHa17nboTrLa0vyucqIzjNrwATU3aklQmc0TNdiUUrI"
    // })REACT_APP_API_URL
    const urls = {
      usuario: `${process.env.REACT_APP_API_URL}/login/paciente`,
      // paciente: `${process.env.REACT_APP_API_URL}/login/paciente`,
      // medico: `${process.env.REACT_APP_API_URL}/login/medico`
    };
    let newBody = {};
    console.log('urls', urls);
    switch (true) {
      case (`${body.email}`.includes('@')): {
        newBody = body;
        break;
      }
      case (`${body.email}` * 1): {
        newBody = { contra: body.contra, celular: body.email };
        break;
      }
      default: {
        newBody = { contra: body.contra, usuario: body.email };
        break;
      }
    }
    var settings = {
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/json",
      },
      "data": JSON.stringify(newBody),
    };
    let error = null;
    const usuario = await window.$.ajax({ ...settings, url: urls.usuario });
    // const paciente = await window.$.ajax({ ...settings, url: urls.paciente });
    // const medico = await window.$.ajax({ ...settings, url: urls.medico });
    
    if (!usuario) {
      error = {mensaje: 'Verifica tu conexión a internet'}
    } else if (usuario?.status === 'error' && usuario?.status === 'error') {
      error = {mensaje: `${usuario.mensaje}`}
    }
    console.log('usuario',usuario);
    callback(error, usuario);
    // http.post("/login/paciente", {}, body, callback);
  },
  loginUser(callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.get(`/app/api/login/user`, headers, callback);
  },
  register(body, callback) {
    console.log(store.getState());

    http.post("/login/paciente", {}, body, callback);
  }
};

export default authEndPoints;
