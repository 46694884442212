import React, { useState, useMemo, useEffect } from 'react'
import * as THREE from 'three'
import { useThree, useFrame } from "@react-three/fiber";
import { Vector3 } from "three";
import CameraControls from 'camera-controls'

CameraControls.install({ THREE })

const randomPos = (min = 5, max = -5) => Math.random() * (max - min) + min



const CameraController = ({ zoom, focus, pos = new Vector3(), look = new Vector3() }) => {
    const [focusInner, setFocusInner] = useState(focus)
    const camera = useThree((state) => state.camera)
    const gl = useThree((state) => state.gl)
    const controls = useMemo(() => new CameraControls(camera, gl.domElement), [])
    useEffect(() => {

        setFocusInner(focus)
    }, [focus])
    useEffect(() => {
        controls.setLookAt(0, 2, 3, look.x, 1.4, look.z,true)

    }, [])
    return useFrame((state, delta) => {
        if (focusInner) {
            true ? pos.set(focus.x, focus.y, focus.z + 0.2) : pos.set(0, 0, 5)
            true ? look.set(focus.x, focus.y, focus.z - 0.2) : look.set(0, 0, 4)

            state.camera.position.lerp(pos, 0.5)
            state.camera.updateProjectionMatrix()
            controls.setLookAt(state.camera.position.x, state.camera.position.y, state.camera.position.z, look.x, look.y, look.z, true)
            setTimeout(() => {
                setFocusInner(null)
            }, 2000)
        }
        return controls.update(delta)
    })
}


export default CameraController;