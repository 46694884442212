import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors, Divider } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import {
  Customization,
  Hero,
  Hub,
  Partners,
  Pricings,
} from './components';

import { integrations } from './data';
import Blog from 'views/Blog';
import Promotions from 'components/organisms/Promotions';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import Browser from 'views/Browser';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    minHeight: 'calc(100vh)',
    width: '100%',
    flexWrap: 'wrap',
    placeContent: 'top flex-start',
    padding: 24,
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  shape: {
    background: 'white',
    borderRadius: 16,
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ResponsiveLayout>
        <div className={classes.shape}>
          <Section className={classes.pagePaddingTop}>
            <Hero />
          </Section>
          <Section className={classes.sectionNoPaddingTop}>
            <Hub />
          </Section>
        </div>
      </ResponsiveLayout>
      <Promotions />
      <ResponsiveLayout>
        <SectionAlternate>
          <Customization />
        </SectionAlternate>
      </ResponsiveLayout>
      <Browser view='paquetes' withOutHeader />
      <ResponsiveLayout>
        <SectionAlternate>
          <Partners data={integrations} />
        </SectionAlternate>
        <SectionAlternate innerNarrowed>
          <Blog />
        </SectionAlternate>
      </ResponsiveLayout>
      <Divider />
    </div>
  );
};

export default Home;
