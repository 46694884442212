import { Box, Grid, Button, ButtonGroup, Dialog, List, ListItem, Typography, useTheme, Divider, IconButton } from '@material-ui/core';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import React, { useState } from 'react';

const Info = () => {
    const [open, setOpen] = useState(true);
    const theme = useTheme();

    return (
        // <Dialog
        //     open={open}
        //     onClose={() => setOpen(false)}
        // >
        <ResponsiveLayout>
            <Box
                style={{ width: '100%' }}
                variant='outlined'
            >
                <Box p={2} width={'100%'} justifyContent='center' display={'flex'}>
                    <Box>
                    <Typography variant='h6' style={{ textAlign: 'center', fontWeight: 700 }}>
                            {'Acreditación otorgada por la Entidad Mexicana de Acreditación, A.C. (ema)'}
                        </Typography>
                    </Box>
                </Box>
                <Box width={'100%'} justifyContent='center' display={'flex'}>
                    <Box style={{ width: 250, maxWidth: '90%', borderRadius: 10, overflow: 'hidden', backgroundColor: 'white', boxShadow: '0px 0px 5px gray' }}>
                        <img width={'100%'} src='images/quienessomos/ISO151892012.jpg' alt='' />
                    </Box>
                </Box>
                <Box p={2} width={'100%'} justifyContent='center' display={'flex'}>
                    <Box >
                        <Typography variant='h6' style={{ textAlign: 'center', fontWeight: 700 }}>
                            {'"Requisitos generales para la calidad y la competencia de laboratorios clínicos"'}
                        </Typography>
                    </Box>
                </Box>
                {
                    [
                        {
                            concepto: 'Laboratorio'
                        },
                        {
                            // concepto: 'Laboratorio central',
                            sucursal: 'Laboratorio central',
                            acreditacion: 'Acreditación Número: CL-025',
                            title: 'Requisitos generales para la calidad y la competencia de laboratorios clínicos', items: [
                                'Hematología y Coagulación, Química Clínica y Toxicología'
                            ], url: '/images/ema/alcance_ema.pdf'
                        },
                        {
                            concepto: 'Imagenología'
                        },
                        {
                            // concepto: 'Imagenología',
                            sucursal: 'Suc. Paliza',
                            acreditacion: 'Acreditación Número: RI-0006-006/17',
                            title: 'Requisitos generales para la calidad y la competencia de laboratorios clínicos', items: [
                                'Mastografía',
                            ], url: '/images/ema/alcance_paliza_ema.pdf'
                        },
                        {
                            // concepto: 'Imagenología',
                            sucursal: 'Suc. Las Quintas',
                            acreditacion: 'Acreditación Número: RI-0005-005/17',
                            title: 'Requisitos generales para la calidad y la competencia de laboratorios clínicos', items: [
                                'Mastografía',
                            ], url: '/images/ema/alcance_quintas_ema.pdf'
                        },
                        {
                            // concepto: 'Imagenología',
                            sucursal: 'Suc. Country Courts',
                            acreditacion: 'Acreditación Número: RI-0007-007/17',
                            title: 'Requisitos generales para la calidad y la competencia de laboratorios clínicos', items: [
                                'Mastografía',
                            ], url: '/images/ema/alcance_country_ema.pdf'
                        },
                    ].map((item, index) => {
                        if (!!item.concepto)
                            return (
                                <Box p={1} width={'100%'} display={'flex'}>
                                    <Box >
                                        <Typography variant='h5' style={{ paddingLeft: 24, fontWeight: 700 }}>
                                            {`${item.concepto}`}
                                        </Typography>
                                    </Box>
                                </Box>
                            )
                        return (
                            <Box
                                p={2}
                                m={2}
                                key={index}
                                fullWidth
                                onClick={() => {
                                    window.open(item, "_blank")
                                }}
                                style={{

                                    borderRadius: 20,
                                    border: '3px solid white',
                                    boxShadow: '0px 0px 10px gray',
                                    backgroundColor: theme.palette.primary.main,
                                    position: 'relative',
                                    color: 'white'
                                }}
                            >


                                <Box width={'100%'}>
                                    <Typography variant='h5'>
                                        {`${item.sucursal}`}
                                    </Typography>
                                </Box>
                                <Box width={'100%'}>
                                    <Typography>
                                        {`${item.acreditacion}`}
                                    </Typography>
                                </Box>
                                <Box style={{ width: '100%', margin: '8px 0px', height: 2, backgroundColor: 'rgba(255,255,255,0.9)', borderRadius: 1 }} />
                                <Box width={'100%'}>
                                    <Typography variant='h6'>
                                        {'Disciplinas:'}
                                    </Typography>
                                </Box>
                                <Box width={'100%'}>
                                    <Grid container>
                                        {
                                            item.items.map((dis, i) => {
                                                return (
                                                    <Grid
                                                        key={i}
                                                        xs
                                                    >
                                                        {dis}
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Box>
                                <Button
                                    onClick={() => window.open(item.url, '_blank')}
                                    style={{ position: 'absolute', top: 10, right: 10, color: 'white' }}
                                >
                                    {'Ver'}
                                </Button>
                            </Box>
                        )
                    })
                }
            </Box>
        </ResponsiveLayout>

        // </Dialog>
    );
};

export default Info;
