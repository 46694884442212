import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Box, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Carousel } from 'react-responsive-carousel';
import data from 'constants/history.json';
const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const portland = !!(window.screen.height > window.screen.width);
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Carousel autoPlay infiniteLoop interval={3000} showThumbs={false} showStatus={false}>
        {
          data[0].content.map((item, i) => {
            return (
              <Box padding={0}
                style={{
                  height: portland ? '100vw' : '50vw',
                  width: '100%',
                  // backgroundColor: 'red'
                  // backgroundColor: `${backgrondsColors[i] || 'whiteSmoke'}`,
                }}
              >
                <Box
                  style={{
                    // backgroundImage: 'linear-gradient(to bottom right, red, yellow)'
                    position: 'absolute',
                    width: '100%',
                    top: 0,
                    height: '100%',
                    zIndex: -1000
                  }}
                >
                </Box>
                <Box className={classes.image}>
                  <Box
                    style={{
                      height: portland ? '100vw' : '50vw',
                      width: '100vw',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: -1
                    }}
                  />
                  <img key={item} src={item.url} className={classes.imageFrame} style={{ float: 'left', width: portland ? '100vw' : '50vw', height: portland ? '100vw' : '50vw' }} />
                </Box>
                <Box className={classes.image}>
                  <Box
                    style={{
                      height: portland ? '100vw' : '50vw',
                      width: '100vw',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: -1
                    }}
                  />
                  <img key={item} src={item.url} className={classes.imageFrame} style={{ float: 'right', width: portland ? '100vw' : '50vw', height: portland ? '100vw' : '50vw' }} />
                </Box>
              </Box>
            )
          })
        }
      </Carousel>

      <Grid
        container
        justifyContent="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >

        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span>
                Delia Barraza
                <br />
                <Typography component="span" variant="h4" color="secondary">
                  Laboratorio Clínico
                </Typography>
              </span>
            }
            subtitle="En Laboratorios Delia Barraza nuestra misión es brindarte servicios confiables, oportunos y de la mejor calidad de análisis clinicos, imagenología y enfermería con personal calificado y equipo de vanguardia entregando resultados con certificado internacional."
            // ctaGroup={[

            // ]}
            align="left"
            disableGutter
            titleVariant="h3"
          />
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="/images/illustrations/patologia-imagen.png"
            alt="patología"
            className={classes.image}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
