import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Typography,
  Grid,
  Button,
  TextField,
  Card,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Box
} from '@material-ui/core';
import { PlayCircleOutlineSharp } from '@material-ui/icons';
import validate from 'validate.js';
import { useHistory } from "react-router-dom";
import { Modal, QRReader } from 'components/molecules';
import { Register } from 'components/organisms';
import { setToken, setIdPaciente, setGlobalUser } from 'redux/session';
import internalApi from 'utils/internalAPI';
import { setPatient } from 'redux/studies';

/*
const typeLogin = [
  {
    value: '1',
    label: 'PACIENTES',
  },
  {
    value: '2',
    label: 'MEDICOS',
  },
  {
    value: '3',
    label: 'EMPRESAS',
  },
  {
    value: '4',
    label: 'PROCEDENCIA',
  },
];
*/

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  message: {
    fontSize: 12
  },
  qr: {
    width: 'calc(100vw - 120px)',
    height: 'calc(100vw - 120px)',
    marginBottom: 16,
    [theme.breakpoints.up('md')]: {
      width: 400,
      height: 400
    },
  },
  loginModal: {
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
}));

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 300,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      minimum: 4,
    },
  },
};

const initialFormState = {
  isValid: false,
  values: {},
  touched: {},
  errors: {},
};

const Login = ({ children, open = false, onLog = () => { } }) => {
  const history = useHistory();
  const classes = useStyles();
  const token = useSelector((state) => state.session.token);
  const dispatch = useDispatch();

  const [openLogin, setOpenLogin] = React.useState(open);
  const [qrLoginMode, setQRLoginMode] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [loadding, setLoadding] = React.useState(false);

  const [formState, setFormState] = React.useState(initialFormState);

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  React.useEffect(() => {
    if (openLogin) {
      setQRLoginMode(false);
    }
  }, [openLogin]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (formState.isValid) {
      setLoadding(true);
      (async () => {
        internalApi.login({
          email: formState.values.email,
          contra: formState.values.password
        }, (error, data = { paciente: {}, medico: {} }) => {
          console.log('error', error, data);
          if (error) {
            setMessage(error.mensaje);
            return;
          }
          dispatch(setGlobalUser(data));
          dispatch(setToken(data.api_token));
          if (data.tipo === 'paciente') {
            dispatch(setPatient(data));
          }
          // dispatch(setIdPaciente(data.paciente.id_paciente));
          setOpenLogin(false);
          onLog(true);
          setFormState(initialFormState);
          if (!window.location.href.includes('viewer')) {
            history.push('/viewer');
          }
        });
        setTimeout(() => {

          setLoadding(false);
        }, 1000)
      })();
      return;
    }

    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const handleOpen = () => setOpenLogin(!openLogin);
  const handleQRResult = (r) => {
    if (r.includes('usuario')) {
      const values = {};
      (r.split('?')[1] || '').split('&').map(item => {
        const v = item.split('=');
        values[v[0]] = v[1];
      })
      console.log('values', values)
      if (values.usuario && values.contra) {
        (async () => {
          internalApi.login({
            email: values.usuario,
            contra: values.contra
          }, (error, data) => {
            console.log('error', error, data);
            if (error) {
              setMessage(error.mensaje);
              return;
            }
            dispatch(setGlobalUser(data));
            dispatch(setToken(data.api_token));
            if (data.tipo === 'paciente') {
              dispatch(setPatient(data));
            }
            // dispatch(setIdPaciente(data.paciente.id_paciente));
            setOpenLogin(false);
            onLog(true);
            setFormState(initialFormState);
            if (!window.location.href.includes('viewer')) {
              history.push('/viewer');
            }
          });
        })();
      } else {
        setMessage('Código no valido por favor contacta con nuestra sucursal');
      }
    }
  };
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const onClick = () => setOpenLogin(true);
  const onCloseModal = () => {
    // if (!window.location.href.includes('viewer')) {
    //   setOpenLogin(false);
    // } else {
    history.push('/');
    // }
  };

  let clonedChildren = null;

  if (React.Children.count(children) > 0) {
    React.Children.only(children);

    let childrenProps = {};

    if (!token) {
      childrenProps = { onClick };
    }
    clonedChildren = React.cloneElement(children, childrenProps);
  }

  return (
    <>
      {clonedChildren}
      <Modal open={openLogin && !token} title="Ingresa" onClose={onCloseModal}>
        {loadding ?
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box p={12}>
                  <CircularProgress />
                </Box>
              </Grid>
            </Grid>
          </>
          : !qrLoginMode && (
            <form className={classes.loginModal} name="login" method="post" onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    placeholder="Correo eléctronico"
                    label="Usuario *"
                    variant="outlined"
                    size="medium"
                    name="email"
                    fullWidth
                    helperText={hasError('email') ? formState.errors.email[0] : null}
                    error={hasError('email')}
                    onChange={handleChange}
                    type="text"
                    value={formState.values.email || ''}
                    disabled={loadding}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    placeholder="Contraseña"
                    label="Contraseña *"
                    variant="outlined"
                    size="medium"
                    name="password"
                    fullWidth
                    helperText={
                      hasError('password') ? formState.errors.password[0] : null
                    }
                    error={hasError('password')}
                    onChange={handleChange}
                    type="password"
                    value={formState.values.password || ''}
                    disabled={loadding}
                  />
                </Grid>

                <Grid item xs={12} className={classes.message}>
                  {message}
                </Grid>

                <Grid item xs={12}>
                  <Button
                    size="large"
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                    disabled={loadding}
                  >
                    Continuar
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    size="large"
                    fullWidth
                    variant="outlined"
                    onClick={() => setQRLoginMode(true)}
                    disabled={loadding}
                  >
                    Continuar con QR
                  </Button>

                </Grid>

                {/* <Grid item container xs={12} justifyContent="flex-end">
                <i>
                  <Box display="flex" flexDirection="row" justifyContent="flex-end">
                    <Typography variant="subtitle2" style={{ marginRight: 8 }}>
                      ¿Nuevo en Delia Barraza?
                    </Typography>

                    <Register>
                      <Typography variant="subtitle2" component="a" href="/signup">
                        Únete
                      </Typography>
                    </Register>
                  </Box>
                </i>
              </Grid> */}
              </Grid>
            </form>
          )}

        {qrLoginMode && (
          <>
            <Box p={1}>
              <Typography style={{ fontWeight: '700' }}>
                {'¡Coloca el QR de tu recibo!'}
              </Typography>
            </Box>
            <div className={classes.qr}>
              <QRReader onLoad={handleQRResult} />
            </div>
            <Grid item xs={12}>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => setQRLoginMode(false)}
              >
                Regresar
              </Button>
            </Grid>
          </>
        )}
      </Modal>
    </>
  );
};

export default Login;
