import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from './components';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';
import { Image } from 'components/atoms';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  logoImage: {
    width: '50%',
    height: '50%',
    marginBottom: 20,
  },
}));

const Login = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid>
        <Grid lg={6} xs={12}>

        </Grid>
        <Grid lg={6} xs={12}>
          <Section className={classes.section}>
            <div className={classes.formContainer}>
              <Image
                className={classes.logoImage}
                src='/images/logos/logo-login.svg'
                alt='db'
                lazy={false}
              />
              <SectionHeader
                title='Inicio de sesión'
                subtitle='inicia sesión para acceder a tu información'
                titleProps={{
                  variant: 'h3',
                }}
              />
              <Form />
            </div>
          </Section>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
