// url del servidor
const baseServerURL = process.env.REACT_APP_API_URL;

const checkResponse = (url, response) => {
	if (response.status === 404) {
		throw new Error(`[404] Method ${url} not found.`);
	}

	if (response.status === 500) {
		throw new Error(`[500] Internal Server Error in method ${url}, contact the backend developer for more information.`);
	}
};

// http service
const httpService = {
	get(request, requestID, headers = new Headers()) {
		let routeURL;
		let serverURL;
		console.log('get headers', headers);
		const settings = { 
			method: 'GET',
			headers,
			mode: 'no-cors'
		};

		if (typeof request === 'object') {
			routeURL = request.url;
			serverURL = request.server;
		} else {
			routeURL = request;
			serverURL = baseServerURL;
		}
		console.log('get settings', settings);

		return fetch(serverURL + routeURL, settings).then(response => {
			console.log('response', response)
			const responseData = {
				status: response.status,
				statusText: response.statusText
			};

			checkResponse(serverURL + routeURL, response);
			window.amplify.store(requestID, responseData);
			return response.json();
		});
	},
	post(request, requestID, headers, body) {
		let routeURL;
		let serverURL;
		const settings = {
			method: 'POST',
			headers,
			body: JSON.stringify(body)
		};

		if (typeof request === 'object') {
			routeURL = request.url;
			serverURL = request.server;
		} else {
			routeURL = request;
			serverURL = baseServerURL;
		}

		return fetch(serverURL + routeURL, settings).then((response) => {
			const responseData = {
				status: response.status,
				statusText: response.statusText
			};

			checkResponse(serverURL + routeURL, response);
			window.amplify.store(requestID, responseData);
			return response.json();
		});
	},
	delete(request, requestID, headers, body) {
		let routeURL;
		let serverURL;
		const settings = {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				...headers
			},
			mode: 'cors',
			body: JSON.stringify(body)
		};

		if (typeof request === 'object') {
			routeURL = request.url;
			serverURL = request.server;
		} else {
			routeURL = request;
			serverURL = baseServerURL;
		}

		return fetch(serverURL + routeURL, settings).then((response) => {
			const responseData = {
				status: response.status,
				statusText: response.statusText
			};

			checkResponse(serverURL + routeURL, response);
			window.amplify.store(requestID, responseData);
			return response.json();
		});
	},
	put(request, requestID, headers, body) {
		let routeURL;
		let serverURL;
		const settings = {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				...headers
			},
			mode: 'cors',
			body: JSON.stringify(body)
		};

		if (typeof request === 'object') {
			routeURL = request.url;
			serverURL = request.server;
		} else {
			routeURL = request;
			serverURL = baseServerURL;
		}

		return fetch(serverURL + routeURL, settings).then((response) => {
			const responseData = {
				status: response.status,
				statusText: response.statusText
			};

			checkResponse(serverURL + routeURL, response);
			window.amplify.store(requestID, responseData);
			return response.json();
		});
	}
};

// exportar servicio
export default httpService;
