const catalogos = {
    getPaquetes: async (body, callback) => {
        console.log('paquete', body);
        const { page, rows } = body || {};
        const url = `${process.env.REACT_APP_API_URL}/paquete/catalogo?pagina=${page || 0}&mostrar=${rows || 15}&estudio=${body.searchText || ''}&clave=${body.clave || ''}`;
        fetch(url, {
            method: 'GET'
        }).then(async (res) => {
            const data = await res.json();
            callback(data.registros || []);
        }).catch(e => callback(null, e));
    },
    getEstudios: async (body, callback) => {
        const { page, rows, id_area, searchText = '' } = body || {};
        const url = `${process.env.REACT_APP_API_URL}/estudio/catalogo?pagina=${page || 0}&mostrar=${rows || 15}${id_area ? `&id_area=${id_area}` : ''}${searchText.length ? `&estudio=${searchText}` : ''}`;
        fetch(url, {
            method: 'GET',
        }).then(async (res) => {
            const data = await res.json();
            callback(data.registros || []);
        }).catch(e => callback(null, e));
    },
    hisorial: async (body, callback) => {
        const { page, rows, id_area, searchText = '' } = body || {};
        const url = `${process.env.REACT_APP_API_URL}/estudio/catalogo?pagina=${page || 0}&mostrar=${rows || 15}${id_area ? `&id_area=${id_area}` : ''}${searchText.length ? `&estudio=${searchText}` : ''}`;
        fetch(url, {
            method: 'GET',
        }).then(async (res) => {
            const data = await res.json();
            callback(data.registros || []);
        }).catch(e => callback(null, e));
    }
};
export default catalogos

// https://analisisdb.com.mx${process.env.REACT_APP_API_URL}/estudio/catalogo?pagina=0&mostrar=30
// https://analisisdb.com.mx${process.env.REACT_APP_API_URL}/estudio/catalogo?pagina=0&mostrar=30'