import axios from "axios"
import { isArray } from "validate.js";

const repository = {
    getRFCData: async (url: string, callback: () => void) => {
        const response = await axios.get(url);

        const div = document.createElement('div');
        div.innerHTML = `${response.data}`.trim();
        console.log('div.firstChild', div.querySelector('[data-ri="6"]').textContent);
        console.log('div.firstChild', div.querySelectorAll(['[data-ri]']));
        const data = {};
        div.querySelectorAll(['[data-ri]']).forEach((e, i) => {
            const chunks = e.textContent.split(':');
            if (data[chunks[0]] && !isArray(chunks[1])) {
                data[chunks[0]] = [data[chunks[0]]];
                data[chunks[0]].push(chunks[1])
            } else if (data[chunks[0]] && isArray(chunks[1])) {
                data[chunks[0]].push(chunks[1])
            } else {
                data[chunks[0]] = chunks[1];
            }
        })
        console.log('data', data);
        callback && callback(data);
    }
}
export default repository;