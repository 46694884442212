import { createTheme, responsiveFontSizes } from '@material-ui/core';

import palette from './palette';

const theme = responsiveFontSizes(
  createTheme({
    // components: {
    //   MuiCssBaseline: {
    //     styleOverrides: `
    //       @font-face {
    //         font-family: 'Raleway';
    //         font-style: normal;
    //         font-display: swap;
    //         font-weight: 400;
    //         src: local('Raleway'), local('Raleway-Regular'), url(${RalewayWoff2}) format('woff2');
    //         unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    //       }
    //     `,
    //   },
    // },
    palette,
    layout: {
      contentWidth: '100vw',
    },
    typography: {
      fontFamily: 'Nunito',
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
    overrides: {
      MuiButton: {
        containedSecondary: {
          color: 'white',
        },
      },
      MuiDialog: {
        container: {
          backdropFilter: 'blur(10px)'
        }
      },
      MuiInputBase: {
        // root: {
        //   backgroundColor: 'rgba(255,255,255,1)',
        //   borderRadius: 16,
        //   width: '100%',
        //   height: 'auto',
        //   boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
        //   transitionDuration: 200,
        //   "&:focus,&:hover": {
        //     backgroundColor: 'rgba(255,255,255,1)',
        //     borderColor: palette.primary.main,
        //     boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
        //   }
        // },
        // notchedOutline: {
        //   borderColor: palette.primary.main
        // }
      },
      MuiOutlinedInput: {
        root: {
          '&:hover': {
            borderColor: palette.primary.main
          }
        }
      }
    },
  }),
);

export default theme;
