import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, Typography, Divider, Button, InputBase, Popover, ButtonGroup, List, debounce, CircularProgress, Dialog, Slide, DialogTitle, DialogActions } from '@material-ui/core';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function ModalInfoSelected({ openDialog, setOpen, select, setSelect, type }) {
    return (
        <Dialog
            open={openDialog}
            onClose={() => setOpen(false)}
            onTransitionEnd={() => {
                if (openDialog && !select)
                    setSelect(null);
            }}
            TransitionComponent={Transition}
            transitionDuration={300}
            PaperProps={{ style: { boxShadow: '0px 0px 20px rgba(100,100,100,0.5)', borderRadius: 30, position: 'relative', width: '100%', height: 'auto', padding: 0 } }}
        >
            {
                !!select &&
                <Paper
                    key={0}
                    style={{
                        backgroundImage: 'linear-gradient(rgba(208,66,142, 0.1), rgba(77,172,170, 0.1))',
                        boxShadow: '0px 0px 20px rgba(100,100,100,0.5)', borderRadius: 30, position: 'relative', width: '100%', height: 'auto', padding: 16
                    }}>
                    <Box p={1}>
                        <Typography
                            key={0}
                            variant='subtitle1'
                            style={{
                                width: 'calc(100% - 100px)',
                                color: 'gray',
                                fontWeight: 400,
                                fontSize: 12,
                                borderRadius: '0px 0px 0px 0px'
                            }}
                        >
                            {select.tipo || ''}
                        </Typography>
                        <Typography
                            key={1}
                            style={{
                                height: 35,
                                width: 'calc(100% - 100px)',
                                textJustify: 'initial',
                                fontWeight: 800,
                                fontSize: 14,
                                // background: 'linear-gradient(to bottom, rgba(100,100,100,0.8), rgba(100,100,100,0))',
                            }}
                        >
                            {`${select.nombre}`.replaceAll('PAQUETE ', '').split('[')[0].split('(')[0]}
                        </Typography>
                    </Box>
                    <Divider />
                    {
                        select.detalle &&
                        <Box p={1}>
                            <Typography
                                key={1}
                                style={{
                                    width: 'calc(100%)',
                                    textJustify: 'initial',
                                    fontWeight: 800,
                                    fontSize: 12,
                                    // background: 'linear-gradient(to bottom, rgba(100,100,100,0.8), rgba(100,100,100,0))',
                                }}
                            >
                                {'Incluye:'}
                            </Typography>
                            {
                                (select.detalle || []).map((e, index) => {
                                    return (
                                        <Typography
                                            key={index}
                                            variant='subtitle1'
                                            style={{
                                                width: 'calc(100%)',
                                                color: 'gray',
                                                fontWeight: 400,
                                                fontSize: 12,
                                                borderRadius: '0px 0px 0px 0px',
                                                textAlign: 'justify',
                                                margin: 5
                                                // background: 'linear-gradient(to bottom, rgba(100,100,100,0.8), rgba(100,100,100,0))',
                                            }}
                                        >
                                            {/* {`${e.alterna} - ${e.estudio}`} */}
                                            {`${e.estudio} - ${e.alterna}`}
                                            {/* {`${e.estudio}`} */}
                                        </Typography>
                                    )
                                })
                            }
                        </Box>
                    }
                    {
                        !!select.indicacion_paciente &&
                        <Box p={1}>
                            <Typography
                                key={1}
                                style={{
                                    height: 35,
                                    width: 'calc(100%)',
                                    textJustify: 'initial',
                                    fontWeight: 800,
                                    fontSize: 12,
                                    // background: 'linear-gradient(to bottom, rgba(100,100,100,0.8), rgba(100,100,100,0))',
                                }}
                            >
                                {'Indicaciones:'}
                            </Typography>
                            <Typography
                                key={0}
                                variant='subtitle1'
                                style={{
                                    width: 'calc(100%)',
                                    color: 'gray',
                                    fontWeight: 400,
                                    fontSize: 12,
                                    borderRadius: '0px 0px 0px 0px',
                                    textAlign: 'justify',
                                    whiteSpace:'pre-wrap'
                                }}
                            >
                                {(select.indicacion_paciente || '').replaceAll('<br />', '\n\n')}
                            </Typography>
                            {/* <Typography
                                            key={1}
                                            style={{
                                                height: 35,
                                                width: 'calc(100%)',
                                                textJustify: 'initial',
                                                fontWeight: 800,
                                                fontSize: 12,
                                                // background: 'linear-gradient(to bottom, rgba(100,100,100,0.8), rgba(100,100,100,0))',
                                            }}
                                        >
                                            {'Toma de muestra:'}
                                        </Typography>
                                        <Typography
                                            key={0}
                                            variant='subtitle1'
                                            style={{
                                                width: 'calc(100%)',
                                                color: 'gray',
                                                fontWeight: 400,
                                                fontSize: 12,
                                                borderRadius: '0px 0px 0px 0px',
                                                textAlign: 'justify'
                                            }}
                                        >
                                            {select.indicacion_toma_de_muestra || ''}
                                        </Typography> */}
                        </Box>
                    }

                </Paper>
            }
        </Dialog>
    )
}