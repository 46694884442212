import http from "../http";
import store from 'redux/store';

const adminEndPoints = {
  getDates: async (body, callback) => {
    http.post("/citas/disponibilidad?fecha_inicial=2021-10-18 08:12:00&fecha_final=2021-10-18 11:12:00", {}, body, callback);
  },
  getSites: async (body, callback) => {
    http.post("/sucursal/listado", {}, body, callback);
  },
  loginUser(callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.get(`/app/api/login/user`, headers, callback);
  },
  register(body, callback) {
    console.log(store.getState());

    http.post("/login/paciente", {}, body, callback);
  }
};

export default adminEndPoints;
