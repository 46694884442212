import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, Paper, Dialog, Typography, InputBase, Button, Grid, useTheme, Divider, IconButton } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import Blog from '../Blog';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import useMeasure from 'react-use-measure'
import styled from 'styled-components';
import { teal } from '@material-ui/core/colors';
import Browser from 'views/Browser';
import Delivery from 'components/Delivery';
import { ArrowDownwardOutlined, ArrowForward, ArrowForwardIosOutlined } from '@material-ui/icons';
import DicomViewer from 'components/organisms/DicomViewer';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import repository from './repository';
import FacturacionForm from './FacturacionForm';
import billingsEndPoints from 'utils/internalAPI/endPoints/billingEndPoints';
const MaskQR = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/torunda.png'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const MaskSuc = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/location.svg'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const MaskRes = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/notepad.svg'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const MaskGlobe = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/pandemia.svg'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const MaskEdit = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/muestra.png'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const MaskList = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/nariz.svg'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;

const useStyles = makeStyles(theme => ({
    root: { padding: 20 },
    formContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
        maxWidth: 500,
        margin: `0 auto`,
    },
    "@keyframes myEffect": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    },
    containerData: {
        animation: `$myEffect 300ms ${theme.transitions.easing.easeIn}`,
    },
    floatContainer: {
        position: 'relative',
        height: 'auto',
        padding: 16,
        width: '100%',
        zIndex: 50,
        [theme.breakpoints.up('md')]: {
            marginBottom: 16,
            paddingTop: 100,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '50%',
            height: '100%',
        },
    },
    floatBox: {
        backgroundColor: 'rgba(255,255,255,1)',
        borderRadius: 16,
        border: `solid 0.5px rgba(255,255,255,0.2)`,
        width: '100%',
        maxHeight: 300,
        maxWidth: 400,
        boxShadow: '0px 4px 24px rgba(100,100,100,1)',
        [theme.breakpoints.up('md')]: {
            width: 400,
        },
    },
    text: {
        marginBottom: 32,
        fontSize: 20,
        fontWeight: 800,
        minHeight: 'calc(100% - 100px)',
        color: 'gray',
        [theme.breakpoints.up('md')]: {
            fontSize: 20,
        },
    },
    input: {
        backgroundColor: 'rgba(255,255,255,0.8)',
        borderRadius: 16,
        width: '100%',
        minWidth: 200,
        marginBottom: 16,
        maxHeight: 300,
        boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
        overflowY: 'scroll',
        paddingLeft: 16,
        paddingRight: 16,
        minHeight: 50,
        transitionDuration: 500,
        border: `solid 1px ${theme.palette.primary.main}`,
        "&:focus,&:hover": {
            backgroundColor: 'rgba(255,255,255,1)',
            border: `solid 1px ${theme.palette.primary.main}`,
            boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 16,
        },
    },
    button: {
        borderRadius: 16,
        width: '100%',
        minWidth: 200,
        marginBottom: 16,
        maxHeight: 300,
        boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
        minHeight: 50,
        transitionDuration: 500,
        "&:focus,&:hover": {
            border: `solid 1px ${theme.palette.primary.main}`,
            boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 16,
        },
    },
    box: {
        display: 'flex',
        height: '70vh',
        backgroundColor: 'red'
    },
    imageFrame: {
        width: '100%',
        float: 'right',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    imageLayout: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        [theme.breakpoints.up('md')]: {
            width: '50vw',
            height: 'calc(100vh - 60px)',
        },
    },
    image: {
        width: '100%',
        minWidth: '100%',
        float: 'right',
        maxHeight: 'calc(100vh - 120px)',
        overflow: 'hidden',
        zIndex: 1000,
        marginBottom: 32,
        [theme.breakpoints.up('md')]: {
            minWidth: '60%',
        },
    },
    background: {
        width: '100%',
        height: 'calc(100vh - 60px)',
    },
    iconButton: {
        position: 'fixed',
        top: 8,
        right: 8,
        color: 'rgba(200,200,200,0.8)',
        backgroundColor: theme.palette.primary.main,
        opacity: 0.9
    },
    section: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    label: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    footer: {
        height: '13%',
        backgroundColor: '#ec008c',
        posi: 'right',
        bottom: 0,
        width: '100%',
        zIndex: 0
    }
}));
const month = (new Date()).getMonth() + 1;
const FacturacionView = () => {
    const classes = useStyles();
    const [ref, { height, width }] = useMeasure();
    const [open, setOpen] = useState(false);
    const [column, { columnH, columnW }] = useMeasure();
    const theme = useTheme();
    useEffect(() => {
        repository.getRFCData('https://siat.sat.gob.mx/app/qr/faces/pages/mobile/validadorqr.jsf?D1=10&D2=1&D3=14040666186_DEFS830606IL1');
    },[])
    const data = {
        "month": "07",
        "media": [
            { "type": "jpg", "name": "1" },
            { "type": "jpg", "name": "2" },
            { "type": "jpg", "name": "3" }
        ]
    };
    const dataBase = {
        "month": "07",
        "media": [
            { "type": "png", "name": "1" },
            { "type": "png", "name": "2" },
        ]
    };
    const backgrondsColors = [
        'rgba(168,126,226,1)',
        'rgba(95,176,143,1)',
        'rgba(211,116,177,1)',
    ]
    const portland = !!(window.screen.height > window.screen.width);
    console.log('is port', portland);
    return (
        <Box key={'pro'} ref={ref} style={{ height: '100vh', overflow: 'hidden', overflowY: 'scroll' }}>
            <div id='dataDemo' style={{position: 'absolute'}}/>
            <Box style={{ position: 'relative' }}>
                <ResponsiveLayout>
                    <Box p={2}>
                        <Typography color='primary' variant={'h5'} style={{ textAlign: 'center', width: '100%', fontWeight: 800, marginTop: 10, marginBottom: 10 }} className={classes.subTitles} >
                            {'Módulo de facturación'}
                        </Typography>
                    </Box>
                    <Box
                        p={1}
                        style={{
                            width: '100%', flexDirection: 'column', overflow: 'hidden', overflowX: 'auto',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        <Box p={4} style={{
                            borderRadius: 20,
                            border: '3px solid white',
                            boxShadow: '0px 0px 10px gray',
                            backgroundColor: theme.palette.primary.main,
                            position: 'relative',
                        }} >
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant={'h5'} style={{ textAlign: 'center', width: '100%', fontWeight: 800, color: 'white', paddingBottom: 16, whiteSpace: 'normal' }}  >
                                        {'Nuestro módulo de facturación en línea lo hace fácil'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider style={{ backgroundColor: 'white' }} />
                        </Box>
                        <Grid spacing={2} container direction='row' style={{ height: '100%', marginBottom: 16 }}>
                            {[
                                {
                                    mask: MaskQR,
                                    nombre: 'Nueva',
                                    tag: 'nueva',
                                    recomentaciones: [
                                    ],
                                    observaciones: [
                                        'Solicita tu factura.',
                                    ]
                                },
                                {
                                    mask: MaskList,
                                    nombre: 'Mis facturas',
                                    tag: 'listado',
                                    recomentaciones: [
                                       
                                    ],
                                    observaciones: [
                                        'Revisa todas tus facturas.',
                                    ]
                                },
                                {
                                    mask: MaskEdit,
                                    nombre: 'Editar mis datos',
                                    tag: 'editar',
                                    recomentaciones: [
                                        
                                    ],
                                    observaciones: [
                                        'Edita tus datos fiscales.',
                                    ]
                                }
                            ].map((item, index) => {
                                const Mask = item.mask;
                                return (
                                    <Grid key={index} item xs={portland ? 12 : 4} style={{ position: 'relative' }}>
                                        <Box style={{ height: 40, width: '100%', display: 'flex', justifyContent: 'center', zIndex: 1000 }} />
                                        <Box style={{ position: 'absolute', top: 40, left: 0, width: '100%', display: 'flex', justifyContent: 'center', zIndex: 1000 }}>
                                            <Box style={{
                                                backgroundColor: theme.palette.secondary.main,
                                                boxShadow: '0px 0px 10px gray',
                                                float: 'center',
                                                alignSelf: 'center', border: '2px solid white', borderRadius: 40, padding: 8
                                            }}>
                                                <Mask style={{ backgroundColor: 'white', alignSelf: 'center' }} />
                                            </Box>
                                        </Box>
                                        <Box p={4} style={{
                                            borderRadius: 20,
                                            border: '3px solid white',
                                            boxShadow: '0px 0px 10px gray',
                                            left: 8,
                                            right: 8,
                                            top: 48,
                                            bottom: 8,
                                            backgroundColor: theme.palette.primary.main,
                                            position: 'absolute',
                                            marginTop: 34,
                                            paddingTop: 40
                                        }} />
                                        <Box p={3} style={{
                                            // borderRadius: 20,
                                            // border: '3px solid white',
                                            // boxShadow: '0px 0px 10px gray',
                                            // backgroundColor: 'transparent' || theme.palette.primary.main,
                                            position: 'relative',
                                            marginTop: 34,
                                            paddingTop: 40
                                        }} >
                                            <Grid container>
                                                <Grid item xs>
                                                    <Typography variant={'h6'} style={{ textAlign: 'center', width: '100%', fontWeight: 800, color: 'white', paddingBottom: 16 }}  >
                                                        {item.nombre}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider style={{ backgroundColor: 'white' }} />
                                            <Box p={0}>
                                                <Grid container>
                                                    <Typography variant={'caption'} style={{ textAlign: 'center', width: '100%', color: 'white', fontSize: 18, whiteSpace: 'normal', padding: 5 }}  >
                                                        {item.muestra ? `(${item.muestra || ''})` : ''}
                                                    </Typography>
                                                </Grid>
                                                {
                                                    (item.recomentaciones || []).map((r, i) => {
                                                        return (
                                                            <Grid container>
                                                                <Grid item xs='auto'>
                                                                    <Box p={1.5}>
                                                                        <Box style={{ width: 6, height: 6, borderRadius: 3, backgroundColor: 'white', boxShadow: '0px 0px 3px gray' }} />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <Typography variant={'caption'} style={{ textAlign: 'justify', float: 'left', width: '100%', color: 'white', fontSize: '0.9rem', whiteSpace: 'normal' }}  >
                                                                        {r}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })
                                                }

                                            </Box>

                                        </Box>
                                        <Box position={'absolute'} style={{
                                            top: '100%',
                                            left: 8,
                                            right: 8,
                                        }}>
                                            {
                                                (item.observaciones || []).map((o, i) => {
                                                    return (
                                                        <Grid container height={60}>
                                                            <Grid item xs>
                                                                <Typography variant={'caption'} style={{ textAlign: 'center', float: 'left', width: '100%', color: 'gray', fontSize: 16, whiteSpace: 'normal' }}  >
                                                                    {o}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>
                    <Box p={4}>
                        <Typography color='primary' variant={'h5'} style={{ textAlign: 'center', width: '100%', fontWeight: 800, marginBottom: 10 }} >
                            {'Tus estudios'}
                        </Typography>
                    </Box>
                    <FacturacionForm />
                </ResponsiveLayout>
            </Box>
            <Dialog open={open}
                onClose={() => setOpen(false)}
                PaperProps={{ style: { backgroundColor: 'transparent', borderRadius: 8 } }}
            >
                <Delivery covid />
            </Dialog>
        </Box>
    );
};

export default FacturacionView;

