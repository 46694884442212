import * as React from "react";
import { useForm } from "react-hook-form";
import model from 'constants/forms/registroPaciente.json'
import { makeStyles } from '@material-ui/core/styles';
// import "./styles.css";
import { Box, Button, Grid, InputBase, Typography } from "@material-ui/core";
import Select from "./Select";
const useStyles = makeStyles(theme => ({
  input: {
    display: 'block',
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: 4,
    border: 'none',
    padding: '10px 10px',
    marginBottom: 10,
    fontSize: 14,
    boxshadow: '0px 0px 2px gray',
    /* outline: none, */
    // backgroundColor: 'white',
    outlineColor: theme.palette.primary.main,
  },
  submit: {
    display: 'block',
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: 4,
    padding: '10px 15px',
    marginBottom: 10,
    fontSize: 14,
    boxshadow: '0px 0px 2px gray',
    border: 'none',
    color: 'inherit',
    backgroundColor: theme.palette.primary.main,
    outlineColor: theme.palette.primary.main,
  },
  label: {
    lineHeight: 0.5,
    textAlign: "left",
    display: 'block',
    marginBottom: 13,
    marginTop: 20,
    fontSize: 14
  },
  pagePaddingTop: {
    padding: 0,
    width: '100%',
    maxWidth: '100%',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },

  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  shape: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
    },
  },
}));

const Forms = (model) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { touched }
  } = useForm({
    defaultValues: {
      firstName: "Bill",
      lastName: "luo",
      amount: 5
    }
  });
  const save = async (data) => {

  }
  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  console.log("touched", touched, model);

  return (
    <Box >
      <Grid container>
        <Grid item>
          {
            model.title &&
            <Typography>
              {model.title || ''}
            </Typography>
          }
          {
            model.subTitle &&
            <Typography>
              {model.subTitle || ''}
            </Typography>
          }
          {
            model.messages &&
            (model.messages || []).map((message, index) => {
              return (
                <Typography>
                  {message.name || ''}
                </Typography>
              )
            })
          }
        </Grid>
        <Grid item>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              {
                (model || []).map((field, index) => {
                  switch (field.type) {
                    case ('select'): {
                      return (
                        <Grid item xs={12}>
                          <Select
                            classes={classes}
                            register={register}
                            value={null}
                            field={field}
                          />
                        </Grid>
                      )
                    }
                    default: {
                      return (
                        <Grid item xs={12}>
                          <label className={classes.label}>{field.vc_label}</label>
                          <input className={classes.input} autoComplete={'off'} type={field.type || 'text'} {...register(field.vc_key, { required: field.required })} />
                        </Grid>
                      )
                    }
                  }
                })
              }
            </Grid>
            <Button variant={'contained'} color='primary' type="submit" style={{ float: 'right', marginBottom: '' }}>
              {'Guardar'}
            </Button>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Forms;
