import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    GraphResults,
    ListResults
} from '../../components';
import { Box, Grid, IconButton, ListItem, Paper, Typography } from '@material-ui/core';
import { CloseOutlined, PersonOutlined } from '@material-ui/icons';
import { setListPatients, setPatient } from 'redux/studies';

const PatientSelected = ({ modeView, setModeView, searchText = '' }) => {
    const dispatch = useDispatch()
    const globalUser = useSelector(state => state.session.globalUser);
    const patient = useSelector(state => state.studies.patient);
    useEffect(() => {
        return () => {
            console.log('reset------->')
            // dispatch(setPatient(null));
        }
    }, [])
    return (
        <Box >
            <ListItem component={Paper} key={'top'} style={{ borderRadius: 16, overflow: 'hidden', marginBottom: 16, boxShadow: '0px 0px 5px gray' }}>
                <Grid container direction='row'>
                    <Grid item xs={'auto'}>
                        <IconButton style={{ marginRight: 8 }}>
                            <PersonOutlined />
                        </IconButton>
                    </Grid>
                    <Grid item xs direction='column'>
                        <Grid item xs={12}>
                            <Typography variant='caption' style={{ marginRight: 8, fontWeight: 600 }}>
                                {'Nombre:'}
                            </Typography>
                            <Typography variant='caption' style={{ marginRight: 8, fontWeight: 600 }}>
                                {patient?.paciente || patient?.nombre || ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} direction='row' container >
                            <Grid item xs={'auto'} >
                                <Typography variant='caption' style={{ marginRight: 8, fontWeight: 600 }}>
                                    {'Sexo:'}
                                </Typography>
                                <Typography variant='caption' style={{ width: '100%', textAlign: 'right' }} >
                                    {patient?.sexo}
                                </Typography>
                            </Grid>
                            <Grid item xs />
                            <Grid item xs={'auto'} >
                                <Typography variant='caption' style={{ marginRight: 8, fontWeight: 600 }}>
                                    {'Fecha Nac.:'}
                                </Typography>
                                <Typography variant='caption' >
                                    {patient?.fecha_nac}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        globalUser?.tipo !== 'paciente' &&
                        <Grid item xs={'auto'}>
                            <IconButton onClick={() => dispatch(setPatient(null))}>
                                <CloseOutlined />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </ListItem>
            {
                modeView === 'grid' ? <GraphResults searchText={searchText} modeView={modeView} /> :
                    <ListResults modeView={modeView} searchText={searchText} />
            }
        </Box>
    );
};

export default PatientSelected;
