import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import repository from './repository';

const useStyles = makeStyles(theme => ({
  
}));

/**
 * Component to display the basic card
 *
 * @param {Object} props
 */
const Promotions = props => {
  const {
    
  } = props;

  const classes = useStyles();
  const [data, setData] = useState([]);
useEffect(() => {
    repository.getPromotions(setData);
}, [])
  return (
    <Box
    >
      {
          data.map((item, index) => {
              return (
                <Box>
                    <Typography>
                        
                    </Typography>
                </Box>
              );
          })
      }
    </Box>
  );
};

export default Promotions;
