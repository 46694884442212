const session = {
    login: async (body, callback) => {
        const {page, rows} = body || {};
        const url = `${process.env.REACT_APP_API_URL}/login/paciente`;
        return fetch(url, {
            method: 'POST',
            // mode: 'cors',
            // credentials: 'include',
            body: JSON.stringify(body)
        }).then(res => res.json());
    }
};

export default session;

   