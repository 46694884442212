import React, { useState } from 'react';
import { Box, Typography, InputBase, Button, Grid, useTheme, Divider, IconButton } from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import styled from 'styled-components';
const MaskPCR = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/torunda.png'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const MaskSuc = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/location.svg'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const MaskRes = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/notepad.svg'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const MaskGlobe = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/pandemia.svg'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const MaskAnt = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/muestra.png'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const MaskAntg = styled('div')`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  padding: 0px;
  background-color: red;
  background-size: '60px 60px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'images/covid/nariz.svg'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;

export default function ModulesLink() {
    const theme = useTheme();
    const portland = !!(window.screen.height > window.screen.width);
    return (
        <Grid spacing={2} container direction='row'>
            {[
                {
                    mask: MaskGlobe,
                    nombre: 'Covid en el mundo',
                    muestra: 'Johns Hopkins University',
                    tiempoResultados: 'El mismo dia (muestras tomadas antes de las 10 am.',
                    recomentaciones: [
                        'Casos activos de COVID-19 en el mundo.',
                    ],
                    observaciones: [
                        '** La información puede tener algunos días desde su última actualización.',
                    ],
                    button: <Box style={{ justifyContent: 'flex-end', width: '100%', display: 'flex' }}>
                        <Button href='/covid-globe' style={{ color: 'white', alignSelf: 'flex-end' }}>
                            {'Ver'}
                        </Button>
                    </Box>
                },
                {
                    mask: MaskSuc,
                    nombre: 'Sucursales',
                    muestra: 'Localiza tu sucursal',
                    tiempoResultados: 'El mismo dia (hasta en 15 minutos).',
                    recomentaciones: [
                        'Contamos con 35 sucursales en 10 ciudades.',
                    ],
                    observaciones: [
                        '** Utiliza el cotizador.',
                    ],
                    button: <Box style={{ justifyContent: 'flex-end', width: '100%', display: 'flex' }}>
                        <Button href='/sucursales' style={{ color: 'white', alignSelf: 'flex-end' }}>
                            {'Ver'}
                        </Button>
                    </Box>
                },
                {
                    mask: MaskRes,
                    nombre: 'Resultados',
                    muestra: 'Descárgalos en línea',
                    tiempoResultados: 'El mismo dia (muestras tomadas antes de las 10 am.',
                    recomentaciones: [
                        'Descargar tus resultados ahora es muy fácil y rápido.',
                    ],
                    observaciones: [
                        '** Podrás revisar el historial de tus resultados.',
                    ],
                    button: <Box style={{ justifyContent: 'flex-end', width: '100%', display: 'flex' }}>
                        <Button href='/viewer' style={{ color: 'white', alignSelf: 'flex-end' }}>
                            {'Ver'}
                        </Button>
                    </Box>
                }
            ].map((item, index) => {
                const Mask = item.mask;
                return (
                    <Grid key={index} item xs={portland ? 12 : 4} style={{ position: 'relative' }}>
                        <Box style={{ height: 40, width: '100%', display: 'flex', justifyContent: 'center', zIndex: 1000 }} />
                        <Box style={{ position: 'absolute', top: 40, left: 0, width: '100%', display: 'flex', justifyContent: 'center', zIndex: 1000 }}>
                            <Box style={{
                                backgroundColor: theme.palette.secondary.main,
                                boxShadow: '0px 0px 10px gray',
                                float: 'center',
                                alignSelf: 'center', border: '2px solid white', borderRadius: 40, padding: 8
                            }}>
                                <Mask style={{ backgroundColor: 'white', alignSelf: 'center' }} />
                            </Box>
                        </Box>
                        <Box p={4} style={{
                            borderRadius: 20,
                            border: '3px solid white',
                            boxShadow: '0px 0px 10px gray',
                            backgroundColor: theme.palette.primary.main,
                            position: 'relative',
                            marginTop: 34,
                            paddingTop: 40,
                            paddingBottom: 16
                        }} >
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant={'h5'} style={{ textAlign: 'center', width: '100%', fontWeight: 800, color: 'white', paddingBottom: 16 }}  >
                                        {item.nombre}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider style={{ backgroundColor: 'white' }} />
                            <Box p={0}>
                                <Grid container>
                                    <Typography variant={'caption'} style={{ textAlign: 'center', width: '100%', color: 'white', fontSize: 18, whiteSpace: 'normal', padding: 5 }}  >
                                        {`(${item.muestra})`}
                                    </Typography>
                                </Grid>
                                {
                                    item.recomentaciones.map((r, i) => {
                                        return (
                                            <Grid key={i} container>
                                                <Grid item xs='auto'>
                                                    <Box p={1.5}>
                                                        <Box style={{ width: 6, height: 6, borderRadius: 3, backgroundColor: 'white', boxShadow: '0px 0px 3px gray' }} />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant={'caption'} style={{ textAlign: 'justify', float: 'left', width: '100%', color: 'white', fontSize: '0.9rem', whiteSpace: 'normal' }}  >
                                                        {r}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }

                            </Box>
                            {item.button}
                        </Box>
                        {
                            item.observaciones.map((o, i) => {
                                return (
                                    <Grid key={i} container>
                                        <Grid item xs>
                                            <Typography variant={'caption'} style={{ textAlign: 'center', float: 'left', width: '100%', color: 'gray', fontSize: 16, whiteSpace: 'normal' }}  >
                                                {o}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            })}
        </Grid>
    )
}