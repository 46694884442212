import React, { useEffect, useRef, useState } from "react";
// import ReactGlobe from "react-globe";


// import "tippy.js/dist/tippy.css";
// import "tippy.js/animations/scale.css";
// import moment from "moment";

function markerTooltipRenderer(marker) {
    return `${marker.country} - ${marker.county || marker.province || ''}: Confirmados - ${marker.stats.confirmed} , Decesos - ${marker.stats.deaths}`;
}

const options = {
    markerTooltipRenderer
};

function Globe() {
    const [markers, setMarkers] = useState([]);
    const [markersFiltered, setMarkersFiltered] = useState([]);
    const ref = useRef();
    const [event, setEvent] = useState(null);
    const [details, setDetails] = useState(null);
    const [ready, setReady] = useState(false);
    function goToDataCovid() {
        let covid = window.amplify.store("covid19") || {};
        const url = "https://corona.lmao.ninja/v2/jhucsse";

        fetch(url, {
            method: 'GET',
        }).then(async (res) => {
            const data = await res.json();
            const covid = data.map((e, index) => {
                return ({
                    id: index + 1,
                    ...e,
                    color: 'red',
                    coordinates: [e.coordinates.latitude, e.coordinates.longitude],
                    value: e.stats.confirmed / 5000,
                })
            });
            setMarkers(covid);
            // structureData    CovidHistorical();
        }).catch(e => console.log('error', e));
    }
    function structureDataCovidHistorical() {
        let covid = window.amplify.store("covid19") || {};
        const url = "https://corona.lmao.ninja/v2/historical";

        fetch(url, {
            method: 'GET',
        }).then(async (res) => {
            const data = await res.json();
            const countries = covid.countries || [];
            covid.countries = (countries.length ? countries : []).map(
                (item, index) => {
                    const country = item;
                    const countryNew = (Array.isArray(data) ? data : []).find(
                        (e) =>
                            e.country.toLowerCase() === country.country.toLowerCase() &&
                            (e.province || "").toLowerCase() ===
                            (country.province || "").toLowerCase()
                    );
                    if (countryNew) {
                        return {
                            ...country,
                            ...countryNew.timeline,
                            vc_nombre:
                                (
                                    this.props.paises.find(
                                        (e) => e.vc_abreviatura === countryNew.country_code
                                    ) || {}
                                ).vc_nombre || null,
                        };
                    }
                    // console.log('no encontrado timeline', country.country, country.province)
                    return country;
                }
            );
            covid.structured = true;
            covid.historical = data;
        }).catch(e => console.log('error', e));
    }
    useEffect(() => {
        goToDataCovid();
    }, [])
    useEffect(() => {
        console.log('ref', ref)
        if (ref.current) {
            console.log('ref', ref.current)
        }
    }, [ref.current])
    function structureDataCovid() {
        let covid = window.amplify.store("covid19") || {};
        // console.log('covid', covid)
        const url = "https://coronavirus-tracker-api.herokuapp.com/v2/locations";

        fetch(url, {
            method: 'GET',
        }).then(async (res) => {
            const data = await res.json();

            covid.countries = covid.countries.map((item, index) => {
                const country = item;
                switch (item.country) {
                    case "uk": {
                        country.country = "United Kingdom";
                        if (country.province === "uk")
                            country.province = "United Kingdom";
                        break;
                    }
                    case "usa": {
                        country.country = "US";
                        if (country.province === "u.s. virgin islands")
                            country.province = "Virgin Islands";
                        break;
                    }
                    case "bosnia": {
                        country.country = "Bosnia and Herzegovina";
                        break;
                    }
                    case "uae": {
                        country.country = "United Arab Emirates";
                        break;
                    }
                    case "czech republic": {
                        country.country = "Czechia";
                        break;
                    }
                    default: {
                        break;
                    }
                }
                const countryNew = data.locations.find(
                    (e) =>
                        e.country.toLowerCase() === country.country.toLowerCase() &&
                        ((e.province || "").toLowerCase() ===
                            (country.province || "").toLowerCase() ||
                            (country.province === null && e.province === ""))
                );
                if (countryNew) {
                    return {
                        ...country,
                        ...countryNew,
                        vc_nombre:
                            (
                                this.props.paises.find(
                                    (e) => e.vc_abreviatura === countryNew.country_code
                                ) || {}
                            ).vc_nombre || null,
                    };
                }
                // console.log('no encontrado', country.country, country.province)
                return country;
            });
            covid.structured = true;
            // console.log('covid', covid)

        }).catch(e => console.log('error', e));
    }

    function onClickMarker(marker, markerObject, event) {

        setEvent({
            type: "CLICK",
            marker,
            markerObjectID: markerObject.uuid,
            pointerEventPosition: { x: event.clientX, y: event.clientY }
        });
        setDetails(markerTooltipRenderer(marker));
    }
    function onDefocus(previousFocus) {
        setEvent({
            type: "DEFOCUS",
            previousFocus
        });
        setDetails(null);
    }

    return (
        [<div/>
        //     <ReactGlobe
        //     ref={ref}
        //     height="calc(100vh - 60px)"
        //     markers={markers}
        //     options={options}
        //     width="100%"
        //     focus={[25.8257, -108.214]}
        //     onClickMarker={onClickMarker}
        //     onDefocus={onDefocus}
        //     onGlobeBackgroundTextureLoaded={() => setReady(true)}
        // />
        ]
    )
}

export default Globe;