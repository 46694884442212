import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  ListItem,
  Typography,
  Button,
  InputBase,
  Popover,
  ButtonGroup,
  List,
  debounce,
  IconButton,
  ListItemText
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import {
  LocalizationProvider,
  MobileDateRangePicker,
  DateRangeDelimiter,
  DesktopDateRangePicker,
  DateRange,
} from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { format, startOfMonth } from 'date-fns';
import esLocale from "date-fns/locale/es";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import internalAPI from '../../../utils/internalAPI';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TimelineIcon from '@material-ui/icons/Timeline';
import ListIcon from '@material-ui/icons/List';
import DateRangeIcon from '@material-ui/icons/DateRange';

import { setView, setResults, setResultsGrouped, setListPatients, setResultsImage } from '../../../redux/studies';

import types from '../../../constants/types.json';
import results from '../../../constants/results.json';
var es = require("apexcharts/dist/locales/es.json");

const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: 16,
    border: `solid 1px rgba(255,255,255,0.2)`,
    padding: 8,
    width: '100%',
    height: 'auto',
    boxShadow: '0px 0px 5px gray',
    transitionDuration: 200,
    "&:focus,&:hover": {
      backgroundColor: 'rgba(255,255,255,1)',
      border: `solid 1px ${theme.palette.primary.main}`,
      boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
    },
    [theme.breakpoints.up('md')]: {
      height: 60
    },
  },
  inputBase: {
    flex: 1,
    padding: '5px 16px',
    boxShadow: 'none',
    "&:focus,&:hover": {
      backgroundColor: 'inherit',
      border: 0,
      boxShadow: 'none',
    }
  },
  toggleGroup: {
    paddingLeft: 8,
    paddingRight: 8
  }
}));

const state = {

  series: [{
    name: 'series1',
    data: [31, 40, 28, 51, 42, 109, 100]
  }, {
    name: 'series2',
    data: [11, 32, 45, 32, 34, 52, 41]
  }],
  options: {
    title: {
      text: undefined,
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238'
      },
    },
    legend: {
      show: false
    },
    markers: {
      size: 4,
      colors: ["lightGray"],
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      }
    },
    stroke: {
      width: [4, 1, 1],
      curve: 'smooth',
    },
    colors: ['#7adec0','rgba(255,0,0,0.2)', 'rgba(255,0,0,0.2)'],
    chart: {
      height: 350,
      type: 'line',
      locales: [es],
      defaultLocale: 'es',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
    },
    xaxis: {
      type: 'datetime',
      categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
    },
    dataLabels: {
      enabled: true,
      offsetY: -8,
      style: {
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        colors: ['black']
      },

      background: {
        enabled: false,
        foreColor: '#fff',
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#fff',
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      }
    },
    tooltip: {
      x: {
        format: 'dd/MMMM/yyyy'
      },
    },
  },
}

const createResultAsGraphData = (estudios) => {
  const groups = [];
  console.log('sdadvadlkvnavlkadvdlakvjadlkvjdalkvadjvlk')
  let texting = `Se realizan estudios de laboratorio: \n`;
  const resultados = [];
  estudios.map((estudio) => {
    const id_estudio = estudio.id_estudio;
    estudio.resultados.map((resultado) => {
      // console.log('resultado', resultado)
      resultado.analitos.resultados.filter(e => e.tipo_elemento === 'Resultado').map((i) => {
        resultados.push({
          ...i,
          id_estudio,
          fecha: new Date(resultado.fecha) * 1,
          id_resultado: resultado.id,
          id_orden: resultado.id_orden,
        })
      });
    })
  });
  const dataSeries = {};
  resultados.map((resultado) => {
    // console.log('resultado.titulo', resultado)
    const prev = (dataSeries[resultado.cve_elemento] || { name: resultado.titulo, resultados: [] });
    dataSeries[resultado.cve_elemento] = {
      ...prev,
      resultados: [...(prev.resultados || []), resultado]
    }
  });
  Object.values(dataSeries).map((i, index) => {
    const maxs = []
    const mins = []
    const valores = []
    const categories = []
    let sn_text = false;
    let speech = `Se ${i.resultados.length === 1 ? 'tiene un' : `tienen ${i.resultados.length}`} ${i.resultados.length === 1 ? 'registro' : `registros`} de ${(i.name || '').replaceAll(' *', '')}, `;
    texting = `${texting}${(i.name || '').replaceAll(' *', '')}`
    i.resultados.map((item, index) => {
      const valRef = item.valref || {};
      const valor = item.resultado * 1;
      const valorText = item.resultado;
      const minVR = valRef.vr_min * 1
      const maxVR = valRef.vr_max * 1
      const texto = valRef.txt
      const fecha = item.fecha;
      categories.push(fecha)
      speech = `${speech} ${index === 0 ? 'uno ' : 'otro'} el ${new Date(item.fecha).toLocaleDateString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} con un resultado ${valor > -1 ? `de ${valor}` : `${valorText}`} ${item.unidad || ''}`
      switch (true) {
        case (valor > maxVR && maxVR !== NaN): {
          speech = `${speech} el cual está por encima del rango de referencia, `
          if (index + 1 === i.resultados.length) {
            texting = `${texting}: ${valor} ${item.unidad} (↑), `
          }
          break;
        }
        case (valor < minVR && minVR !== NaN): {
          speech = `${speech} el cual está por debajo del rango de referencia, `
          if (index + 1 === i.resultados.length) {
            texting = `${texting}: ${valor} ${item.unidad} (↓), `
          }
          break;
        }
        case (valor > minVR && valor < maxVR && minVR !== NaN && maxVR !== NaN): {
          speech = `${speech} el cual está dentro del rango de referencia, `
          if (index + 1 === i.resultados.length) {
            texting = `${texting}: ${valor} ${item.unidad} (✔), `
          }
          break;
        }
        default: {
          if (index + 1 === i.resultados.length) {
            texting = `${texting}: ${valor} ${item.unidad}, `
          }
          break;
        }
      }
      if (minVR !== undefined && !isNaN(minVR)) {
        mins.push(minVR)
      }
      if (maxVR !== undefined && !isNaN(maxVR)) {
        maxs.push(maxVR)
      }
      if (valor !== undefined && !isNaN(valor)) {
        // console.log('valores',valor, isNaN(valor));
        valores.push(valor)
      } else {
        valores.push(valorText);
        sn_text = true;
      }
    });

    const series = [];
    const options = { ...state.options };

    if (valores.length) {
      series.push({
        name: (i.name || '').replaceAll(' *', ''),
        data: valores
      })
    }

    if (categories.length) {
      options.xaxis.categories = categories;
    }

    let colors = ['7adec0'];
    let stops = [];

    switch (true) {
      // case (!!(mins.length && maxs.length)): {
      //   colors = ['#ff3636', '#7adec0', '#ff3636'];
      //   stops = []
      //   break;
      // }
      // case (mins.length): {
      //   colors = ['#ff3636', '#7adec0'];
      //   stops = [0, mins[0]]
      //   break;
      // }
      // case (maxs.length): {
      //   colors = ['#7adec0', '#ff3636'];
      //   stops = [0, maxs[0]]
      //   break;
      // }

      case (!!(mins.length && maxs.length)): {
        series.push({
          name: 'Referencia Minima',
          data: valores.map(e => mins[0])
        })

        series.push({
          name: 'Referencia Maxima',
          data: valores.map(e => maxs[0])
        })
        colors = ['gray', 'red', 'red']

        break;
      }
      case (mins.length): {
        series.push({
          name: 'Referencia Minima',
          data: valores.map(e => mins[0])
        })
        colors = ['gray', 'red']
        break;
      }
      case (maxs.length): {
        series.push({
          name: 'Referencia Maxima',
          data: valores.map(e => maxs[0])
        })
        colors = ['gray', 'red']
        break;
      }
      default: {
        colors = ['#7adec0'];
        stops = []
      }
    }

    if (!sn_text)
      groups.push({
        nombre: (i.name || '').replaceAll(' *', ''),
        series,
        speech,
        options: {
          ...options,
          title: { text: (i.name || '').replaceAll(' *', '') },
          xaxis: {
            type: 'datetime',
            // tickAmount: 10,
            categories: [...categories]
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              gradientToColors: [...colors],
              shadeIntensity: 1,
              type: 'vertical',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [...stops]
            },
          }
        }
      });
  });
  console.log('groups', groups)

  return { groups, texting };
}
const searchStudios = debounce((callback) => {
  callback();
}, 300);
const SearchStudiesResultsBar = ({ modeView, setModeView, searchText = '', setSearchText = () => { } }) => {
  const dispatch = useDispatch();
  const globalUser = useSelector(state => state.session.globalUser);
  const resultsGrouped = useSelector(state => state.studies.resultsGrouped);
  const patient = useSelector(state => state.studies.patient);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const [study, setStudy] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElView, setAnchorElView] = useState(null);
  // const [dateRange, setDateRange] = React.useState([startOfMonth(new Date()), new Date()]);
  const [dateRange, setDateRange] = React.useState([new Date('2000-01-01'), new Date()]);
  const [openDateRangePicker, setOpenDateRangePicker] = React.useState(false);
  const getListPatients = async (searchTex) => {
    const response = await internalAPI.patientList({ searchText, type: globalUser.tipo, startDate: dateRange[0], endDate: dateRange[1] });
    console.log('response------------>', response)
    dispatch(setListPatients({ ...response, page: 1, query: { searchText, type: globalUser.tipo, startDate: dateRange[0], endDate: dateRange[1] } }));
  }
  const getStudies = async () => {
    const [startDate, endDate] = dateRange;
    console.log('patient ------>', patient);

    const response = await internalAPI.historyStudiesPatientsCustom({ type: globalUser?.tipo, startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd'), id_paciente: patient?.id })
    let studies = {};
    let studiesImage = {};
    console.log('response', response);
    (response?.data || []).map((study) => {
      if (!!(study.resultados[0] || {}).rutas_dcm) {
        studiesImage[study.id_estudio] = {
          estudio: study.estudio,
          id_estudio: study.id_estudio,
          rutas_dcm: (study.resultados[0] || {}).rutas_dcm || []
        };
      }
      study.resultados.map(result => {
        if (!studies[result.folio]) {
          studies[result.folio] = {
            id: result.id,
            folio: result.folio,
            fecha: result.fecha,
            id_orden: result.id_orden,
            facturas: result.facturas,
            resultados: result.analitos.resultados,
          };
        } else {
          studies[result.folio].resultados = [...studies[result.folio].resultados, ...result.analitos.resultados];
          // .slice(), result.analitos.resultados.slice()
          // if (!!result.rutas_dcm) {
          //   console.log('results   ------>', results)
          //   studies[result.folio].dicoms = [...studies[result.folio].dicoms || [], { urls: result.rutas_dcm, estudio: study.estudio, id_estudio: study.id_estudio }];
          // }
        }
      });
    });
    console.log('Object.values(studies)', Object.values(studies));
    console.log('response?.data', response?.data);

    console.log('Object.values(image)', (response?.data || []).filter((e) => e.clave_estudio?.startsWith('IMA') && e.resultados.filter(e => e.rutas_dcm?.length).length));
    // console.log('createResultAsGraphData(data)', createResultAsGraphData(data));
    console.log('resulss', studies)

    dispatch(setResults(createResultAsGraphData(response.data)));
    dispatch(setResultsGrouped(Object.values(studies)));
    dispatch(setResultsImage([...(response?.data || []).filter((e) => e.clave_estudio?.startsWith('IMA') && e.resultados.filter(e => e.rutas_dcm?.length).length)]));
  };

  useEffect(() => {
    setAnchorEl(null);
    if (globalUser) {
      searchStudios(() => {
        if (!!globalUser && globalUser.tipo !== 'paciente')
          getListPatients();
        if (!!patient)
          getStudies()
      });
    }
  }, [study]);

  useEffect(() => {
    console.log('patient', patient)
    if (!!patient) {
      getStudies();
    }
  }, [patient]);

  const handleModeView = (e, mode) => {
    setModeView(mode);
    // dispatch(setView(mode));
    setAnchorElView(null);
  };

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
      <ButtonGroup
        orientation={matches ? 'vertical' : 'horizontal'}
        className={classes.input}
        variant="contained"
        color="primary"
        aria-label="contained primary button group"
      >
        {/* <Button
          variant={'text'}
          endIcon={<ArrowDropDownIcon />}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          {study ? study.folio : 'ESTUDIOS'}
        </Button> */}

        <InputBase
          placeholder="Buscar..."
          onChange={(e) => setSearchText(e.target.value)}
          className={classes.inputBase}
        />

        {!matches && (
          <IconButton onClick={() => {
            setOpenDateRangePicker(!openDateRangePicker);
          }}>
            <DateRangeIcon />
          </IconButton>
        )}

        {matches && (
          <Button
            variant={'text'}
            endIcon={<DateRangeIcon />}
            onClick={(e) => setOpenDateRangePicker(true)}
          >
            FECHA
          </Button>
        )}

        {matches && (
          <Button
            variant={'text'}
            endIcon={<ArrowDropDownIcon />}
            onClick={(e) => setAnchorElView(e.currentTarget)}
          >
            {modeView === 'list' ? 'Listado' : 'Graficas'}
          </Button>
        )}

        {!matches && (
          <ToggleButtonGroup
            value={modeView}
            exclusive
            onChange={handleModeView}
            className={classes.toggleGroup}
          >
            <ToggleButton value="grid">
              <TimelineIcon />
            </ToggleButton>
            <ToggleButton value="list">
              <ListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </ButtonGroup>

      <Popover
        open={!!anchorEl && resultsGrouped.length > 0}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List style={{ maxHeight: 350, width: 'calc(100% - 8px)' }}>
          {(resultsGrouped || []).map((study, index) => (
            <ListItem
              key={index}
              button
              onClick={() => setStudy(study)}
            >
              <ListItemText primary={study.fecha} secondary={`Folio ${study.folio}`} />
            </ListItem>
          ))}
        </List>
      </Popover>


      {matches && (
        <Popover
          open={!!anchorElView}
          anchorEl={anchorElView}
          onClose={() => setAnchorElView(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List style={{ maxHeight: 200, width: 'calc(100% - 8px)' }}>
            <ListItem button onClick={(e) => handleModeView(e, 'list')}>
              <Typography>
                Listado
              </Typography>
            </ListItem>
            <ListItem button onClick={(e) => handleModeView(e, 'grid')}>
              <Typography>
                Graficas
              </Typography>
            </ListItem>
          </List>
        </Popover>
      )}

      {matches && (
        <MobileDateRangePicker
          toolbarTitle="Rango de fechas"
          startText="Inicio"
          endText="Fin"
          cancelText="Cancelar"
          okText="Aceptar"
          value={dateRange}
          disableFuture
          open={openDateRangePicker}
          onClose={() => setOpenDateRangePicker(false)}
          onChange={(newValue) => setDateRange(newValue)}
          renderInput={(startProps, endProps) => null}
        />
      )}

      {!matches && (
        <DesktopDateRangePicker
          toolbarTitle="Rango de fechas"
          startText="Inicio"
          endText="Fin"
          cancelText="Cancelar"
          okText="Aceptar"
          value={dateRange}
          disableFuture
          open={openDateRangePicker}
          onClose={() => setOpenDateRangePicker(false)}
          onChange={(newValue) => setDateRange(newValue)}
          renderInput={(startProps, endProps) => null}
        />
      )}
    </LocalizationProvider>
  );
};

export default SearchStudiesResultsBar;
