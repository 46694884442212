import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    globalUser: null,
    token: null,
    idPaciente: 0
  },
  reducers: {
    setGlobalUser: (state, action) => {
      state.globalUser = action.payload;
    },
    setLocation: (state, action) => {
      state.globalUser = action.payload;
    },
    RemoveGlobalUser: (state, action) => {
      state.globalUser = null;
      state.resultsGrouped = [];
      state.results = { groups: [] };
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    removeToken: (state) => {
      state.token = null;
    },
    setIdPaciente: (state, action) => {
      state.idPaciente = action.payload;
    },
    removeIdPaciente: (state) => {
      state.idPaciente = 0;
    }
  },
})

export const { setGlobalUser, setLocation, RemoveGlobalUser, setToken, removeToken, setIdPaciente, removeIdPaciente } = sessionSlice.actions;

export default sessionSlice.reducer;