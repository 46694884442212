import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import useMeasure from 'react-use-measure'
import visualContent from '../../constants/visualContent.json'
import { Carousel } from 'react-responsive-carousel';

const useStyles = makeStyles(theme => ({
    root: { padding: 20 },
    formContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
        maxWidth: 500,
        margin: `0 auto`,
    },
    box: {
        display: 'flex',
        height: '70vh',
        backgroundColor: 'red'
    },
    image: {
        width: '100%',
        maxWidth: 500,
        float: 'center',
        boxShadow: '1px 1px 5px gray',
        borderRadius: 10
    },
    iconButton: {
        position: 'fixed',
        top: 8,
        right: 8,
        color: 'rgba(200,200,200,0.8)',
        backgroundColor: theme.palette.primary.main,
        opacity: 0.9
    },
    section: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    label: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
}));
const Blog = () => {
    const classes = useStyles();
    const [column, { columnH, columnW }] = useMeasure();
    const [play, setPlay] = useState(null);
    return (
        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box ref={column} padding={2} style={{ width: '100%', alignSelf: 'center', maxWidth: 800 }}>
                <Typography color='primary' variant={'h5'} style={{ textAlign: 'center', width: '100%', fontWeight: 800, marginBottom: 24 }} className={classes.subTitles} >
                    {'Cápsulas informativas'}
                </Typography>
                {
                    visualContent.map((item, i) => {
                        return (
                            <Box
                                key={i}
                                onMouseEnter={() => setPlay(i)}
                                onMouseLeave={() => setPlay(null)}
                                style={{ boxShadow: '0px 0px 10px rgba(100,100,100,0.5)', borderRadius: 16, overflow: 'hidden', marginBottom: 16, position: 'relative' }}>
                                <Typography
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        height: 60,
                                        width: '100%',
                                        padding: '8px 16px',
                                        color: 'white',
                                        background: 'linear-gradient(to bottom, rgba(100,100,100,0.8), rgba(100,100,100,0))',
                                        borderRadius: '0px 0px 0px 0px'
                                    }}
                                >
                                    {/* {item.vc_description} */}
                                </Typography>
                                <Box
                                    style={{ position: 'relative' }}
                                >
                                    {
                                        item.media.map((media, index) => {
                                            return (
                                                <ReactPlayer
                                                    key={index}
                                                    width={columnW - 32}
                                                    url={media.vc_url}
                                                    height={'auto'}
                                                    playing={!!(i === play)}
                                                    controls={!!(i === play)}
                                                />
                                            )
                                        })
                                    }
                                    <Typography

                                        style={{
                                            position: 'absolute',
                                            left: 0,
                                            top: 0,
                                            height: 60,
                                            width: '100%',
                                            padding: '8px 16px',
                                            color: 'white',
                                            background: 'linear-gradient(to bottom, rgba(100,100,100,0.8), rgba(100,100,100,0))',
                                            borderRadius: '0px 0px 0px 0px'
                                        }}
                                    >
                                        {item.vc_title}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    );
};

export default Blog;
