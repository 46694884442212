const homeCare = {
    getDates: async (body) => {
        const {page, rows} = body || {};
        const url = `${process.env.REACT_APP_API_URL}/citas/disponibilidad?fecha_inicial=2021-10-18 08:12:00&fecha_final=2021-10-18 11:12:00`;
        return await fetch(url, {
            method: 'GET',
        }).then(res => res.json()).catch(e => e);
    }
};

export default homeCare;

   