import React, { Component, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { QrReader } from 'react-qr-reader';

const useStyles = makeStyles(theme => ({
}));

const QRReader = ({ onClose, onLoad = () => { } }) => {
    const classes = useStyles();
    const [data, setData] = useState('');

    const handleScan = (scanData) => {
        if (scanData) {
            console.log('scanData', scanData)
            setData(scanData);
            onLoad(scanData);
        }
    };

    const handleError = () => {

    };

    return (
        <QrReader
            showViewFinder={false}
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%', height: '100%', overflow: 'hidden', borderRadius: 10, marginBottom: 16 }}
        />
    )
};

export default QRReader;