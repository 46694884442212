import adminEndPoints from './adminEndPoints';
import authEndPoints from './authEndPoints';
import studiesEndPoints from './studiesEndPoints';
import billingEndPoints from './billingEndPoints';

export default {
    ...authEndPoints,
    ...studiesEndPoints,
    ...adminEndPoints,
    ...billingEndPoints
};
