import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Button,
  TextField,
  Card,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Box
} from '@material-ui/core';
import validate from 'validate.js';

import { Modal } from 'components/molecules';
import { setToken, setIdPaciente } from 'redux/session';
import internalApi from 'utils/internalAPI';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  message: {
    height: theme.spacing(8)
  }
}));

const schema = {
  gender: {
    
  },
  birthday: {
    
  },
  phone: {
    
  },
  cellPhone: {
    
  },
  email: {
    
  },
  address: {
    
  },
  colony: {
    
  },
  city: {
    
  },
  state: {
    
  },
  country: {
    
  },
  zipCode: {
    
  },
  email: {
    presence: { allowEmpty: false, message: 'es requerido' },
    email: true,
    length: {
      maximum: 300,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      minimum: 4,
    },
  },
};


const Register = ({ children, open = false }) => {
  const classes = useStyles();
  const token = useSelector((state) => state.session.token);
  const dispatch = useDispatch();

  const [openRegister, setOpenRegister] = React.useState(open);
  const [message, setMessage] = React.useState(false);

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (formState.isValid) {
      (async () => {
        internalApi.register({
          nombre: formState.values.name,
          a_paterno: formState.values.lastName,
          a_materno: formState.values.secondLastName,
          sexo: formState.values.gender,
          fecha_nac: formState.values.birthday,
          telefono: formState.values.phone,
          celular: formState.values.cellPhone,
          email: formState.values.email,
          direccion: formState.values.address,
          colonia: formState.values.colony,
          ciudad: formState.values.city,
          estado: formState.values.state,
          pais: formState.values.country,
          cp: formState.values.zipCode
        }, (error, data = {}) => {

          if (data.status === 'error') {
            setMessage(data.mensaje);
            return;
          }

          //dispatch(setToken(data.api_token));
          //dispatch(setIdPaciente(data.id_paciente));
        });
      })();
      return;
    }

    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const onClick = () => setOpenRegister(true);
  const onCloseModal = () => setOpenRegister(false);

  let clonedChildren = null;

  if (React.Children.count(children)>0) {
    React.Children.only(children);
    clonedChildren = React.cloneElement(children, { onClick });
  }

  return (
    <React.Fragment>
      {clonedChildren}
    
      <Modal open={openRegister && !token} title="Registro" onClose={onCloseModal}>
        <form name="login" method="post" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                placeholder="Nombre"
                label="Nombre *"
                variant="outlined"
                size="medium"
                name="name"
                fullWidth
                helperText={hasError('name') ? formState.errors.name[0] : null}
                error={hasError('name')}
                onChange={handleChange}
                type="name"
                value={formState.values.email || ''}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                placeholder="Contraseña"
                label="Contraseña *"
                variant="outlined"
                size="medium"
                name="password"
                fullWidth
                helperText={
                  hasError('password') ? formState.errors.password[0] : null
                }
                error={hasError('password')}
                onChange={handleChange}
                type="password"
                value={formState.values.password || ''}
              />
            </Grid>

            <Grid item xs={12} className={classes.message}>
              {message}
            </Grid>

            <Grid item xs={12}>
              <Button
                size="large"
                variant="contained"
                type="submit"
                color="primary"
                fullWidth
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default Register;
