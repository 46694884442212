import React, { Suspense, useEffect, useRef, useState, lazy } from "react"
import { Canvas, useFrame, useLoader, } from "@react-three/fiber"
import { ContactShadows, Environment, } from "@react-three/drei"
// import Model3D from "../Model3D"

import styled from 'styled-components';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import CameraController from "../CameraController"
import { Avatar, Box, Button, ButtonGroup, Divider, List, ListItem, Typography } from "@material-ui/core"
import { AddAPhotoOutlined, FilterCenterFocusOutlined, FullscreenExitOutlined, ThreeDRotationOutlined, ThreeSixtyOutlined, VisibilityOffOutlined } from "@material-ui/icons"
import { useTheme } from "@material-ui/core";
import { teal } from "@material-ui/core/colors";
import { useMeasure } from "react-use";
import { Vector3 } from "three";
import { useSpeechSynthesis } from "react-speech-kit";
import meshes from 'constants/models/meshes.json'
import Model3D from "./Model3D";
import GroupModel3D from "./GroupModel3D";

const Mask = styled('div')`
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  padding: 0px;
  background-color: red;
  background-size: '30px 30px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'assets/images/cerebro.svg'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
export default function Scene3D({ selected, sex = { path: 'male' } }) {
    const [selector, setSelector] = useState('x');
    const [rotate, setRotate] = useState(true)
    const { speak, speaking, cancel } = useSpeechSynthesis();
    const [focus, setFocus] = useState(null);
    const [ref, { height, width }] = useMeasure();
    const refScene = useRef()
    const refBodyGroup = useRef()
    const handle = useFullScreenHandle();
    const theme = useTheme();

    const defaultColor = theme.palette?.primary ? theme.palette?.primary[500] : teal[500];
    const modes = [
        { id: 2, icon: FilterCenterFocusOutlined, description: 'Enfocar' },
        { id: 3, icon: VisibilityOffOutlined, description: 'Ocultar/Disecar' },
        { id: 4, icon: AddAPhotoOutlined, description: 'Image' }
    ]
    const [mode, setMode] = useState(modes[0]);
    const [removedParts, setRemovedParts] = useState([]);

    const [systems, setSystems] = useState([
        { id: 1, id_sexo: 3, selected: true, src: 'atras.svg', system: 'piel', description: 'Piel y tegumentos', alpha: 1, models: [9] },
        { id: 2, id_sexo: 3, selected: true, src: 'cerebro.svg', system: 'nervioso', description: 'Nervioso', alpha: 1, models: [4] },
        // { id: 3, id_sexo: 3, selected: true, src: 'globo-del-ojo.svg', system: 'ocular', description: 'Ocular', alpha: 1 },
        // { id: 4, id_sexo: 3, selected: true, src: 'pulmon.svg', system: 'respiratorio', description: 'Respiratorio', alpha: 1 },
        { id: 5, id_sexo: 3, selected: true, src: 'estomago.svg', system: 'digestivo', description: 'Digestivo', alpha: 1, models: [3] },
        // { id: 6, id_sexo: 3, selected: true, src: 'tiroides.svg', system: 'endocrino', description: 'Endrocrino', alpha: 1 },
        // { id: 7, id_sexo: 3, selected: true, src: 'rinones.svg', system: 'urinario', description: 'Urinario', alpha: 1 },
        // { id: 8, id_sexo: 1, selected: true, src: 'pene.svg', system: 'reproductor', description: 'Reproductor', alpha: 1, models: [5] },
        // { id: 9, id_sexo: 2, selected: true, src: 'matriz.svg', system: 'reproductor', description: 'Reproductor', alpha: 1, models: [5] },
        { id: 10, id_sexo: 3, selected: true, src: 'corazon.svg', system: 'vascular', description: 'Vascular', alpha: 1, models: [1] },
        { id: 11, id_sexo: 3, selected: true, src: 'ganglios-linfaticos.svg', system: 'linfatico', description: 'Linfatico', alpha: 1, models: [6] },
        { id: 12, id_sexo: 3, selected: true, src: 'musculo.svg', system: 'muscular', description: 'Muscular', alpha: 1, models: [5] },
        { id: 13, id_sexo: 3, selected: true, src: 'pelvis.svg', system: 'oseo', description: 'Oseo', alpha: 1, models: [2, 8] },
        { id: 14, id_sexo: 3, selected: true, src: 'tendon.svg', system: 'conectivo', description: 'Conectivo', alpha: 1, models: [7] },
    ])

    useEffect(() => {
        console.log('scene', refScene, refBodyGroup);
    }, [refScene, refBodyGroup])

    const removedPartsNames = removedParts.map(e => e.name)

    return (
        <Box ref={ref} style={{ width: '100%', height: '100%' }}>
            <FullScreen handle={handle}>
                <Box style={{ width: '100%', height: '100%', position: 'relative', backgroundColor: 'rgb(220,220,220,1)' }}>
                    <Box style={{ minHeight: height, minWidth: width, width: '100%', height: '100%' }}>
                        <Canvas style={{ height: height }} ref={refScene} onClick={(e) => console.log('select', e)} dpr={[1, 1.5]} camera={{ position: [5, 2, 2.5] }} color="red">
                            <CameraController focus={focus} zoom={200} />
                            <ambientLight intensity={0.5} />
                            <ambientLight intensity={1} color='gray' position={[0, 0, 0]} />
                            <spotLight intensity={0.3} angle={0.3} penumbra={1} position={[5, 10, 20]} shadow-bias={-0.0001} />
                            <Suspense >
                                <GroupModel3D rotate={rotate} onClick={(e) => {
                                    e.stopPropagation()
                                    const intersections = e.intersections.map(e => { return { ...(meshes.find(m => m.name === e.object.userData.name) || {}), object: e.object } });
                                    const names = removedParts.map(e => e.name);
                                    const firstVisible = intersections.filter(e => !names.includes(e.name))[0]
                                    console.log('mode', firstVisible, removedParts);
                                    let text = (intersections.filter(e => !e.mute && e.visible)[0] || {}).nombre;
                                    if (!!e.intersections[0]) {

                                        switch (mode.id) {
                                            case (1): {

                                                break;
                                            }
                                            case (2): {
                                                setFocus(e.intersections[0].point);
                                                break;
                                            }
                                            case (3): {
                                                if (!!firstVisible && mode.id === 3) {
                                                    const { object, ...part } = firstVisible || {};
                                                    if (!!part) {
                                                        setRemovedParts([part, ...removedParts])
                                                        text = 'Se remueve, ' + (intersections.find(e => e.name === firstVisible.name) || {}).nombre
                                                    } else {
                                                        text = (intersections.find(e => e.name === firstVisible.name) || {}).nombre
                                                    }
                                                }
                                                break;
                                            }
                                            default: {

                                            }
                                        }
                                        speak({
                                            text: text || 'No identificado',
                                            voice: window.speechSynthesis.getVoices().filter(e => e.lang === 'es-MX')[0] || undefined
                                        });
                                    }

                                }}>
                                    {
                                        [
                                            { id: 1, path: `/${sex.path}/${sex.path}_vascular_system.glb`, opacity: 1, color: 'red' },
                                            { id: 2, path: `/${sex.path}/${sex.path}_skeletal_system.glb`, opacity: 1, color: 'white' },
                                            { id: 3, path: `/${sex.path}/${sex.path}_organs.glb`, opacity: 1 },
                                            { id: 4, path: `/${sex.path}/${sex.path}_nervous_system.glb`, opacity: 1, color: 'yellow' },
                                            { id: 5, path: `/${sex.path}/${sex.path}_muscular_system.glb`, opacity: 0.9, color: '#ab0003' },
                                            { id: 6, path: `/${sex.path}/${sex.path}_lymphatic_system.glb`, opacity: 1, color: 'green' },
                                            { id: 7, path: `/${sex.path}/${sex.path}_connective_tissue.glb`, opacity: 1, color: 'white' },
                                            { id: 8, path: `/${sex.path}/${sex.path}_cartilage_tissue.glb`, opacity: 1, color: 'whiteSmoke' },
                                            { id: 9, path: `/${sex.path}/${sex.path}_integumentary_system.glb`, opacity: 0.3, color: 'black' },
                                        ].filter(e => systems.find(i => i.models?.includes(e.id))?.selected).map(({ id, ...item }, index) => {
                                            return (
                                                <Model3D key={id} mode={mode.id} removedParts={removedPartsNames} genre={sex.path} {...item} selector={selector} />
                                            )
                                        })
                                    }
                                </GroupModel3D>
                                <Environment files="assets/models/demo/royal_esplanade_1k.hdr" />
                                <ContactShadows
                                    rotation-x={Math.PI / 2}
                                    position={[0, -0.8, 0]}
                                    opacity={0.5}
                                    width={10}
                                    height={10}
                                    blur={2}
                                    far={2}
                                />
                            </Suspense>
                        </Canvas>
                        <Box position={'absolute'} bottom={8} right={8}>
                            <Avatar src="assets/images/iconThin-k.png" style={{ width: 80, height: 80 }} />
                        </Box>
                        <Box position={'absolute'} top={8} left={8}>
                            <img src="images/logos/logo.svg" style={{ height: 35 }} />
                        </Box>
                        <Box position={'absolute'} top={0} width='100%'>
                            <Box p={1} position='relative'>
                                <div
                                    style={{ position: 'absolute', top: 8, right: 8, width: 40 }}
                                >
                                    <List style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(0,0,0,0.6)', borderRadius: 5, boxShadow: '0px 0px 5px black', padding: 0, marginBottom: 8 }}>
                                        <ListItem key={'fs'} button selected={handle.active} onClick={handle.active ? handle.exit : handle.enter} style={{ justifyContent: 'center', height: 40 }}>
                                            <FullscreenExitOutlined />
                                        </ListItem>
                                        <Divider />
                                        {
                                            modes.map((item, index) => {
                                                const Icon = item.icon;
                                                return (
                                                    <ListItem key={index} button selected={item.id === mode.id} onClick={() => setMode(item)} style={{ justifyContent: 'center', backgroundColor: item.id === mode.id ? defaultColor : 'transparent', }}>
                                                        <Icon color={item.id === mode.id ? 'primary' : 'inherit'} />
                                                    </ListItem>
                                                )
                                            })
                                        }
                                        <Divider />
                                    </List>
                                    <List style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(0,0,0,0.6)', borderRadius: 5, boxShadow: '0px 0px 5px black', padding: 0, marginBottom: 8 }}>
                                        <ListItem key={'fs'} button selected={rotate} onClick={() => setRotate(!rotate)} style={{ justifyContent: 'center', height: 40 }}>
                                            <ThreeDRotationOutlined color={rotate ? 'primary' : 'inherit'} />
                                        </ListItem>
                                    </List>
                                    <List style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(0,0,0,0.6)', borderRadius: 5, boxShadow: '0px 0px 5px black', padding: 0, overflowX: 'scroll' }}>
                                        {
                                            systems.filter(e => e.id_sexo === 3 || (e.id_sexo === 1 && sex.path === 'male') || (e.id_sexo === 2 && sex.path === 'female')).map((item, index) => {
                                                const Mask = styled('div')`
                                                    box-sizing: border-box;
                                                    height: 30px;
                                                    width: 30px;
                                                    padding: 0px;
                                                    background-color: red;
                                                    background-size: '30px 30px';
                                                    background-position: center;
                                                    background-repeat: no-repeat;
                                                    mask-size: contain;
                                                    mask-image: url(${process.env.PUBLIC_URL + 'assets/images/' + item.src});
                                                    mask-repeat: no-repeat;
                                                    mask-position: center;
                                                    align-self: 'center';
                                                `;
                                                return (
                                                    <ListItem key={index} onClick={() => {
                                                        const systemsE = [...systems];
                                                        systemsE[index].selected = !item.selected;
                                                        setSystems(systemsE);
                                                    }} button style={{ justifyContent: 'center', height: 40, width: 40 }}>
                                                        <div style={{ height: 40, width: 40, padding: 5, opacity: item.selected ? 1 : 0.3 }}>
                                                            <Mask style={{ backgroundColor: item.selected ? 'white' : 'lightGray' }} />
                                                            <Divider />
                                                        </div>
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </FullScreen>
        </Box>
    )
}
