import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, IconButton, Paper } from '@material-ui/core';
import { Hearing, VolumeOff } from '@material-ui/icons';
import ReactECharts from 'echarts-for-react';
import { useSpeechSynthesis } from 'react-speech-kit';
import { Pagination } from '@material-ui/lab';
import { useTheme } from '@material-ui/styles';

const GraphResults = ({ modeView, searchText = '' }) => {
  const results = useSelector(state => state.studies.results || {});
  const { speak, speaking, cancel } = useSpeechSynthesis();
  const { groups = [] } = results;
  const [resultsFiltered, setResultsFiltered] = useState([]);
  const [page, setPage] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    setResultsFiltered(groups.filter((e, i) => e.nombre.toLowerCase().includes(searchText.toLowerCase())));
  }, [searchText]);

  // Function to convert the ApexCharts options to ECharts options
  const convertOptions = (item) => {
    // Determine min and max values from the data
    const dataValues = item.series[0].data;
    const minValue = Math.min(...dataValues);
    const maxValue = Math.max(...dataValues);

    return {
      title: {
        text: item.nombre,
      },
      colors: ['red', 'blue', 'green'],
      tooltip: {
        trigger: 'axis',
        // formatter: (params) => {
        //   const date = new Date(params[0].data[0]);
        //   const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        //   console.log('params', params)
        //   return `${params[0].marker} ${params[0].seriesName}: ${params[0].data[1]} (${formattedDate})`;
        // },
      },
      xAxis: {
        type: 'time',
        axisLabel: {
          formatter: (value) => {
            const date = new Date(value);
            return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          },
        },
      },
      yAxis: {
        type: 'value',
      },
      series:
        item.series.map((serie, i) => {
          return {
            name: serie.name,
            type: 'line',
            data: serie.data.map((d, i) => [item.options.xaxis.categories[i], d]),
            color: item.options.colors[i],
            smooth: true,
            lineStyle: {
              width: i === 0 ? 2 : 1,
              opacity: 1,
              shadowColor: 'black',
              shadowOffsetX: 0,
              shadowBlur: 0,
              type: 'dashed', // Makes the line dashed
            },
            itemStyle: {
              borderWidth: i === 0 ? 3 : 1,
            },
            // markLine: {
            //   data: [
            //     { type: 'average', name: 'Average' },
            //     { yAxis: minValue, name: 'Min Value' },
            //     { yAxis: maxValue, name: 'Max Value' }
            //   ],
            //   label: {
            //     formatter: '{b}: {c}',
            //     color: '#333',
            //   },
            //   lineStyle: {
            //     color: '#ff0000',
            //     type: 'dashed',
            //   },
            // },
          }
        })
      ,
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
    };
  };

  return (
    <Box display={modeView === 'grid' ? 'block' : 'none'}>
      {resultsFiltered.filter((e, i) => i < (page * 10 + 10) && i >= page * 10)
        .map((item, index) => (
          <Paper
            key={`${index}:${item.nombre}`}
            id="chart"
            style={{
              borderRadius: 10,
              boxShadow: '1px 1px 5px rgba(200,200,200,0.5)',
              marginBottom: 16,
              padding: 8,
              position: 'relative'
            }}
          >
            <Suspense fallback={<div>Loading...</div>}>
              <ReactECharts
                key={`${index}:${item.nombre}`}
                option={convertOptions(item)}
                style={{ height: 350, width: '100%' }}
              />
            </Suspense>
            <Box style={{ position: 'absolute', top: 8, right: 8, zIndex: 5 }}>
              <IconButton style={{ marginRight: 5 }} size='small' onClick={() => {
                if (!speaking) {
                  speak({
                    text: item.speech,
                    voice: window.speechSynthesis.getVoices().filter(e => e.lang === 'es-MX')[0] || undefined
                  });
                } else {
                  cancel();
                }
              }}>
                {!speaking ? <Hearing /> : <VolumeOff />}
              </IconButton>
            </Box>
          </Paper>
        ))}
      <Grid spacing={2} sx={{ width: '100%' }}>
        <Pagination
          count={Math.ceil(resultsFiltered.length / 10)}
          page={page}
          onChange={(event, newPage) => {
            setPage(newPage);
          }}
          color="primary"
        />
      </Grid>
    </Box>
  );
};

export default GraphResults;
