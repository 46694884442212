import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  AccordionActions,
  Button,
  Grid,
  IconButton,
  Dialog,
  CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import dataDemo from '../../../../components/Charts/dataDemo.json'

import internalAPI from '../../../../utils/internalAPI';
import { CloudDownloadOutlined, FileCopyOutlined, PersonalVideoOutlined } from '@material-ui/icons';
// import DicomViewer from 'components/organisms/DicomViewer';
import { PDFViewer, Document } from '@react-pdf/renderer';
import ModalUI from 'components/containers/ModalUI';
import DicomViewer from 'common/DicomViewer';
import { TransitionUp } from 'common/TransitionComponents';
import { isArray } from 'lodash';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const ListResults = ({ modeView }) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(null);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [expandedImage, setExpandedImage] = React.useState(false);
  const { texting } = useSelector(state => state.studies.results);
  const results = useSelector(state => state.studies.resultsGrouped);
  const resultsImage = useSelector(state => state.studies.resultsImage);
  // const resultsImage = [];
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeImage = (panel) => (event, isExpanded) => {
    setExpandedImage(isExpanded ? panel : false);
  };
  const handleDownloadPDF = (id_orden) => {
    // return () => {
    internalAPI.getPDF(id_orden, (error, data) => {
      if (error || !data) {
        console.log('error', error)
        window.open();
        return;
      }
      // setLoading(true);
      // setSelected(data);
      handleDownloadPDFFile(data)
      window.open(data);
    });
    // };
  };
  const handleDownloadPDFFile = (url) => {
    // return () => {
    internalAPI.getPDFFile(url, (error, data) => {
      if (error || !data) {
        // window.open();
        return;
      }
      console.log('resltados', data)
      setSelected(data);
      // window.open(data);
    });
    // };
  };
  function renderHTMLContent(content) {
    return { __html: content };
  }

  return (
    <Box display={modeView === 'list' ? 'block' : 'none'}>
      <Box display={modeView === 'list' ? 'block' : 'none'} style={{ borderRadius: 16, overflow: 'hidden', boxShadow: '0px 0px 5px gray' }}>
        {([...results] || []).sort((a, b) =>
          moment(b.fecha, 'YYYY-MM-DD').toDate() - moment(a.fecha, 'YYYY-MM-DD').toDate()
        ).map((item, index) => (
          <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)} >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${index}bh-content`}
              id={`${index}bh-header`}
            >
              <Grid container direction='row'>
                {/* <Grid style={{ flexDirection: 'column', alignSelf: 'center', padding: 5 }} >
                <IconButton onClick={(e) => {
                  e.stopPropagation();
                  getNote(item)
                }}>
                  <FileCopyOutlined />
                </IconButton>
              </Grid> */}
                <Grid style={{ flexDirection: 'column', alignSelf: 'center', padding: 5 }} >
                  <Button color='primary' onClick={(e) => {
                    e.stopPropagation();
                    console.log('orden', item)
                    handleDownloadPDF(item.id_orden)
                  }}>
                    {/* <CloudDownloadOutlined /> */}
                    {'Ver'}
                  </Button>
                </Grid>
                <Grid container item xs={6} alignContent='flex-start' style={{ flexDirection: 'column', alignSelf: 'center' }} >
                  <Typography variant='caption' align='left' style={{ marginRight: 8, fontWeight: 600 }}>
                    {/* <IconButton onClick={handleDownloadPDF(item.folio)}>
                  <CloudDownloadOutlined />
                </IconButton> */}
                    Fecha: {item.fecha}
                  </Typography>
                </Grid>
                <Grid style={{ flexDirection: 'column', alignSelf: 'center' }} >
                  <Typography variant='caption' style={{ width: '100%', textAlign: 'right' }} >
                    Folio: {item.folio}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <List style={{ width: '100%' }}>
                {item.resultados.map((result, index) => (
                  <ListItem key={index} divider={!result.unidad}>
                    <Grid container direction={result.clasificacion === 'Parrafos' ? 'column' : 'row'}>
                      <Grid xs>
                        <Typography variant='caption' align='left' style={{ fontWeight: 600 }}>
                          {result.titulo}
                        </Typography>
                      </Grid>
                      {/* <Grid xs={3}>
                    </Grid> */}
                      <Grid xs={result.clasificacion === 'Parrafos' ? 12 : 3}>
                        {
                          // `${result.resultado} ${result.unidad}`.includes('</') ?
                          //   <div dangerouslySetInnerHTML={renderHTMLContent(`${result.resultado} ${result.unidad}`)}  >
                          //     {`${result.resultado} ${result.unidad}`}
                          //   </div> :
                          <Typography variant='caption' align='right' style={{ textJustify: 'flex-start' }}>
                            {`${result.resultado} ${result.unidad}`.replaceAll('<b>', '').replaceAll('</b>', '').replace(/<\/?[^>]+(>|$)/g, "")}
                          </Typography>
                        }

                      </Grid>
                    </Grid>
                    {/* <ListItemText
                    primary={result.titulo}
                    secondary={`${result.resultado} ${result.unidad}`}
                  /> */}
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}


      </Box>
      {!!(resultsImage || []).length && <Box p={2}>
        <Typography color='primary' style={{ fontWeight: '700' }}>
          {'Estudios de imagen'}
        </Typography>
      </Box>}
      <Box display={modeView === 'list' ? 'block' : 'none'} style={{ borderRadius: 16, overflow: 'hidden', boxShadow: '0px 0px 5px gray' }}>
        {(resultsImage || []).map((item, index) => (
          <Accordion key={index} expanded={expandedImage === index} onChange={handleChangeImage(index)} >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${index}bh-content`}
              id={`${index}bh-header`}
            >
              <Grid container direction='row'>
                <Grid style={{ flexDirection: 'column', alignSelf: 'center', padding: 5 }} >
                  <IconButton onClick={(e) => {
                    e.stopPropagation();
                    console.log('orden', item)
                    if (!!(item.resultados[0]))
                      handleDownloadPDF(item.resultados[0].id_orden)
                  }}>
                    <CloudDownloadOutlined />
                  </IconButton>
                </Grid>
                <Grid container item xs={6} alignContent='flex-start' style={{ flexDirection: 'column', alignSelf: 'center' }} >
                  <Typography variant='caption' align='left' style={{ marginRight: 8, fontWeight: 600 }}>
                    Estudio: {item.estudio}
                  </Typography>
                </Grid>
                <Grid style={{ flexDirection: 'column', alignSelf: 'center' }} >
                  <Typography variant='caption' style={{ width: '100%', textAlign: 'right' }} >
                    Resultados: {(item.resultados || []).length}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <List style={{ width: '100%' }}>
                {item.resultados.filter(e => e.rutas_dcm?.length).map((result, index) => (
                  <ListItem key={index} divider={!result.unidad}>
                    <Grid container direction='row'>
                      <Grid xs>
                        <Typography variant='caption' align='left' style={{ fontWeight: 600 }}>
                          {item.estudio}
                        </Typography>
                      </Grid>
                      {/* <Grid xs={3}>
                    </Grid> */}
                      <Grid xs={3}>
                        <Typography variant='caption' align='right' >
                          {`${result.fecha}`}
                        </Typography>
                      </Grid>
                      <Grid xs={'auto'} direction='column' position='relative'>
                        <IconButton style={{ display: 'flex', position: 'absolute', top: -8, right: -10 }} onClick={(e) => {
                          e.stopPropagation();
                          console.log('orden', item)
                          console.log('results', result);
                          setSelectedImage(result)
                        }}>
                          <PersonalVideoOutlined />
                        </IconButton>
                      </Grid>
                    </Grid>
                    {/* <ListItemText
                    primary={result.titulo}
                    secondary={`${result.resultado} ${result.unidad}`}
                  /> */}
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      <ModalUI
        open={!!selected}
        onClose={() => setSelected(null)}
        fullScreen
      >
        {/* {
          selected &&
          <iframe
            src={selected}
            id={'pdfViewer'}
            onLoad={() => setLoading(false)}
            onAbort={() => console.log('salio del load')}
            // name={gpcSelected.vc_nombre}
            style={{
              width: '100%',
              height: '100%',
              padding: 0,
              margin: 0,
              borderRadius: 5,
            }}

          />
          // <PDFViewer>
          //   <Document
          //     file={selected}
          //     onLoadSuccess={() => {}}/>
          //   </PDFViewer>
        } */}
        {
          loading &&
          <Box
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ backgroundColor: 'white' }}
          >
            <CircularProgress color={'primary'} />
            <Box>
              <Typography color={'primary'} style={{ marginTop: 8 }}>
                {'Cargando resultados'}
              </Typography>
              <Button fullWidth onClick={() => { setSelected(null); setLoading(false) }}>
                {'Cancelar'}
              </Button>
            </Box>
          </Box>
        }
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{ backgroundColor: 'white' }}
        />
      </ModalUI>
      <Dialog
        fullScreen
        open={!!selectedImage}
        TransitionComponent={TransitionUp}
      >
        {<DicomViewer urls={selectedImage?.rutas_dcm || []} setHiddeBottomTool={() => setSelectedImage(null)} />}
      </Dialog>
    </Box>
  );
};

export default ListResults;
