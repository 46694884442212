/* eslint-disable no-undef */
import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="root" />);
console.log('window.location.href', window.location.href);
if (!window.location.href.includes("https://dblaboratorios.com") && !window.location.href.includes("http://localhost")) {
    // La URL no contiene "https://dblaboratorios.com", redirigir a la misma ruta con ese dominio.
    const currentPath = window.location.pathname + window.location.search + window.location.hash;
    const newURL = `https://dblaboratorios.com${currentPath}`;
    window.location.href = newURL;
}

console.log('amplify.store(token)', amplify.store('token'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// window.location = 'http://187.216.113.211/resultados/web/index.php?r=site/login';
