import * as React from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, ListItem, Tooltip, Typography } from '@material-ui/core';
import IconMask from '../IconMask';
import Lottie from 'react-lottie-player';
import { useSpeechRecognition, useSpeechSynthesis } from 'react-speech-kit';
import voiceJson from './voice.json';
import { TransitionUp } from '../TransitionComponents';
export default function IAButton({ commands = [] }) {

  const [voice, setVoice] = React.useState("");
  const [showInfo, setShowInfo] = React.useState(false);
  const { speak, speaking, cancel } = useSpeechSynthesis();
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (res) => {
      const result = res.toLowerCase().replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u')
      setVoice(result + " ");
      console.log('res', res);
    },
    onEnd: (result) => {
      console.log(result);
    },
  });
  React.useEffect(() => {
    let command = commands.find(e => {
      return !!e.command.find((c) => voice.includes(`${c}`.toLowerCase()))
    })
    if (!!command) {
      command.action && command.action();
      console.log('command entro', command);
      command.response && speak({ text: command.response[0] });
      switch (true) {
        case (command.actionBase === 'off'): {
          console.log('stop');
          stop();
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [voice])
  React.useEffect(() => {
    return () => {
      stop();
    }
  }, [])

  console.log('listening', listening);
  const iconSize = 30

  return (
    [<Tooltip key={'ia-voice'} title={'Voz'} >
      <Button
        size="small"
        color='primary'
        onClick={() => {
          !listening && speak({ text: 'Puede darme instrucciones, estare escuchando...' });
          listening ? stop() : listen();
        }}
        style={{ margin: 8, width: 120, height: 30, flexWrap: "nowrap" }}
      >
        {
          !listening ?
            <IconMask url={`/icons/mic.svg`} color={'gray'} size={iconSize + 1} style={{ position: 'absolute' }} />
            :
            <Lottie
              loop
              animationData={voiceJson}
              play
              color="red"
              style={{ position: 'absolute', width: 100, height: 50, color: 'red' }}
            />}
      </Button>
    </Tooltip>
      ,
    // listening &&
    <Tooltip key={'ia'} title={'Voz'}>
      <Button
        size="small"
        color='primary'
        onClick={() => {
          setShowInfo(true);
        }}
        style={{ margin: '8px 0px', height: 30, flexWrap: "nowrap", maxHeight:'80%' }}
      >
        <IconMask url={`/icons/list.svg`} color={'gray'} size={iconSize -6} style={{ position: 'absolute' }} />
      </Button>
    </Tooltip>
      ,
    <Dialog
      open={showInfo}
      onClose={() => setShowInfo(false)}
      TransitionComponent={TransitionUp}
    >

      <DialogTitle >
        {/* <Lottie
          loop
          animationData={voiceJson}
          play
          color="red"
          style={{ width: '100%', color: 'red', maxWidth: 300 }}
        /> */}
        <Box key={'Title'} width={'100%'} style={{ justifyContent: 'center', display: 'flex' }}>
          <IconMask url={`/icons/ia.svg`} color={'gray'} size={80} style={{ display: 'flex' }} />
        </Box>
        <Box key={'SubTitle'} width={'100%'} style={{ justifyContent: 'center', display: 'flex' }}>
          <Typography>
            {'Comandos de voz'}:
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {commands.map((command, index) => {
          return (
            <ListItem
              key={`${index}${command.id}`}
              style={{ backgroundColor: 'rgba(200,200,200,0.2)', marginBottom: 8, borderRadius: 10 }}
            >
              <Box width={'100%'}>
                <Typography>
                  {command.name}:
                </Typography>
              </Box>
              <Divider orientation='vertical' />
              <Box width={'100%'}>
                {
                  command.command.map((item, i) => {
                    return (
                      <Button
                        fullWidth
                        key={i}
                        onClick={() => {
                          setShowInfo(false);
                          command.action && command.action()
                          speak({ text: command.response[0] });
                        }}
                      >
                        {item}
                      </Button>
                    )
                  })
                }
              </Box>

            </ListItem>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setShowInfo(false)}
        >
          {'Aceptar'}
        </Button>
      </DialogActions>
    </Dialog>
    ]
  );
}

