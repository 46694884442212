import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DialogActions, Grid, List, ListItem, Typography } from '@material-ui/core';

import { Image } from 'components/atoms';
import { ModalUI } from 'components/containers';
import Avisos from 'components/Avisos';
import Scene3D from 'components/Scene3D';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 0),
    },
    boxShadow: '0px -3px 3px rgba(100,100,100, 0.5)',
    background: theme.palette.primary.main,
  },
  footerContainer: {
    margin: '0 20px',
    padding: theme.spacing(0, 0),
  },
  logoContainerItem: {
    paddingTop: 0,
  },
  logoContainer: {
    width: 320,
    height: 72,
  },
  logoImage: {
    width: 600,
    maxWidth: '80%',
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
    '&:hover': {
      background: 'transparent',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 24,
  },
}));

const Footer = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();
  const [displayAviso, setdisplayAviso] = useState(false)
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.footerContainer}>
        <List disablePadding>
          <ListItem disableGutters className={classes.logoContainerItem}>
            <Grid container>
              {/* <Image
                className={classes.logoImage}
                src="/images/illustrations/Footer_image.png"
                alt="db"
                lazy={false}
              /> */}
              <Grid item xl xs={12}>
                <Typography style={{ fontWeight: 800, padding: 8, fontSize: 10, color: 'white' }}>
                  {'Consulte a su médico'}
                </Typography>
              </Grid>
              <Grid item xl={'auto'} xs={12}>
                <Typography style={{ fontWeight: 800, padding: 8, fontSize: 10, color: 'white' }}>
                  {'CLAVE DE AUTORIZACIÓN COFEPRIS 223301201A0117'}
                </Typography>
              </Grid>
              <Grid item xl={'auto'} xs={12}>
                <a
                  onClick={(e) => {
                    window.open("/pdf/aviso_privacidad.pdf");
                    // setdisplayAviso(true)
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  style={{ color: 'white' }}
                >
                  Aviso de privacidad
                </a>
              </Grid>
              <Grid item xl={'auto'} xs={12}>
                <a
                  style={{ color: 'white' }}
                >
                  Contacto {' '}
                  info@corp.analisisdb.com
                </a>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </div>
      <ModalUI
        open={displayAviso}
        onClose={() => setdisplayAviso(false)}
      >
        {/* <Scene3D/> */}
        {/* <Avisos /> */}
        <DialogActions>
          <Button onClick={() => setdisplayAviso(false)}>
            {'Salir'}
          </Button>
        </DialogActions>
      </ModalUI>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
};

export default Footer;
