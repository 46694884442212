import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Section, SearchStudiesResultsBar, Login } from 'components/organisms';
import {
  GraphResults,
  ListResults
} from './components';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import store from 'redux/store';
import DicomViewer from 'components/organisms/DicomViewer';
import { Box, Grid, IconButton, ListItem, Paper, Typography } from '@material-ui/core';
import { Forms } from 'components/molecules';
import ListPatients from './components/ListPatients';
import { ChevronRightOutlined, CloseOutlined, PersonOutlined } from '@material-ui/icons';
import { setPatient } from 'redux/studies';
import PatientSelected from './components/PatientSelected';
// import Female3D from 'components/Female3D';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    minHeight: 'calc(100vh)',
    width: '100%',
    alignContent: 'center',
    flexDirection: 'rrow',
    paddingTop: 24,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    padding: 8,
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
  pagePaddingTop: {
    padding: 0,
    width: '100%',
    maxWidth: '100%',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },

  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  shape: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
    },
  },
}));

const Viewer = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  // const modeView = useSelector(state => state.studies.view);
  const [searchText, setSearchText] = useState('')
  const globalUser = useSelector(state => state.session.globalUser);
  const token = useSelector(state => state.session.token);
  const patient = useSelector(state => state.studies.patient);
  const results = useSelector(state => state.studies.results);
  const [modeView, setModeView] = useState('list');
  let Content = <div />;
  switch (true) {
    case (!!patient): {
      Content = <PatientSelected modeView={modeView} searchText={searchText} setModeView={setModeView} />
      break;
    }
    default: {
      Content = <ListPatients modeView={modeView} searchText={searchText} type={globalUser.tipo || 'paciente'} />
      break;
    }
  }

  return (
    <div className={classes.root}>
      <ResponsiveLayout>
        <div className={classes.container}>
          <div className={classes.shape}>
            <SearchStudiesResultsBar modeView={modeView} setSearchText={(e) => setSearchText(e)} setModeView={setModeView} setDates={() => {

            }} />
          </div>
          <div className={classes.shape}>
            {
              !!globalUser &&
              <Section className={classes.pagePaddingTop}>
                {Content}
              </Section>
            }
          </div>
        </div>
        {/* {
          <Login open={!token} />
        } */}
        <Box p={2}>
          <Typography style={{ fontWeight: '700' }}>
            {/* {'TAC Cráneo Simple'} */}
          </Typography>
        </Box>
        {/* <Box m={'16px 0px'} height={'80vh'} style={{borderRadius: 16, boxShadow: '0px 0px 10px gray', overflow: 'hidden', }}>
          <Female3D/>
        </Box> */}
        {/* <Forms/> */}
      </ResponsiveLayout>

    </div>
  );
};

export default Viewer;
