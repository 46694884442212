import React from 'react';

import { Box, Dialog, IconButton, Slide } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const ModalUI = (props) => {

    return (
        <Dialog
            TransitionComponent={Transition}
            {...props}
        >
            {props.children}
            <IconButton onClick={props.onClose || undefined} style={{position: 'absolute', top: props.fullScreen ? 16 : -10, right: props.fullScreen ? 16 : -10}}>
                <CloseOutlined/>
            </IconButton>
        </Dialog>
    );
};

export default ModalUI;
