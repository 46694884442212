import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, ListItem, Paper, Typography, Divider, Button, InputBase, Popover, ButtonGroup, List, debounce, CircularProgress, Dialog, Slide, DialogTitle, DialogActions } from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import useMeasure from 'react-use-measure'
import { Carousel } from 'react-responsive-carousel';
import internalAPI from '../../internalAPI';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import areas from '../../constants/areas.json';
import types from '../../constants/types.json';
import { ArrowForward, WhatsApp } from '@material-ui/icons';
import { Modal } from '@material-ui/core';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import Delivery from 'components/Delivery';
import ReactPlayer from 'react-player';
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignContent: 'flex-start',
    },
    containerData: {
        animation: `$myEffect 500ms ${theme.transitions.easing.sharp}`,
    },
    "@keyframes myEffect": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    },
    container: {
        width: '100%',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        '& svg': {
            margin: theme.spacing(1.5),
        },
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
    },
    selector: {
        display: 'flex',
        flexWrap: 'wrap',
        height: 60,
        width: '100%',
        borderRadius: 16,
        backgroundColor: 'white',
        justifyContent: 'space-around',
        alignContent: 'flex-start',
        marginBottom: 60,

    },
    floatContainer: {
        position: 'relative',
        height: 'auto',
        padding: 16,
        width: '100%',
        zIndex: 50,
        // [theme.breakpoints.up('md')]: {
        //     marginBottom: 16,
        //     paddingTop: 30,
        //     position: 'absolute',
        //     top: 0,
        //     left: 0,
        //     width: '50%',
        //     height: '100%',
        // },
    },
    "@keyframes myEffect": {
        "0%": {
            opacity: 0.5,
        },
        "100%": {
            opacity: 1,
        }
    },
    floatBox: {
        backgroundColor: 'rgba(255,255,255,1)',
        borderRadius: 16,
        border: `solid 0.5px rgba(255,255,255,0.2)`,
        width: '100%',
        marginBottom: 24,
        maxWidth: 400,
        boxShadow: '0px 4px 24px rgba(100,100,100,1)',
        [theme.breakpoints.up('md')]: {
            width: 400,
        },
    },
    text: {
        marginBottom: 32,
        fontSize: 18,
        fontWeight: 800,
        minHeight: 'calc(100% - 100px)',
        color: 'gray',
        [theme.breakpoints.up('md')]: {
            fontSize: 18,
        },
    },
    formContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
        maxWidth: 500,
        margin: `0 auto`,
    },
    box: {
        display: 'flex',
        height: '70vh',
        backgroundColor: 'red'
    },
    image: {
        width: '100%',
        maxWidth: 500,
        float: 'center',
        boxShadow: '1px 1px 5px gray',
        borderRadius: 10,

    },
    iconButton: {
        position: 'fixed',
        top: 8,
        right: 8,
        color: 'rgba(200,200,200,0.8)',
        backgroundColor: theme.palette.primary.main,
        opacity: 0.9
    },
    section: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    label: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    containerTag: {
        flexDirection: 'column',
        borderRadius: 0,
        boxShadow: '0px 0px 10px gray',
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            borderRadius: 16,
            margin: '60px 0px 0px 0px',
        },
    },
    tagLeft: {
        backgroundColor: 'rgba(255,255,255,1)',
        padding: 30,
        [theme.breakpoints.up('md')]: {
            padding: 40,
        },
    },
    tagRight: {
    },
    input: {
        backgroundColor: 'rgba(255,255,255,0.8)',
        borderRadius: 16,
        width: '100%',
        minWidth: 200,
        marginTop: 16,
        marginBottom: 0,
        maxHeight: 300,
        boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
        overflowY: 'scroll',
        paddingLeft: 16,
        paddingRight: 16,
        minHeight: 50,
        transitionDuration: 500,
        border: `solid 1px ${theme.palette.primary.main}`,
        "&:focus,&:hover": {
            backgroundColor: 'rgba(255,255,255,1)',
            border: `solid 1px ${theme.palette.primary.main}`,
            boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    inputBase: {
        flex: 1,
        height: '100%',
        padding: '5px 16px',
        boxShadow: 'none',
        "&:focus,&:hover": {
            backgroundColor: 'inherit',
            border: 0,
            boxShadow: 'none',
        }
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EmpresasView = (props) => {
    const selectType = types.find(e => e.id === props.view) || types[0];
    const classes = useStyles();
    const [screen, { height, width }] = useMeasure();
    const [anchor, { ah, aw }] = useMeasure();
    const [loading, setLoading] = useState(true);
    const [select, setSelect] = useState(null);
    const [openDialog, setOpen] = useState(false);
    const [area, setArea] = useState(null);
    const [type, setType] = useState(selectType);
    const [searchText, setSearchText] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElType, setAnchorElType] = useState(null);
    const [data, setData] = useState([]);

    const update = async (d, e) => {
        if (d && d.length) {
            setData(d)
        } else {
            setData([])
        }
    }
    const searchStudios = debounce((searchText) => {
        setLoading(true);
        switch (type.id) {
            case ('estudios'): {
                internalAPI.catalogos.getEstudios({ id_area: area ? area.id : undefined, searchText: searchText.length ? searchText : undefined }, update)
                break;
            }
            case ('paquetes'): {
                internalAPI.catalogos.getPaquetes({ id_area: area ? area.id : undefined, searchText: searchText.length ? searchText : undefined }, update)
                break;
            }
            default: {
                internalAPI.catalogos.getEstudios({ id_area: area ? area.id : undefined, searchText: searchText.length ? searchText : undefined }, update)
            }
        }
    }, 500)

    useEffect(() => {
        searchStudios(`${searchText || props.search || ''}`);
        setAnchorEl(null);
        setAnchorElType(null);
    }, [area, type, searchText])

    useEffect(() => {
        setLoading(false);
    }, [data])

    useEffect(() => {
        if (!!select) {
            setOpen(true)
        }
    }, [select])

    const isMobile = width < 600;
    console.log('ancho', anchorElType)
    const contactAction = () => {
        const text = '¡Hola me interesa mas información sobre los planes empresariales para mi empresa!'
        window.open(`https://api.whatsapp.com/send?phone=526674534062&text=${text}`);
    }
    return (
        <Box ref={screen} style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignContent: 'center' }}>
            <ResponsiveLayout >
                <Box className={classes.root}>
                    <Box onClick={() => setOpen(true)} style={{ paddingBottom: -50, width: width, position: 'relative' }}>
                        <Grid container justifyContent='center' className={classes.floatContainer}>
                            <Grid item >
                                <Box p={0} className={classes.floatBo} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <InputBase
                                        autoFocus endAdornment={<WhatsApp color='primary' />} placeholder='Contáctanos...' className={classes.input} onChange={contactAction} onClick={contactAction} />
                                </Box>
                            </Grid>
                        </Grid>
                        <ReactPlayer
                            key={'video'}
                            width={width}
                            url={'videos/EMPRESAS.mp4'}
                            height={'auto'}
                            playing={true}
                            controls={false}
                            loop
                        />
                    </Box>
                </Box>

                {/* <Box p={2}>
                    <Typography className={classes.text} style={{ textAlign: 'center', fontSize: 22 }}>
                        {'Algunos de nuestros clientes'}
                    </Typography>
                    <img src='/images/empresas/partners.png' width={'100%'} alt='' />
                </Box> */}
                <Dialog
                    open={false}
                    onClose={() => setOpen(false)}
                    onTransitionEnd={() => {
                        if (openDialog && !select)
                            setSelect(null);
                    }}
                    TransitionComponent={Transition}
                    transitionDuration={300}
                    PaperProps={{ style: { boxShadow: '0px 0px 20px rgba(100,100,100,0.5)', borderRadius: 30, position: 'relative', width: '100%', height: 'auto', padding: 0 } }}
                >
                    {
                        !!select &&
                        <Paper
                            key={0}
                            style={{
                                backgroundImage: 'linear-gradient(rgba(208,66,142, 0.1), rgba(77,172,170, 0.1))',
                                boxShadow: '0px 0px 20px rgba(100,100,100,0.5)', borderRadius: 30, position: 'relative', width: '100%', height: 'auto', padding: 16
                            }}>
                            <Box p={1}>
                                <Typography
                                    key={0}
                                    variant='subtitle1'
                                    style={{
                                        width: 'calc(100% - 100px)',
                                        color: 'gray',
                                        fontWeight: 400,
                                        fontSize: 12,
                                        borderRadius: '0px 0px 0px 0px'
                                    }}
                                >
                                    {select.tipo || ''}
                                </Typography>
                                <Typography
                                    key={1}
                                    style={{
                                        height: 35,
                                        width: 'calc(100% - 100px)',
                                        textJustify: 'initial',
                                        fontWeight: 800,
                                        fontSize: 12,
                                        // background: 'linear-gradient(to bottom, rgba(100,100,100,0.8), rgba(100,100,100,0))',
                                    }}
                                >
                                    {`${select.nombre}`.replaceAll('PAQUETE ', '').split('[')[0].split('(')[0]}
                                </Typography>
                            </Box>
                            <Divider />
                            {
                                type.id === 'estudios' ?
                                    <Box p={1}>
                                        <Typography
                                            key={1}
                                            style={{
                                                height: 35,
                                                width: 'calc(100%)',
                                                textJustify: 'initial',
                                                fontWeight: 800,
                                                fontSize: 12,
                                                // background: 'linear-gradient(to bottom, rgba(100,100,100,0.8), rgba(100,100,100,0))',
                                            }}
                                        >
                                            {'Indicaciones:'}
                                        </Typography>
                                        <Typography
                                            key={0}
                                            variant='subtitle1'
                                            style={{
                                                width: 'calc(100%)',
                                                color: 'gray',
                                                fontWeight: 400,
                                                fontSize: 12,
                                                borderRadius: '0px 0px 0px 0px',
                                                textAlign: 'justify'
                                            }}
                                        >
                                            {select.indicacion_paciente || ''}
                                        </Typography>
                                        <Typography
                                            key={1}
                                            style={{
                                                height: 35,
                                                width: 'calc(100%)',
                                                textJustify: 'initial',
                                                fontWeight: 800,
                                                fontSize: 12,
                                                // background: 'linear-gradient(to bottom, rgba(100,100,100,0.8), rgba(100,100,100,0))',
                                            }}
                                        >
                                            {'Toma de muestra:'}
                                        </Typography>
                                        <Typography
                                            key={0}
                                            variant='subtitle1'
                                            style={{
                                                width: 'calc(100%)',
                                                color: 'gray',
                                                fontWeight: 400,
                                                fontSize: 12,
                                                borderRadius: '0px 0px 0px 0px',
                                                textAlign: 'justify'
                                            }}
                                        >
                                            {select.indicacion_toma_de_muestra || ''}
                                        </Typography>
                                    </Box> :
                                    <Box p={1}>
                                        <Typography
                                            key={1}
                                            style={{
                                                width: 'calc(100%)',
                                                textJustify: 'initial',
                                                fontWeight: 800,
                                                fontSize: 12,
                                                // background: 'linear-gradient(to bottom, rgba(100,100,100,0.8), rgba(100,100,100,0))',
                                            }}
                                        >
                                            {'Incluye:'}
                                        </Typography>
                                        {
                                            (select.detalle || []).map((e, index) => {
                                                return (
                                                    <Typography
                                                        key={index}
                                                        variant='subtitle1'
                                                        style={{
                                                            width: 'calc(100%)',
                                                            color: 'gray',
                                                            fontWeight: 400,
                                                            fontSize: 12,
                                                            borderRadius: '0px 0px 0px 0px',
                                                            textAlign: 'justify',
                                                            margin: 5
                                                            // background: 'linear-gradient(to bottom, rgba(100,100,100,0.8), rgba(100,100,100,0))',
                                                        }}
                                                    >
                                                        {`${e.alterna} - ${e.estudio}`}
                                                    </Typography>
                                                )
                                            })
                                        }
                                    </Box>
                            }
                        </Paper>
                    }
                </Dialog>

            </ResponsiveLayout>
        </Box>
    );
};

export default EmpresasView;
