import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContentText, DialogContent, Select, FormControl, InputLabel, MenuItem, ListSubheader } from '@material-ui/core';
import sucursalesBase from '../../constants/sucursales.json';
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    minHeight: '100vh',
    marginTop: 55,
    // backgroundImage: 'url(images/background50.png)',
    // backgroundSize: '40vh 100vh',
    // backgroundRepeat: 'repeat',
    backgroundImage: 'linear-gradient(rgba(208,66,142, 0.2), rgba(77,172,170, 0.2))',
    backgroundSize: '100vw 100%',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    backgroundColor: 'rgba(200,200,200,0.1)',
    [theme.breakpoints.up('sm')]: {
      marginTop: 65,
    },
  },
  LocationComponent: {
    width: '100%',
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '100vw',
    }
  },
  floating: {
    position: 'absolute',
    right: 16,
    bottom: 16,
  },
  social: {
    position: 'absolute',
    left: 16,
    bottom: 16,
    height: 80,
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.LocationComponent,
    '&:hover': {
      background: 'transparent',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 24,
  },
}));

const LocationComponent = props => {
  const classes = useStyles();

  const [sucursales, setSucursales] = useState([]);
  const [location, setLocation] = useState(null);
  useEffect(() => {
    const locations = {};

    sucursalesBase.map((item) => {
      locations[item.state] = {
        name: item.state
      }
    })
    setSucursales(Object.values(locations).map(s => {
      return {
        ...s,
        cities: sucursalesBase.filter(e => e.state === s.state)
      }
    }));
    sucursalesBase.map((item) => {
      locations[item.state] = {
        name: item.state
      }
    })
  }, [])
  return (
    <Dialog
      // open={!location?.city}
    >
      <DialogTitle>
        {'!Bienvenido de dónde nos visitas!'}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {'Para ofrecerte un mejor servicio selecciona tu localidad'}
        </DialogContentText>
        <FormControl >
          <Select
            fullWidth
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={location?.city}
            onChange={(e) => setLocation({ ...location, state: e.value })}
            
          >
            <MenuItem value="">
              <em>Sin Selección</em>
            </MenuItem>

            {
              sucursales.map((item, index) => {
                return (
                  <MenuItem button onClick={() => setLocation({ state: item.name })} key={index} value={item.name}>{item.name}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};

LocationComponent.propTypes = {
  children: PropTypes.node,
};

export default LocationComponent;
