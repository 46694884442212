import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useMediaQuery, Grid, Typography, Button } from '@material-ui/core';
import { Icon, LearnMoreLink } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardPricingStandard } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeight900: {
    fontWeight: 900,
  },
}));

const Pricings = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title="Precios o promociones"
        subtitle="Eu enim anim culpa reprehenderit."
        ctaGroup={[
          <LearnMoreLink title="Obtén más detalle" href="#" variant="h6" />,
        ]}
        data-aos="fade-up"
      />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <CardPricingStandard
            variant="outlined"
            withShadow
            liftUp
            title="Check up 21"
            subtitle="Sit incididunt in ad ex est Lorem exercitation laboris minim sit sit irure est mollit."
            priceComponent={
              <div>
                <Typography
                  variant="h3"
                  component="span"
                  className={classes.fontWeight900}
                >
                  $590
                </Typography>
              </div>
            }
            features={[
              'Anemia',
              'Leucemia',
              'Diabetes',
              'Enfermedad de la gota',
              'Aumento de grasas (corazón y cerebro)',
              'Entre otras'
            ]}
            featureCheckComponent={
              <Icon
                fontIconClass="far fa-check-circle"
                fontIconColor={theme.palette.secondary.main}
              />
            }
            cta={
              <Button
                color="primary"
                variant="contained"
                fullWidth
                size="large"
              >
                Agenda ahora
              </Button>
            }
         /*    disclaimer="Fully featured 30-day free trial" */
          />
        </Grid>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <CardPricingStandard
            variant="outlined"
            title="SMAC 31"
            liftUp
            subtitle="Aliquip pariatur mollit quis mollit mollit consectetur proident elit culpa ullamco enim."
            priceComponent={
              <div>
                <Typography
                  variant="h3"
                  component="span"
                  className={classes.fontWeight900}
                >
                  $725
                </Typography>
              </div>
            }
            features={[
              'Anemia ferropénica',
              'Diabetes',
              'Enfermedad de la gota',
              'Aumento de grasas (corazón y cerebro)',
              'Desequilibrio electrolítico',
              'Osteoporosis'
            ]}
            featureCheckComponent={
              <Icon
                fontIconClass="far fa-check-circle"
                fontIconColor={theme.palette.secondary.main}
              />
            }
            cta={
              <Button color="primary" variant="outlined" fullWidth size="large">
                Agenda ahora
              </Button>
            }
/*             disclaimer="Fully featured 30-day free trial" */
          />
        </Grid>
      </Grid>
    </div>
  );
};

Pricings.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Pricings;
