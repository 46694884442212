import React, { useEffect, useRef, useState } from "react";
import dicomParser from "dicom-parser";
import * as cornerstone from "cornerstone-core";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import cornerstoneMath from "cornerstone-math";
import cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import CornerstoneViewport from "react-cornerstone-viewport";
import { Box, Button, IconButton, Tooltip, Typography } from "@material-ui/core";
import { CreateOutlined, InvertColorsOutlined, PanToolOutlined, QuestionAnswerOutlined, ShortTextOutlined, TonalityOutlined, ZoomInOutlined } from "@material-ui/icons";
import { useSelector } from "react-redux";

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

cornerstoneTools.init({
    globalToolSyncEnabled: true
});
// Preferences
const fontFamily =
    "Work Sans, Roboto, OpenSans, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif";
cornerstoneTools.textStyle.setFont(`16px ${fontFamily}`);
cornerstoneTools.toolStyle.setToolWidth(2);
cornerstoneTools.toolColors.setToolColor("rgba(0, 255, 0, 0)");
cornerstoneTools.toolColors.setActiveColor("rgb(0, 255, 0)");

cornerstoneTools.store.state.touchProximity = 40;
const OverlayTool = cornerstoneTools.OverlayTool;
cornerstoneTools.addTool(OverlayTool);
cornerstoneTools.setToolEnabled("Overlay", {});

// Image Loader
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
const controls = [
    { id: 'ArrowAnnotate', name: 'Anotación', icon: CreateOutlined },
    { id: 'EllipticalRoi', name: 'Elíptica', icon: null },
    { id: 'TextMarker', name: 'Texto', icon: null },
    { id: 'Wwwc', name: 'Niveles', icon: TonalityOutlined },
    { id: 'Zoom', name: 'Zoom', icon: ZoomInOutlined },
    { id: 'Pan', name: 'Mover', icon: PanToolOutlined },
    { id: 'Length', name: 'Longitud', icon: null },
    { id: 'Angle', name: 'Angulo', icon: null },
    { id: 'Bidirectional', name: 'Bidirecciónal', icon: null },
    { id: 'FreehandRoi', name: 'FreehandRoi', icon: null },
    { id: 'Eraser', name: 'Resetear', icon: null },
    { id: 'StackScrollMouseWheel', name: 'StackScrollMouseWheel', icon: null },
    { id: 'PanMultiTouch', name: 'PanMultiTouch', icon: null },
    { id: 'ZoomTouchPinch', name: 'ZoomTouchPinch', icon: null },
    { id: 'StackScrollMultiTouch', name: 'StackScrollMultiTouch', icon: null },
    { id: 'ScaleOverlay', name: 'Scalas', icon: null },
    { id: 'WwwcRegion', name: 'Niveles Region', icon: null },
    { id: 'Magnify', name: 'Lupa', icon: null }
];

const DicomViewer = () => {
    const ref = useRef()

    const [state, setState] = useState({
        activeViewportIndex: 0,
        viewports: [0],
        tools: controls.map(e => {
            return {
                ...e,
                name: e.id,
                mode: 'active'
            }
        }),
        imageIds: [
            // "dicomweb://localhost:3000/demoFiles/1170744.dcm",
            // "dicomweb://localhost:3000/demoFiles/1170745.dcm",
            // "dicomweb://localhost:3000/demoFiles/1170746.dcm",
            "dicomweb://187.216.113.211/dbsystem/web/index.php?r=resultadoscapturaimagen/descargar&id=MzAwMTY=",
            // "dicomweb://localhost:3000/demoFiles/1170754.dcm",
            // "dicomweb://localhost:3000/demoFiles/1170755.dcm",
            // "dicomweb://localhost:3000/demoFiles/1170756.dcm",
            // "wadouri:https://raw.githubusercontent.com/cornerstonejs/cornerstoneWADOImageLoader/master/testImages/CT2_J2KR",
            // "wadouri:https://server.dcmjs.org/dcm4chee-arc/aets/DCM4CHEE/rs/studies/1.3.6.1.4.1.25403.345050719074.3824.20170125113417.1/series/1.3.6.1.4.1.25403.345050719074.3824.20170125113545.4/instances/1.3.6.1.4.1.25403.345050719074.3824.20170125113608.3/frames/1"
        ],
        // FORM
        activeTool: 'Length',
        imageIdIndex: 0,
        isPlaying: false,
        frameRate: 10,
    })
    console.log('ref', ref);
    const getFiles = async () => {
        const url = `http://187.216.113.211/dbsystem/web/index.php?r=resultadoscapturaimagen/descargar&id=MzAwMTY=`;
        var settings = {
            "url": url,
            "method": "GET",
            "timeout": 0,
            "headers": {
                "content-type": 'multipart/related;start="<baba99da-3722-40d4-88d7-707f8be91443@resteasy-multipart>";type="application/octet-stream"; boundary=1430ea1a-64d6-4f02-805d-9a0689f87189',
                "accept": 'multipart/related; type="application/octet-stream"'
            },
        };

        let blob = await fetch(url).then(r => r.blob());
        const response = {};
        // const response = await window.$.ajax(settings);
        console.log('blob', blob);
        const dicomFile = new File([blob], "image.dcm", { type: "application/dicom" })
        let objectURL = URL.createObjectURL(dicomFile);
        console.log('objectURL', objectURL);
        const urlPath = `wadouri:${objectURL.replace('http://')}`;
        console.log('urlPath', urlPath);

        setState({ ...state, imageIds: [...state.imageIds, urlPath] })
        if (response.data) {
            //   callback(null, response.data);
        } else {
            //   callback({error:'No hay datos para mostrar'})
        }
    }
    useEffect(() => {
        getFiles();
    }, []);
    const globalUser = useSelector(state => state.session.globalUser);

    console.log('user', globalUser)
    const isMedico = !(globalUser?.medico?.status === 'success');

    return (
        <Box p={0} m={0} style={{ height: '90vw', borderRadius: 16, overflow: 'hidden', marginBottom: 100 }}>
            <Box style={{ height: 'calc(100vh - 60px)', borderRadius: 16, backgroundColor: 'black', boxShadow: '0px 2px 10px gray', overflow: 'hidden', marginTop: isMedico ? 0 : -100 }}>
                {
                    isMedico &&
                    <Box p={1} style={{ display: "flex", flexWrap: "nowrap", borderBottom: '1px solid gray', height: 60, overflowY: 'hidden', overflowX: 'scroll', flexDirection: 'row' }}>
                    {
                        controls.map((item, index) => {
                            const Icon = item.icon || QuestionAnswerOutlined;
                            return (
                                <Tooltip title={item.name} arrow>
                                    <IconButton
                                        color='primary'
                                        onClick={() => setState({ ...state, activeTool: item.id })}
                                        style={{ marginRight: 5, flexWrap: "nowrap" }}
                                    >
                                        <Typography>
                                        {item.name}
                                    </Typography>
                                    </IconButton>
                                </Tooltip>

                            )
                        })
                    }
                </Box> 
                }
                <div style={{ display: "flex", flexWrap: "wrap", height: 'calc(100% - 60px)', borderRadius: 16, overflow: 'hidden' }}>
                    {state.viewports.map(viewportIndex => (
                        <CornerstoneViewport
                            ref={ref}
                            key={viewportIndex}
                            style={{ minWidth: '50%', height: '100%', flex: '1', marginTop: isMedico ? 0 : -80 }}
                            tools={state.tools}
                            imageIds={state.imageIds}
                            imageIdIndex={state.imageIdIndex}
                            isPlaying={state.isPlaying}
                            frameRate={state.frameRate}
                            className={state.activeViewportIndex === viewportIndex ? 'active' : ''}
                            activeTool={state.activeTool}
                            setViewportActive={() => {
                                setState({
                                    ...state,
                                    activeViewportIndex: viewportIndex,
                                });
                            }}
                        />
                    ))}
                </div>
            </Box>
        </Box>
    );
};
export default DicomViewer;
