import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    List,
    ListItem,
    ListItemText,
    AccordionActions,
    Button,
    Grid,
    IconButton,
    Dialog,
    CircularProgress,
    Paper,
    DialogTitle,
    DialogContent,
    InputBase,
    DialogActions,
    TextField,
    DialogContentText,
    useMediaQuery,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import internalAPI from '../../../utils/internalAPI';
import { CloudDownloadOutlined, CropFreeOutlined, FileCopyOutlined } from '@material-ui/icons';
import ModalUI from 'components/containers/ModalUI';
import styled from 'styled-components';
import Input from 'components/Delivery/subComponents/Input';
import repository from '../repository';
import Loading from 'components/Loading';
import { Image } from 'components/atoms';
import billingsEndPoints from 'utils/internalAPI/endPoints/billingEndPoints';
import { useTheme } from '@material-ui/styles';
import Avisos from 'components/Avisos';
import { QrReader } from 'react-qr-reader';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));
const MaskIcon = styled('div')`
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  padding: 0px;
  background-color: red;
  background-size: '30px 30px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'icons/factura.svg'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;

const FacturacionForm = ({ modeView = 'list' }) => {
    const classes = useStyles();
    const [modal, setModal] = useState(null);
    const [data, setData] = useState({})

    const [displayAviso, setdisplayAviso] = useState(false);
    const [scanner, setScanner] = useState(null);
    const [selected, setSelected] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const results = useSelector(state => state.studies.resultsGrouped);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('sm'), {
        defaultMatches: true,
    });
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const fields = [
        {
            "key": "rfc", "label": "RFC", end: (
                <IconButton onClick={() => setScanner('scan')}>
                    <CropFreeOutlined />
                </IconButton>
            )
        },
        { "key": "cliente", "label": "Razón Social" },
        { "key": "calle", "label": "Calle" },
        { "key": "no_ext", "label": "No. Ext." },
        { "key": "no_int", "label": "No. Int." },
        { "key": "colonia", "label": "Colonia" },
        { "key": "ciudad", "label": "Municipio" },
        { "key": "estado", "label": "Entidad Federativa" },
        { "key": "email1", "label": "Correo Electrónico" },
        { "key": "metodo_pago", "label": "Método de Pago" },
        { "key": "telefono", "label": "Teléfono" }
    ]
    const handleDownloadPDF = (id_orden) => {
        // return () => {
        internalAPI.getPDF(id_orden, (error, data) => {
            if (error || !data) {
                console.log('error', error)
                window.open();
                return;
            }
            // setLoading(true);
            // setSelected(data);
            handleDownloadPDFFile(data)
            window.open(data);
        });
        // };
    };
    const handleDownloadBilling = (item) => {
        // return () => {
        console.log('item', item);
        const url = (item.facturas[0] || {}).url_factura_pdf;
        if (url.includes('https://'))
            window.open(url);

        // };
    };
    const handleDownloadPDFFile = (url) => {
        // return () => {
        internalAPI.getPDFFile(url, (error, data) => {
            if (error || !data) {
                // window.open();
                return;
            }
            console.log('resltados', data)
            setSelected(data);
            // window.open(data);
        });
        // };
    };
    const getNote = async (item) => {
        // await navigator.clipboard.writeText(getData(item).texting);
    }
    useEffect(() => {
        billingsEndPoints.dataBillings((err, res) => {
            console.log('res', res);
            console.log('err', err);
            res &&
                setData(res.datos_fiscales);
        });
        console.log('res------------>');
    }, [])
    console.log('results', results)
    return (
        <Box p={!isMd ? 1 : 0} display={modeView === 'list' ? 'block' : 'none'} >
            <Box style={{ borderRadius: 16, overflow: 'hidden', boxShadow: '0px 0px 5px gray' }}>
                {results.map((item, index) => (
                    <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${index}bh-content`}
                            id={`${index}bh-header`}
                        >
                            <Grid container direction='row'>
                                <Grid style={{ flexDirection: 'column', alignSelf: 'center', padding: 5 }} >
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation();
                                        const facturado = item.facturas?.facturado || false;
                                        const facturas = item.facturas?.facturas || [];
                                        if (facturado) {
                                            handleDownloadBilling({ ...item, facturas, facturado });
                                        } else {
                                            setModal({ ...item, facturas, facturado })
                                        }
                                    }}>
                                        {/* <FileCopyOutlined /> */}
                                        <MaskIcon style={{ backgroundColor: 'gray', alignSelf: 'center' }} />

                                    </IconButton>
                                </Grid>
                                <Grid style={{ flexDirection: 'column', alignSelf: 'center', padding: 5 }} >
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation();
                                        console.log('orden', item)
                                        handleDownloadPDF(item.id_orden)
                                    }}>
                                        <CloudDownloadOutlined />
                                    </IconButton>
                                </Grid>
                                <Grid container item xs={6} alignContent='flex-start' style={{ flexDirection: 'column', alignSelf: 'center' }} >
                                    <Typography variant='caption' align='left' style={{ fontWeight: 600 }}>
                                        Fecha: {item.fecha}
                                    </Typography>
                                </Grid>
                                <Grid style={{ flexDirection: 'column', alignSelf: 'center' }} >
                                    <Typography variant='caption' style={{ marginRight: 8, width: '100%', textAlign: 'right' }} >
                                        Folio: {item.folio}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List style={{ width: '100%' }}>
                                {item.resultados.map((result, index) => (
                                    <ListItem key={index} divider={!result.unidad}>
                                        <Grid container direction='row'>
                                            <Grid xs>
                                                <Typography variant='caption' align='left' style={{ fontWeight: 600 }}>
                                                    {result.titulo}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={3}>
                                                <Typography variant='caption' align='right' >
                                                    {`${result.resultado} ${result.unidad}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                ))}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                ))}
                <ModalUI
                    open={!!selected}
                    onClose={() => setSelected(null)}
                    fullScreen={!isMd}
                >
                    {
                        loading &&
                        <Box
                            position="absolute"
                            top={0}
                            bottom={0}
                            left={0}
                            right={0}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            style={{ backgroundColor: 'white' }}
                        >
                            <CircularProgress color={'primary'} />
                            <Box>
                                <Typography color={'primary'} style={{ marginTop: 8 }}>
                                    {'Cargando resultados'}
                                </Typography>
                                <Button fullWidth onClick={() => { setSelected(null); setLoading(false) }}>
                                    {'Cancelar'}
                                </Button>
                            </Box>
                        </Box>
                    }
                    <Box
                        position="absolute"
                        top={0}
                        bottom={0}
                        left={0}
                        right={0}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        style={{ backgroundColor: 'white' }}
                    />
                </ModalUI>
                <ModalUI
                    open={!!modal}
                    onClose={() => setModal(null)}
                    transparent={true}
                    fullScreen={!isMd}
                >
                    <DialogTitle style={{ boxShadow: '0px 0px 3px gray', zIndex: 40000 }}>
                        <Box width={'100%'}>
                            <img
                                className={classes.avatar}
                                src="/images/logos/logo.svg"
                                alt="deliabarraza"
                                height={40}
                            />

                        </Box>
                        {/* {'Facturación'} */}
                    </DialogTitle>
                    <DialogContent>
                        {
                            modal?.facturado ?
                                <Typography variant='h6' style={{ textAlign: 'left', width: '100%', padding: 8 }}>
                                    {'Folio facturado: ' + modal?.folio}
                                </Typography> :
                                <Typography variant='h6' style={{ textAlign: 'left', width: '100%', padding: 8 }}>
                                    {'Facturar folio: ' + modal?.folio}
                                </Typography>
                        }
                        {
                            !(modal || {}).facturado &&
                            <Grid container spacing={2} justifyContent='space-evenly'>

                                <Typography variant='h6' style={{ textAlign: 'left', width: '100%', padding: 8 }}>
                                    {'Datos de Facturación'}
                                </Typography>

                                {fields.map((item, index) => {
                                    return (
                                        <Grid item xs={12}>

                                            <TextField
                                                id={item.key}
                                                variant='outlined'
                                                fullWidth
                                                value={data[item.key] || ''}
                                                placeholder={item.label}
                                                onChange={(e) => setData({ ...data, [item.key]: e.target.value })}
                                                InputProps={{ endAdornment: item.end || undefined }}
                                            />
                                        </Grid>
                                    )
                                })

                                }
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        fullWidth
                                        control={
                                            <Checkbox name="jason" />
                                        }
                                        label={<div>
                                            Acepto{" "}
                                            <a
                                                onClick={(e) => {
                                                    setdisplayAviso(true)
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                style={{ color: theme.palette.primary.main, fontWeight: 600 }}
                                            >
                                                terminos y condiciones
                                            </a>
                                        </div>}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        fullWidth
                                        control={
                                            <Checkbox name="jason" />
                                        }
                                        label={<div>
                                            Acepto{" "}
                                            <a
                                                onClick={(e) => {
                                                    setdisplayAviso(true)
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                style={{ color: theme.palette.primary.main, fontWeight: 600 }}
                                            >
                                                aviso de privacidad
                                            </a>
                                        </div>}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </DialogContent>
                    <DialogActions style={{ boxShadow: '0px 0px 8px gray', zIndex: 40000 }}>
                        <Button onClick={() => setModal(null)} variant='contained' color='primary'>
                            {'Cancelar'}
                        </Button>
                        {console.log('data------>', data)}
                        {
                            (modal || {}).facturado ?
                                <Button variant='outlined' disabled={!(data.rfc)}>
                                    {'Ver'}
                                </Button> :
                                <Button onClick={() => {
                                    if (data.referencia) {
                                        billingsEndPoints.saveDataBillings({ folios: [data.referencia] }, (err, res) => {
                                            console.log('res', res);
                                            console.log('err', err);
                                            if (res) {
                                                billingsEndPoints.saveBillings(data, (err, res) => {
                                                    console.log('res', res);
                                                    console.log('err', err);
                                                    res &&
                                                        setData(res.datos_fiscales);
                                                });
                                            }
                                        });
                                    } else {
                                        billingsEndPoints.saveBillings(data, (err, res) => {
                                            console.log('res', res);
                                            console.log('err', err);
                                            res &&
                                                setData(res.datos_fiscales);
                                        });
                                    }
                                }} variant='outlined' disabled={!(data.rfc) || !(data.email1) || !(data.cliente)}>
                                    {'Facturar'}
                                </Button>
                        }
                    </DialogActions>
                </ModalUI>
                <ModalUI
                    open={scanner === 'scan'}
                    onClose={() => setScanner(null)}
                    fullScreen={!isMd}
                >
                    <DialogTitle style={{ boxShadow: '0px 0px 8px gray', zIndex: 40000 }}>
                        <Box width={'100%'}>
                            <img
                                className={classes.avatar}
                                src="/images/logos/logo.svg"
                                alt="deliabarraza"
                                height={40}
                            />
                        </Box>
                        {'Captura el código QR de tu cédula de identificación fiscal'}
                    </DialogTitle>
                    <DialogContent>
                        <QrReader
                            delay={500}
                            onScan={(scan) => {
                                if ((`${scan}`.includes('https://siat.sat.gob.mx/app/qr/faces/pages/mobile'))) {
                                    setScanner('loading');
                                    repository.getRFCData(scan, (dataSAT) => {
                                        console.log('data', dataSAT);
                                        const editData = {
                                            rfc: `${scan}`.split('_')[1],
                                            cliente: `${dataSAT.Nombre} ${dataSAT['Apellido Paterno']} ${dataSAT['Apellido Materno']}`,
                                            email1: `${dataSAT['Correo electrónico']}`,
                                            colonia: `${dataSAT['Colonia']}`,
                                            calle: `${dataSAT['Nombre de la vialidad']}`,
                                            no_ext: `${dataSAT['Número exterior']}`,
                                            no_int: `${dataSAT['Número interior']}`,
                                            estado: `${dataSAT['Entidad Federativa']}`,
                                            ciudad: `${dataSAT['Municipio o delegación']}`,
                                            cp: `${dataSAT['CP']}`
                                        };

                                        setData({ ...data, ...editData })
                                        setScanner(null);
                                    });
                                }
                            }} />
                    </DialogContent>
                    <DialogActions style={{ boxShadow: '0px 0px 8px gray', zIndex: 40000 }}>
                        <Button onClick={() => setScanner(null)} variant='contained' color='primary'>
                            {'Cancelar'}
                        </Button>
                    </DialogActions>
                </ModalUI>
                {
                    scanner === 'loading' && <Loading />
                }
                <ModalUI
                    open={displayAviso}
                    onClose={() => setdisplayAviso(false)}
                >
                    <Avisos />
                    <DialogActions>
                        <Button onClick={() => setdisplayAviso(false)}>
                            {'Salir'}
                        </Button>
                    </DialogActions>
                </ModalUI>
            </Box>
        </Box>
    );
};

export default FacturacionForm;
