import { Box, Grid, Button, ButtonGroup, Dialog, List, ListItem, Typography, useTheme, Divider, IconButton } from '@material-ui/core';
import DicomViewer from 'common/DicomViewer';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import React, { useState } from 'react';

const DicomView = () => {
    const [open, setOpen] = useState(true);
    const theme = useTheme();

    return (
        <Box
            style={{ width: '100%', height:'100vh' }}
            variant='outlined'
        >
            <DicomViewer />
        </Box>
    );
};

export default DicomView;
