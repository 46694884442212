import React, { Suspense, useEffect, useRef, useState, lazy } from "react"
import { Canvas, useFrame, useLoader, } from "@react-three/fiber"

export default function GroupModel3D({ onClick = () => { }, rotate, children }) {
    const refBodyGroup = useRef();
    useFrame((state) => {
        if (rotate) {
            refBodyGroup.current.rotation.y = refBodyGroup.current.rotation.y + 0.02;
        }
    })
    return (
        <group ref={refBodyGroup} onClick={onClick}>
            {children}
        </group>

    )
}
