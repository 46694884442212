import React from "react";
import { omit } from "lodash";
import SpeechButton from "./SpeechButton";
import { Grid, InputBase } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    input: {
        backgroundColor: 'rgba(255,255,255,1)',
        borderRadius: 16,
        border: `solid 0.5px rgba(255,255,255,0.2)`,
        padding: 8,
        width: '100%',
        minWidth: 200,
        marginBottom: 16,
        height: 'auto',
        boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
        transitionDuration: 200,
        "&:focus,&:hover": {
            backgroundColor: 'rgba(255,255,255,1)',
            border: `solid 1px ${theme.palette.primary.main}`,
            boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 16,
            height: 50,
        },
    },
    inputBase: {
        flex: 1,
        height: '100%',
        padding: '5px 16px',
        boxShadow: 'none',
        "&:focus,&:hover": {
            backgroundColor: 'inherit',
            border: 0,
            boxShadow: 'none',
        }
    },
}));

function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
const Input = ({
    ...props
}) => {
    const classes = useStyles();
    return (
        <Grid item xs>
            <InputBase
                className={classes.input}
                {...props}
                style={{ marginBottom: props.separator ? 16 : 0 }}
            />
        </Grid>

    );
};

export default Input;