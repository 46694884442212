import React, { useState, lazy, Suspense } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Dialog, Typography, useMediaQuery } from '@material-ui/core';
import Browser from 'views/Browser';
import Delivery from 'components/Delivery';
import useMeasure from 'react-use-measure';
import ModulesLink from 'components/ModulesLink';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import { useTheme } from '@material-ui/styles';
// lazy comp
const InputBox = lazy(() => import('./components/InputBox'));
const CarouselPaquetes = lazy(() => import('./components/CarouselPaquetes'));
const CarouselHead = lazy(() => import('./components/CarouselHead'));
const CaroucelMedia = lazy(() => import("./components/CaroucelMedia"));
const Blog = lazy(() => import('../Blog'));

// styles
const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh', overflow: 'hidden', overflowY: 'scroll',
    // animation: `$myEffect 50ms ${theme.transitions.easing.sharp}`,
  },
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  floatContainer: {
    position: 'relative',
    height: 'auto',
    padding: 0,
    width: '100%',
    zIndex: 50,
    paddingTop: 30,
    [theme.breakpoints.up('md')]: {
      marginBottom: 16,
      paddingTop: 30,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '50%',
    },
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0.5,
    },
    "100%": {
      opacity: 1,
    }
  },
  floatBox: {
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: 16,
    border: `solid 0.5px rgba(255,255,255,0.2)`,
    width: '100%',
    marginBottom: 24,
    maxWidth: 400,
    boxShadow: '0px 4px 24px rgba(100,100,100,1)',
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
  text: {
    marginBottom: 32,
    fontSize: 20,
    fontWeight: 800,
    minHeight: 'calc(100% - 100px)',
    color: 'gray',
    [theme.breakpoints.up('md')]: {
      fontSize: 33,
    },
  },
  input: {
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: 16,
    width: '100%',
    minWidth: 200,
    marginBottom: 16,
    maxHeight: 300,
    boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
    overflowY: 'scroll',
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: 50,
    transitionDuration: 500,
    border: `solid 1px ${theme.palette.primary.main}`,
    "&:focus,&:hover": {
      backgroundColor: 'rgba(255,255,255,1)',
      border: `solid 1px ${theme.palette.primary.main}`,
      boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 16,
    },
  },
  button: {
    borderRadius: 16,
    width: '100%',
    minWidth: 200,
    marginBottom: 16,
    maxHeight: 300,
    boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
    minHeight: 50,
    transitionDuration: 500,
    "&:focus,&:hover": {
      border: `solid 1px ${theme.palette.primary.main}`,
      boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 16,
    },
  },
  box: {
    display: 'flex',
    height: '70vh',
    backgroundColor: 'red'
  },
  subTitles: {
    color: theme.palette.primary.main
  },
  imageFrame: {
    width: '100%',
    float: 'right',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  imageLayout: {
    height: '100vw',
    width: '100vw',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    [theme.breakpoints.up('md')]: {
      width: '50vw',
      height: 'calc(100vh - 60px)',
    },
  },
  image: {
    width: '100%',
    minWidth: '100%',
    // maxWidth: 500,
    float: 'right',
    maxHeight: 'calc(100vh - 120px)',
    overflow: 'hidden',
    zIndex: 1000,
    marginBottom: 32,
    [theme.breakpoints.up('md')]: {
      minWidth: '60%',
    },
  },
  background: {
    width: '100%',
    height: 'calc(100vh - 60px)',
  },
  iconButton: {
    position: 'fixed',
    top: 8,
    right: 8,
    color: 'rgba(200,200,200,0.8)',
    backgroundColor: theme.palette.primary.main,
    opacity: 0.9
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  footer: {
    height: '13%',
    backgroundColor: '#ec008c',
    posi: 'right',
    bottom: 0,
    width: '100%',
    zIndex: 0
  }
}));
const month = (new Date()).getMonth() + 1;

const Promotions = () => {
  const classes = useStyles();
  const [ref, { width }] = useMeasure();
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const data = {
    "month": "07",
    "media": [
      { "type": "png", "name": "0" },
      { "type": "jpg", "name": "1" },
      { "type": "jpg", "name": "2" },
      { "type": "jpg", "name": "3" }
    ]
  };
  const dataBase = {
    "month": "07",
    "media": [
      { "type": "png", "name": "1" },
      { "type": "png", "name": "2" },
    ]
  };
  const backgrondsColors = [
    'rgba(168,126,226,1)',
    'rgba(95,176,143,1)',
    'rgba(211,116,177,1)',
    'rgba(168,126,226,1)',
    'rgba(95,176,143,1)',
    'rgba(211,116,177,1)',
    'rgba(168,126,226,1)',
    'rgba(95,176,143,1)',
    'rgba(211,116,177,1)',
    'rgba(168,126,226,1)',
    'rgba(95,176,143,1)',
    'rgba(211,116,177,1)',
  ]
  const portland = !!(window.screen.height > window.screen.width);
  return (
    <Box key={'pro'} ref={ref} className={classes.root}>
      <Box style={{ position: 'relative' }}>
        <Suspense fallback={<div style={{
          width: '100%',
          height: portland ? '100vw' : '50vw',
        }}>
        </div>}>
          {/* componente de card con input  */}
          <InputBox
            classes={classes}
            openModal={() => setOpen(true)}
          />
          {/* caroucel header  */}
          <CarouselHead
            classes={classes}
            data={data}
            portland={portland}
            backgrondsColors={backgrondsColors}
          />
        </Suspense>

        {/* <Suspense fallback={<div style={{}}>{'cargando...'}</div>}>
          <CarouselPaquetes
            portland={portland}
            classes={classes}
            width={width}
          />
        </Suspense> */}

      </Box>
      {/* modal */}
      <Dialog open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ style: { backgroundColor: 'transparent', borderRadius: 8 } }}
      >
        <Delivery covidSelector={false} />
      </Dialog>
      <Box position={'relative'}>
        {
          !focus && isMd &&
          <Box style={{ position: 'absolute', textAlign: 'center', width: '100vw', fontWeight: 800, marginTop: -150 }}>
            <Button
              onClick={() => {
                document.getElementById('searchField').scrollIntoView({ behavior: 'smooth', inline: 'start' })
                setFocus(true);
              }}
              variant={'contained'} className={classes.subTitles} style={{
                textAlign: 'center', borderRadius: 16, padding: 24, fontWeight: 800, backgroundColor: 'white',
                // border: `2px solid ${theme.palette.primary.main}`,
                boxShadow: '0px 0px 10px rgba(50,50,50,0.5)'
              }}>
              {'Busca tus estudios o paquetes'}
            </Button>
          </Box>
        }
      </Box>
      {/* buscador de estudios */}
      <Typography variant={'h5'} className={classes.subTitles} style={{ textAlign: 'center', width: '100vw', fontWeight: 800, marginTop: 30 }}>
        {'Busca tus estudios o paquetes'}
      </Typography>
      <Browser title='Paquetes para tu salud' withOutHeader />
      <Box style={{ position: 'relative' }}>
        <Suspense fallback={<div style={{}}>{'cargando...'}</div>}>
          <CarouselPaquetes
            portland={portland}
            classes={classes}
            width={width}
          />
        </Suspense>
      </Box>
      <Suspense key={1} fallback={<div style={{}}>{'cargando...'}</div>}>
        <ResponsiveLayout>
          <Box
            p={2}
            style={{
              width: '100%', flexDirection: 'column', overflow: 'hidden', overflowX: 'auto',
              whiteSpace: 'nowrap'
            }}
          >
            <ModulesLink />
          </Box>
        </ResponsiveLayout>
      </Suspense>
      <Suspense key={2} fallback={<div style={{}}>{'cargando...'}</div>}>
        {/* caroucel media */}
        <CaroucelMedia
          classes={classes}
          portland={portland}
          width={width}
          data={dataBase.media}
        />
      </Suspense>

      {/* <Suspense fallback={<div style={{}}>{'cargando...'}</div>}>
        <Blog />
      </Suspense> */}
    </Box>
  );
};

export default Promotions;
