import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, ListItem, Paper, Typography, Divider, Button, InputBase, Popover, ButtonGroup, List, debounce, CircularProgress, Dialog, Slide, DialogTitle, DialogActions, Zoom } from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import useMeasure from 'react-use-measure'
import { Carousel } from 'react-responsive-carousel';
import internalAPI from '../../internalAPI';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import areas from '../../constants/areas.json';
import types from '../../constants/types.json';
import { ArrowForward, WhatsApp } from '@material-ui/icons';
import { Modal } from '@material-ui/core';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import DicomViewer from 'common/DicomViewer';
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignContent: 'flex-start',
    },
    containerData: {
        animation: `$myEffect 500ms ${theme.transitions.easing.sharp}`,
    },
    "@keyframes myEffect": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    },
    container: {
        width: '100%',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        '& svg': {
            margin: theme.spacing(1.5),
        },
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
    },
    selector: {
        display: 'flex',
        flexWrap: 'wrap',
        height: 60,
        width: '100%',
        borderRadius: 16,
        backgroundColor: 'white',
        justifyContent: 'space-around',
        alignContent: 'flex-start',
        marginBottom: 60,

    },
    floatContainer: {
        position: 'relative',
        height: 'auto',
        padding: 16,
        width: '100%',
        zIndex: 50,
        // [theme.breakpoints.up('md')]: {
        //     marginBottom: 16,
        //     paddingTop: 30,
        //     position: 'absolute',
        //     top: 0,
        //     left: 0,
        //     width: '50%',
        //     height: '100%',
        // },
    },
    "@keyframes myEffect": {
        "0%": {
            opacity: 0.5,
        },
        "100%": {
            opacity: 1,
        }
    },
    floatBox: {
        backgroundColor: 'rgba(255,255,255,1)',
        borderRadius: 16,
        border: `solid 0.5px rgba(255,255,255,0.2)`,
        width: '100%',
        marginBottom: 24,
        maxWidth: 400,
        boxShadow: '0px 4px 24px rgba(100,100,100,1)',
        [theme.breakpoints.up('md')]: {
            width: 400,
        },
    },
    text: {
        marginBottom: 32,
        fontSize: 18,
        fontWeight: 800,
        minHeight: 'calc(100% - 100px)',
        color: 'gray',
        [theme.breakpoints.up('md')]: {
            fontSize: 18,
        },
    },
    formContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
        maxWidth: 500,
        margin: `0 auto`,
    },
    box: {
        display: 'flex',
        height: '70vh',
        backgroundColor: 'red'
    },
    image: {
        width: '100%',
        maxWidth: 500,
        float: 'center',
        boxShadow: '1px 1px 5px gray',
        borderRadius: 10,

    },
    iconButton: {
        position: 'fixed',
        top: 8,
        right: 8,
        color: 'rgba(200,200,200,0.8)',
        backgroundColor: theme.palette.primary.main,
        opacity: 0.9
    },
    section: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    label: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    containerTag: {
        flexDirection: 'column',
        borderRadius: 0,
        boxShadow: '0px 0px 10px gray',
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            borderRadius: 16,
            margin: '60px 0px 0px 0px',
        },
    },
    tagLeft: {
        backgroundColor: 'rgba(255,255,255,1)',
        padding: 30,
        [theme.breakpoints.up('md')]: {
            padding: 40,
        },
    },
    tagRight: {
    },
    input: {
        backgroundColor: 'rgba(255,255,255,0.8)',
        borderRadius: 16,
        width: '100%',
        minWidth: 200,
        marginTop: 16,
        marginBottom: 0,
        maxHeight: 300,
        boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
        overflowY: 'scroll',
        paddingLeft: 16,
        paddingRight: 16,
        minHeight: 50,
        transitionDuration: 500,
        border: `solid 1px ${theme.palette.primary.main}`,
        "&:focus,&:hover": {
            backgroundColor: 'rgba(255,255,255,1)',
            border: `solid 1px ${theme.palette.primary.main}`,
            boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    inputBase: {
        flex: 1,
        height: '100%',
        padding: '5px 16px',
        boxShadow: 'none',
        "&:focus,&:hover": {
            backgroundColor: 'inherit',
            border: 0,
            boxShadow: 'none',
        }
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ViewerPublic = (props) => {
    const classes = useStyles();
    const [tokenPublic, setTokenPublic] = useState(null);
    const [study, setStudy] = useState(null);
    const [screen, { height, width }] = useMeasure();
    const history = useHistory();
    async function getStudy() {
        try {
            const urlParams = new URLSearchParams(history.location?.search);

            const codigo = urlParams.get("data");
            console.log('data', codigo);
            // Paso 2: Realizar la solicitud de login y obtener el api_token
            const loginResponse = await fetch("https://apithink.dblaboratorios.mx/index.php/login/codigo", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ codigo }),
            });

            const loginData = await loginResponse.json();

            if (loginData.status !== "success") {
                throw new Error("Error en el login");
            }

            const apiToken = loginData.api_token;

            // Paso 3: Realizar la solicitud para descargar las imágenes utilizando el api_token
            const imagenesResponse = await fetch("https://apithink.dblaboratorios.mx/index.php/resultados/ver-imagenes", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": apiToken,
                },
            });

            const imagenesData = await imagenesResponse.json();

            if (imagenesData.status !== "success") {
                throw new Error("Error al obtener las imágenes");
            } else {
                setStudy(imagenesData?.data);
                console.log(imagenesData?.data, imagenesData?.data);

            }
        } catch (error) {
            console.error("Error:", error.message);
        }
    }
    useEffect(() => {
        console.log('history', history);
        console.log('history', history?.location);
        getStudy();
        // if (data?.length) {
        //     solicitarEstudios();
        // }
    }, [history])

    return (
        <Box ref={screen} style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignContent: 'center' }}>
            <Dialog
                fullScreen
                open={!!study}
                TransitionComponent={Zoom}
            >
                {<DicomViewer urls={study?.rutas || []} setHiddeBottomTool={() => {
                    setStudy(null);
                    history.push('promociones-mes');
                }} />}
            </Dialog>
        </Box>
    );
};

export default ViewerPublic;
