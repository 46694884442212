import React, { useEffect, useMemo, useState } from 'react';
import { Marker, LoadScript, GoogleMap } from '@react-google-maps/api';
import { Box, debounce } from '@material-ui/core';
const center = {
    lat: 55.378,
    lng: 3.436
};

const containerStyle = {
    width: '100%',
    height: '100%'
}

export default function Map(props) {
    const [map, setMap] = useState();
    const [bounds, setBounds] = useState();

    const [markers, setMarkers] = useState([
        {
            lat: 51.378,
            lng: 3.436
        },
        {
            lat: 47.0902,
            lng: -125.712
        }
    ]);

    const delayed = useMemo(
        () => debounce(function smoothCenter() {
            console.log('entro a renderizar la actualizacion')
            const bd = {

            };
            (props.points || []).map((point) => {
                const lat = point.location.lat;
                const lng = point.location.lng;
                bd.minLat = lat < bd.minLat || bd.minLat === undefined ? lat : bd.minLat;
                bd.maxLat = lat > bd.maxLat || bd.maxLat === undefined ? lat : bd.maxLat;
                bd.minLng = lat < bd.minLng || bd.minLng === undefined ? lng : bd.minLng;
                bd.maxLng = lat > bd.maxLng || bd.maxLng === undefined ? lng : bd.maxLng;
            })
            console.log('props.points.length === 1', props.points.length === 1)
            bd.minLat = bd.minLat - (props.points.length === 1 ? 3 : 0.03);
            bd.maxLat = bd.maxLat + (props.points.length === 1 ? 3 : 0.03);
            bd.minLng = bd.minLng - (props.points.length === 1 ? 3 : 0.03);
            bd.maxLng = bd.maxLng + (props.points.length === 1 ? 3 : 0.03);

            if (map && props.points?.length) {
                // console.log('bd---- entro', bd);
                const initialBounds = map.getBounds();
                const steps = 20
                const difMinLat = bd.minLat - initialBounds.Ya.lo;
                const difMaxLat = bd.maxLat - initialBounds.Ya.hi;
                const difMinLng = bd.minLng - initialBounds.Ia.lo;
                const difMaxLng = bd.maxLng - initialBounds.Ia.hi;
                let index = 1;
                function smooth(i) {
                    const sw = { lat: initialBounds.Ya.lo + ((difMinLat / steps) * i), lng: initialBounds.Ia.lo + ((difMinLng / steps) * i) }
                    const ne = { lat: initialBounds.Ya.hi + ((difMaxLat / steps) * i), lng: initialBounds.Ia.hi + ((difMaxLng / steps) * i) }
                    // console.log('limits', sw, ne)
                    const bound = new window.google.maps.LatLngBounds(sw, ne);
                    map.fitBounds(bound);
                    if (i < steps) {
                        setTimeout(() => {
                            smooth(i + 1);
                        }, 50);
                    }
                }
                smooth(index)
                // map.setCenter((props.points[0])?.location || {
                //     lat: 47.0902,
                //     lng: -125.712
                // });
                // map.setZoom(10);
            }
        }, 500),
        [map, props.points]
    );
    // useEffect(() => {
    //     delayed()
    // }, [props.points])

    useEffect(() => {
        if (map) {
            const bounds = new window.google.maps.LatLngBounds();
            props.points.map(marker => {
                bounds.extend(marker.location);
            });
            map.fitBounds(bounds);
            if (props.points.length === 1) {
                map.setZoom(15);
            }
        }
    }, [map, props.points]);

    const onLoad = React.useCallback(function callback(map) {
        // const bound = new window.google.maps.LatLngBounds();
        // setBounds(bound)
        // console.log('bounds----', bound);
        // map.fitBounds(bound);
        // map.setZoom(5)
        setMap(map)
    }, [])
    const fitB = () => {
        let bounds = new window.google.maps.LatLngBounds();
        console.log({ bounds });
        props.points &&
            props.points.map(place => {
                bounds.extend(place.location);
            });

        console.log({ extended: bounds });

        return bounds;
    };

    return (
        <Box width={'100%'} height='100%'>
            <LoadScript
                googleMapsApiKey="AIzaSyALFV0i5CA9nuaUYvabwL09uCsETeGWTQM"

            >
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                    onLoad={onLoad}
                    fitBounds={{ latLngBounds: () => fitB() }}
                    options={{ fullscreenControl: false, mapTypeControl: false, scaleControl: false, streetViewControl: false }}
                >
                    {
                        props.points.map((item, index) => {
                            return (
                                <Marker key={index} onClick={() => props.onClick(item)} animation="DROP" position={item.location} />
                            )
                        })
                    }
                </GoogleMap>
            </LoadScript>
        </Box>
    )
}