/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Typography,
  Button,
  IconButton,
  debounce,
  TextField,
  Box,
  Dialog,
  InputBase,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DeleteForeverOutlined, SearchOutlined, WhatsApp } from '@material-ui/icons';
import useMeasure from 'react-use-measure'
import { Autocomplete } from '@material-ui/lab';
import { TransitionUp } from 'common/TransitionComponents';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    overFlowX: 'hidden'
  },
  listItemContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: 'calc(100% + 50px)',
    minHeight: 100,
    transitionDuration: 200,
    "&:hover": {
      width: '100%'
    }
  },
  listItemSelected: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  closeIcon: {
    position: 'absolute',
    right: 10,
    top: 10
  },
  divider: {
    width: '100%',
  },
  input: {
    backgroundColor: 'rgba(200,200,200,0.0)',
    borderRadius: 10,
    border: `solid 1px rgba(200,200,200,0.2)`,
    padding: 8,
    transitionDuration: 200,
    "&:focus,&:hover": {
      backgroundColor: 'rgba(255,255,255,1)',
      border: `solid 1px ${theme.palette.primary.main}`,
      boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
    }
  },

  button: {
    borderRadius: 10,
    backgroundColor: 'rgba(255,255,255,1)',
    border: `solid 1px ${theme.palette.primary.main}`,
    padding: 8,
    transitionDuration: 200,
    "&:focus,&:hover": {
      backgroundColor: 'rgba(255,255,255,1)',
      border: `solid 1px ${theme.palette.primary.main}`,
      boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
    }
  },
  startAdornment: {
    marginRight: 8,
    color: 'gray'
  },
  shoppingCartIcon: {
    float: 'right',
  }
}));

const CotizacionMedicos = props => {
  const { pages, onClose, className, ...rest } = props;
  const classes = useStyles();
  const [options, setoptions] = useState([]);
  const [total, setTotal] = useState(0);
  const [link, setLink] = useState('');
  const [optionsSelected, setoptionsSelected] = useState([]);
  const [ref, { width }] = useMeasure();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const handleCloseOptions = () => {
    setAnchorEl(null);
  }
  useEffect(() => {
    let amount = 0;
    let text = '👩🏻‍🔬 ¡Hola Delia!%0AMe interesa un paquete con los siguientes estudios:%0A'
    optionsSelected.map((option) => {
      amount = amount + (option.precio_bronce * 1);
      text = `${text}${option.nombre}: $${option.precio_bronce}%0A`
    });
    text = `${text}Total: ${amount}`
    setTotal(amount)
    if (amount === 0) {
      text = '👩🏻‍🔬 ¡Hola Delia!%0A'
    }
    setLink(`https://api.whatsapp.com/send?phone=526674534062&text=${text}`)
  }, [optionsSelected])
  const searchStudios = debounce((searchTex) => {
    fetch(`${process.env.REACT_APP_API_URL}/estudio/catalogo?estudio=${searchTex}`, {
      method: 'GET'
    })
      .then(res => res.json())
      .then(
        ({ registros = [] }) => {
          setoptions(registros);
        },
        // Nota: es importante manejar errores aquí y no en 
        // un bloque catch() para que no interceptemos errores
        // de errores reales en los componentes.
        (error) => {
          setoptions([]);
        }
      )
  }, 300);
  return (
    [
      !props.button ? <Button
        className={classes.button}
        onClick={() => setOpen(true)}>
        {'Crea tus paquetes personalizados'}
      </Button> :
        <Box onClick={() => setOpen(true)}>
          {props.button}
        </Box>
      ,
      <Dialog
        TransitionComponent={TransitionUp}
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ style: { borderRadius: 10, padding: 8, minHeight: 600, minWidth: 400, maxWidth: '90%' } }}
      >
        <DialogContent>
          <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.listItem}>
              <Typography
                variant="h6"
                color="primary"
                className={classes.listItemLink}
              >
                Nuevo Paquete
              </Typography>
              <IconButton size='small' className={classes.closeIcon} onClick={() => setOpen(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </ListItem>
            <ListItem divider className={classes.listItem}>
              <InputBase
                placeholder='Nombre del médico'
                className={classes.input}
                fullWidth
              />
            </ListItem>
            <ListItem divider className={classes.listItem}>
              <InputBase
                placeholder='Nombre del paquete'
                className={classes.input}
                fullWidth
              />
            </ListItem>
            <ListItem divider className={classes.listItem}>
              <Autocomplete
                id="disabled-options-demo"
                options={options}
                fullWidth
                autoSelect
                getOptionDisabled={(option) => optionsSelected.includes(option)}
                getOptionLabel={(option) => option.nombre}
                noOptionsText='Sin resultados'
                multiple
                renderOption={(option) => {
                  return (
                    <div onClick={() => setoptionsSelected([option, ...optionsSelected])}>
                      <Typography>
                        {option.nombre}
                      </Typography>
                    </div>
                  )
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder='Buscar estudios...'

                    onChange={(e) => {
                      searchStudios(e.target.value)
                    }}
                    {...params}

                    InputProps={{ ...params.InputProps, className: classes.input, disableUnderline: true, startAdornment: <SearchOutlined /> }}
                  />
                )}
              />
            </ListItem>
            {
              optionsSelected.length === 0 &&
              <Button color='primary' style={{ padding: 16 }} fullWidth>
                {'¡Agrega estudios aqui!'}
              </Button>
            }

            {
              optionsSelected.map((item, index) => {
                console.log('item, item', item)
                return (
                  <ListItem divider key={index} className={classes.listItemContainer}>
                    <Typography variant='inherit' style={{ width: 'calc(100% - 50px)' }}>
                      {`- ${item.nombre}`}
                    </Typography>
                    <Typography variant='inherit' style={{ width: 'calc(40% - 50px)' }}>
                      {`$ ${item.precio_lista}`}
                    </Typography>
                    <Box style={{ width: '100%' }}>
                      <Typography variant='inherit'>
                        {`Precio en línea -${((1 - (item.precio_bronce / item.precio_lista)) * 100).toFixed(0)}%`}
                      </Typography>
                      <Typography variant='inherit' style={{ textDecoration: 'line-through' }}>
                        {`   $ ${item.precio_lista}`}
                      </Typography>
                      <Typography variant='inherit' >
                        {`    $ ${(item.precio_bronce * 1).toFixed(0)} `}
                      </Typography>
                    </Box>

                    <IconButton onClick={() => setoptionsSelected(optionsSelected.filter(e => e !== item))} size='small' style={{ position: 'absolute', top: 8, right: 8 }}>
                      <DeleteForeverOutlined />
                    </IconButton>
                  </ListItem>
                )
              })
            }

            {/* {
        total ?
          <ListItem className={classes.listItem}>
            <PaypalButton total={total} />
          </ListItem> :
          null
      } */}
          </List>
        </DialogContent>
        <DialogActions>
          <Box width={'100%'} flexDirection={'column'}>
            <ListItem divider className={classes.listItem} />
            <ListItem divider className={classes.listItem}>
              <Typography variant='h6' style={{ width: '100%', textAlign: 'right' }}>
                {`Total: $${total}`}
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                size="large"
                endIcon={<WhatsApp />}
                variant='outlined'
                color="primary"
                fullWidth
                target='_blank'
                className={classes.button}
                component="a"
                href={link}
              >
                Enviar WhatsApp
              </Button>
            </ListItem>
          </Box>
        </DialogActions>
      </Dialog>
    ]
  );
};

CotizacionMedicos.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default CotizacionMedicos;
