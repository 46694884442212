import React, { Component, useEffect, useState } from 'react'
import { 
    Dialog,
    DialogContent,
    DialogTitle,
    Slide,
    Typography,
    IconButton,
    Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8
  },

}));

const Modal = ({
    open,
    onClose = () => {},
    children,
    title = '',
    disableCloseButton = false
}) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            transitionDuration={500}
            PaperProps={{ style: { opacity: 0.9, borderRadius: 16, maxWidth: 'calc(100vw - 50px)'} }}
            onClose={onClose}
        >
            <DialogTitle disableTypography>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" color="textSecondary">
                        {title}
                    </Typography>
                    {!disableCloseButton && (
                        <IconButton className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    )}
                </Box>
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    )
};

export default Modal;