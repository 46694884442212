import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, ListItem, Paper, Typography, Divider, Button, InputBase, Popover, ButtonGroup, List, debounce, CircularProgress, Dialog, Slide, DialogTitle, DialogActions } from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import useMeasure from 'react-use-measure'
import { Carousel } from 'react-responsive-carousel';
import internalAPI from '../../internalAPI';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import areas from '../../constants/areas.json';
import types from '../../constants/types.json';
import { ArrowForward } from '@material-ui/icons';
import { Modal } from '@material-ui/core';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import Delivery from 'components/Delivery';
import ModalInfoSelected from 'components/ModalInfoSelected';
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignContent: 'flex-start',
    },
    containerData: {
        animation: `$myEffect 500ms ${theme.transitions.easing.sharp}`,
    },
    "@keyframes myEffect": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    },
    container: {
        width: '100%',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        '& svg': {
            margin: theme.spacing(1.5),
        },
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
    },
    selector: {
        display: 'flex',
        flexWrap: 'wrap',
        height: 60,
        width: '100%',
        borderRadius: 16,
        backgroundColor: 'white',
        justifyContent: 'space-around',
        alignContent: 'flex-start',
        marginBottom: 60,

    },
    formContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
        maxWidth: 500,
        margin: `0 auto`,
    },
    box: {
        display: 'flex',
        height: '70vh',
        backgroundColor: 'red'
    },
    image: {
        width: '100%',
        maxWidth: 500,
        float: 'center',
        boxShadow: '1px 1px 5px gray',
        borderRadius: 10
    },
    iconButton: {
        position: 'fixed',
        top: 8,
        right: 8,
        color: 'rgba(200,200,200,0.8)',
        backgroundColor: theme.palette.primary.main,
        opacity: 0.9
    },
    section: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    label: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    containerTag: {
        flexDirection: 'column',
        borderRadius: 0,
        boxShadow: '0px 0px 10px gray',
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            borderRadius: 16,
            margin: '60px 0px 0px 0px',
        },
    },
    tagLeft: {
        backgroundColor: 'rgba(255,255,255,1)',
        padding: 30,
        [theme.breakpoints.up('md')]: {
            padding: 40,
        },
    },
    tagRight: {
    },
    input: {
        backgroundColor: 'rgba(255,255,255,1)',
        borderRadius: 16,
        border: `solid 0.5px rgba(255,255,255,0.2)`,
        padding: 8,
        width: '100%',
        margin: '24px 16px',
        height: 'auto',
        boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
        transitionDuration: 200,
        "&:focus,&:hover": {
            backgroundColor: 'rgba(255,255,255,1)',
            border: `solid 1px ${theme.palette.primary.main}`,
            boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
        },
        [theme.breakpoints.up('md')]: {
            margin: '60px 0px',
            height: 60,
        },
    },
    inputBase: {
        flex: 1,
        height: '100%',
        padding: '5px 16px',
        boxShadow: 'none',
        "&:focus,&:hover": {
            backgroundColor: 'inherit',
            border: 0,
            boxShadow: 'none',
        }
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Browser = (props) => {
    const selectType = types.find(e => e.id === props.view) || types[0];
    const classes = useStyles();
    const [screen, { height, width }] = useMeasure();
    const [anchor, { ah, aw }] = useMeasure();
    const [loading, setLoading] = useState(true);
    const [select, setSelect] = useState(null);
    const [openDialog, setOpen] = useState(false);
    const [area, setArea] = useState(null);
    const [type, setType] = useState(selectType);
    const [searchText, setSearchText] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElType, setAnchorElType] = useState(null);
    const [data, setData] = useState([]);

    const update = async (d, e) => {
        if (d && d.length) {
            setData(d)
        } else {
            setData([])
        }
    }
    const searchStudios = debounce((searchText) => {
        setLoading(true);
        switch (type.id) {
            case ('estudios'): {
                internalAPI.catalogos.getEstudios({ id_area: area ? area.id : undefined, searchText: searchText.length ? searchText : undefined }, update)
                break;
            }
            case ('paquetes'): {
                internalAPI.catalogos.getPaquetes({ id_area: area ? area.id : undefined, searchText: searchText.length ? searchText : undefined }, update)
                break;
            }
            default: {
                internalAPI.catalogos.getEstudios({ id_area: area ? area.id : undefined, searchText: searchText.length ? searchText : undefined }, update)
            }
        }
    }, 500)

    useEffect(() => {
        searchStudios(`${searchText || props.search || ''}`);
        setAnchorEl(null);
        setAnchorElType(null);
    }, [area, type, searchText])

    useEffect(() => {
        setLoading(false);
    }, [data])

    useEffect(() => {
        if (!!select) {
            setOpen(true)
        }
    }, [select])

    const isMobile = width < 600;
    console.log('ancho', anchorElType)
    return (
        <Box ref={screen} style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignContent: 'center' }}>
            <ResponsiveLayout >
                <Box className={classes.root}>
                    {
                        !props.withOutHeader &&
                        <Box style={{ width: '100%', alignSelf: 'center', fontSize: 24, fontWeight: 800, textAlign: 'center' }}>
                            <Grid container display='flex' className={classes.containerTag}>
                                <Grid className={classes.tagLeft} xs={isMobile ? 12 : 6} item >
                                    <Typography color='primary' variant='h5' style={{ textAlign: 'left', width: '100%', marginBottom: 20 }}>
                                        {type.name || 'Estudios'}
                                    </Typography>
                                    <Typography style={{ textAlign: 'left' }}>
                                        {type.description || 'Los exámenes de Laboratorio Clínico son una herramienta primordial ya que por medio de este se diagnostican diferentes patologías y además se realizan estudios para establecer el tipo de tratamiento que se debe disponer al paciente, al igual que el seguimiento del mismo.'}
                                    </Typography>
                                </Grid>
                                <Grid xs={isMobile ? 12 : 6} item style={{ marginBottom: -8 }}>
                                    <img className={classes.tagRight} src={'images/layouts/1.jpg'} width='100%' />
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    {/* <Box className={classes.input}> */}
                    <ButtonGroup orientation={isMobile ? 'vertical' : 'horizontal'} className={classes.input} variant="contained" color="primary" aria-label="contained primary button group">
                        <Button
                            variant={'text'}
                            endIcon={<ArrowDropDown />}
                            onClick={(e) => {
                                console.log('hola')
                                setAnchorElType(e.currentTarget);
                            }}
                        // onBlur={(e) => {
                        //     setAnchorEl(null);
                        // }}
                        // style={{ flex: 1, height: '100%', padding: '5px 16px', borderRight: '1px solid gray' }} 
                        >
                            {type ? type.name : 'ESTUDIOS'}
                        </Button>
                        {
                            type.id === 'estudios' &&
                            <Button
                                ref={anchor}
                                variant={'text'}
                                endIcon={<ArrowDropDown />}
                                onClick={(e) => {
                                    console.log('hola')
                                    setAnchorEl(e.currentTarget);
                                }}
                                // onBlur={(e) => {
                                //     setAnchorEl(null);
                                // }}
                                placeholder='Áreas o Servicios'
                            // style={{ flex: 1, height: '100%', padding: '5px 16px', borderRight: '1px solid gray' }} 
                            >
                                {area ? area.name : 'Áreas o Servicios'}
                                {data.length ? ` (${data.length})` : ''}
                            </Button>
                        }
                        <InputBase
                            id='searchField'
                            flex={1}
                            placeholder={`Buscar ${type.id}...`}
                            onChange={(e) => setSearchText(e.target.value)}
                            className={classes.inputBase}
                        />
                        {/* <ListItem divider
                            button
                        >
                            {'Especialidad'}
                        </ListItem> */}
                    </ButtonGroup>
                    <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Paper>
                            <List style={{ maxHeight: 200, width: '100%' }}>
                                {
                                    areas.map((item, index) => {
                                        return (
                                            <ListItem
                                                key={index}
                                                button
                                                onClick={() => {
                                                    setArea(item)
                                                }}
                                            >
                                                <Typography>
                                                    {item.name}
                                                </Typography>
                                            </ListItem>
                                        )
                                    })
                                }
                                <ListItem
                                    key={'todos'}
                                    button
                                    onClick={() => setArea(null)}
                                >
                                    <Typography>
                                        {'Todas'}
                                    </Typography>
                                </ListItem>
                            </List>
                        </Paper>
                    </Popover>
                    <Popover
                        open={!!anchorElType}
                        anchorEl={anchorElType}
                        onClose={() => setAnchorElType(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Paper>
                            <List style={{ maxHeight: 200, width: 'calc(100% - 8px)' }}>
                                {
                                    types.map((item, index) => {
                                        return (
                                            <ListItem
                                                key={index}
                                                button
                                                onClick={() => {
                                                    setType(item)
                                                }}
                                            >
                                                <Typography>
                                                    {item.name}
                                                </Typography>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </Paper>
                    </Popover>

                    {/* </Box> */}
                    <Divider />
                    {
                        loading ?
                            <Box style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignContent: 'center', padding: 80 }}>
                                <CircularProgress disableShrink />
                            </Box>
                            :
                            <Box style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignContent: 'center' }}>
                                {
                                    (data || []).map((item, i) => {
                                        let precio_publico = item.precio_publico;
                                        if ((item.tipo === 'Paquete' || item.tipo === 'Perfil') && item.detalle) {
                                            precio_publico = item.detalle.map(e => e.precio_publico).reduce((t, n) => (t * 1) + (n * 1));
                                        }
                                        const descuentoRatio = (((precio_publico - item.precio_bronce) / precio_publico) * 100).toFixed(0);
                                        const descuento = descuentoRatio < 1 ? 10 : descuentoRatio;

                                        return (
                                            <Paper
                                                className={classes.containerData}
                                                key={i}
                                                style={{ boxShadow: '0px 0px 20px rgba(100,100,100,0.5)', borderRadius: 30, position: 'relative', width: 340, height: 270, margin: '0px 0px 40px 0px', maxWidth: 'calc(100% - 40px)', padding: 8 }}>
                                                <Box p={1}>
                                                    <Typography
                                                        key={0}
                                                        variant='subtitle1'
                                                        style={{
                                                            width: 'calc(100% - 100px)',
                                                            color: 'gray',
                                                            fontWeight: 400,
                                                            fontSize: 12,
                                                            borderRadius: '0px 0px 0px 0px'
                                                        }}
                                                    >
                                                        {item.tipo || ''}
                                                    </Typography>
                                                    <Typography
                                                        key={1}
                                                        style={{
                                                            height: 35,
                                                            width: 'calc(100% - 100px)',
                                                            textJustify: 'initial',
                                                            fontWeight: 800,
                                                            fontSize: 12,
                                                            // background: 'linear-gradient(to bottom, rgba(100,100,100,0.8), rgba(100,100,100,0))',
                                                        }}
                                                    >
                                                        {`${item.nombre}`.replaceAll('PAQUETE ', '').split('[')[0].split('(')[0]}
                                                    </Typography>
                                                </Box>
                                                <Divider />
                                                <Box key={'body'} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                    <Typography
                                                        key={0}
                                                        style={{
                                                            height: 35,
                                                            width: 'calc(100% - 80px)',
                                                            textJustify: 'initial',
                                                            fontWeight: 800,
                                                            alignSelf: 'center',
                                                            textAlign: 'center',
                                                            fontFamily: '"Comic Sans", sans-serif',
                                                            fontSize: 30,
                                                            margin: 16
                                                            // background: 'linear-gradient(to bottom, rgba(100,100,100,0.8), rgba(100,100,100,0))',
                                                        }}
                                                    >
                                                        {`$ ${(((item.precio_bronce * 1).toFixed(0)).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))}`}
                                                    </Typography>
                                                    {
                                                        !!(descuentoRatio > 0) &&
                                                        <Typography
                                                            key={1}
                                                            variant='subtitle1'
                                                            style={{
                                                                width: 'calc(100% - 80px)',
                                                                color: 'gray',
                                                                fontWeight: 400,
                                                                alignSelf: 'center',
                                                                textAlign: 'center',
                                                                fontSize: 20,
                                                                borderRadius: '0px 0px 0px 0px',
                                                                textDecoration: 'line-through'
                                                            }}
                                                            pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                                                        >
                                                            {`$ ${(((precio_publico * 1).toFixed(0)).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))}`}
                                                        </Typography>
                                                    }
                                                </Box>
                                                {
                                                    !!(descuentoRatio > 0) &&
                                                    <div style={{
                                                        backgroundImage: 'url(images/etiqueta.png)',
                                                        position: 'absolute',
                                                        top: -16,
                                                        right: 20,
                                                        boxSizing: 'border-box',
                                                        height: 100,
                                                        width: 90,
                                                        padding: 0,
                                                        backgroundSize: '90px 100px',
                                                        backgroundPosition: 'center',
                                                        backgroundRepeat: 'no-repeat',
                                                        maskSize: 'contain',
                                                        maskImage: `url(images/etiqueta.png)`,
                                                        maskRepeat: 'no-repeat',
                                                        maskPosition: 'center',
                                                        alignSelf: 'center',
                                                    }}>
                                                        <Typography style={{ fontWeight: 800, fontSize: 20, position: 'absolute', width: '100%', textAlign: 'center', padding: 6, color: 'white', textShadow: 'initial', left: 4, bottom: 35 }}>
                                                            {`-${descuento || 10}%`}
                                                        </Typography>
                                                    </div>
                                                }
                                                <Box style={{
                                                    display: 'flex', flexDirection: 'column', position: 'absolute', left: 0, width: '100%', bottom: 16
                                                }}>
                                                    <Button onClick={() => {
                                                        setSelect(item)
                                                        setOpen(true)
                                                    }} variant='contained' color='secondary' style={{
                                                        // backgroundImage: 'linear-gradient(rgba(208,66,142, 0.5), rgba(77,172,170, 0.5))',
                                                        // backgroundSize: '100vw 100vh',
                                                        backgroundRepeat: 'no-repeat',
                                                        borderRadius: 30, height: 60, width: 250, alignSelf: 'center', padding: 16
                                                    }}>
                                                        {'Ver más'}
                                                    </Button>
                                                </Box>

                                            </Paper>
                                        )
                                    })
                                }
                            </Box>
                    }
                </Box>
                <ModalInfoSelected
                    openDialog={openDialog}
                    select={select}
                    setOpen={setOpen}
                    setSelect={setSelect}
                    type={type}
                />

            </ResponsiveLayout>
        </Box>
    );
};

export default Browser;
