import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  AccordionActions,
  Button,
  Grid,
  IconButton,
  Dialog,
  CircularProgress,
  debounce,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import dataDemo from '../../../../components/Charts/dataDemo.json'

import internalAPI from '../../../../utils/internalAPI';
import { ArrowRightOutlined, ChevronRightOutlined, CloudDownloadOutlined, FileCopyOutlined, PersonOutline } from '@material-ui/icons';
import DicomViewer from 'components/organisms/DicomViewer';
import ModalUI from 'components/containers/ModalUI';
import { useEffect } from 'react';
import { setListPatients, setPatient } from 'redux/studies';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const ListPatients = ({ type, modeView, searchText = '', startDate, endDate }) => {
  const dispatch = useDispatch()
  const [selected, setSelected] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { pacientes = [], page = 1, total = 0, query = {} } = useSelector(state => state.studies.listPatients || {});
  
  return (
    <Paper display={modeView === 'list' ? 'block' : 'none'} style={{ borderRadius: 16, overflow: 'hidden', boxShadow: '0px 0px 5px gray' }}>
      {(pacientes || []).map((item, index) => (
        <ListItem divider button key={index} expanded={false} onClick={() =>  dispatch(setPatient(item))}>
          <Grid container direction='row'>
            <Grid item xs={'auto'}>
              <IconButton>
                <PersonOutline />
              </IconButton>
            </Grid>
            <Grid item xs direction='column'>
              <Grid item xs={12} >
                <Typography variant='caption' style={{ marginRight: 8, fontWeight: 600 }}>
                {item.paciente}
                </Typography>
              </Grid>
              <Grid item xs={12} direction='row' container >
                <Grid item xs={'auto'} >
                  <Typography variant='caption' style={{ marginRight: 8, fontWeight: 600 }}>
                    {'Sexo:'}
                  </Typography>
                  <Typography variant='caption' style={{ width: '100%', textAlign: 'right' }} >
                    {item.sexo}
                  </Typography>
                </Grid>
                <Grid item xs />
                <Grid item xs={'auto'} >
                  <Typography variant='caption' >
                    Fecha Nac.: {item.fecha_nac}
                  </Typography>
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={'auto'}>
              <IconButton>
                <ChevronRightOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </ListItem>
      ))}
      <ModalUI
        open={!!selected}
        onClose={() => setSelected(null)}
        fullScreen
      >
        {/* {
          selected &&
          <iframe
            src={selected}
            id={'pdfViewer'}
            onLoad={() => setLoading(false)}
            onAbort={() => console.log('salio del load')}
            // name={gpcSelected.vc_nombre}
            style={{
              width: '100%',
              height: '100%',
              padding: 0,
              margin: 0,
              borderRadius: 5,
            }}

          />
          // <PDFViewer>
          //   <Document
          //     file={selected}
          //     onLoadSuccess={() => {}}/>
          //   </PDFViewer>
        } */}
        {
          loading &&
          <Box
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ backgroundColor: 'white' }}
          >
            <CircularProgress color={'primary'} />
            <Box>
              <Typography color={'primary'} style={{ marginTop: 8 }}>
                {'Cargando resultados'}
              </Typography>
              <Button fullWidth onClick={() => { setSelected(null); setLoading(false) }}>
                {'Cancelar'}
              </Button>
            </Box>
          </Box>
        }
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{ backgroundColor: 'white' }}
        />
      </ModalUI>
    </Paper>
  );
};

export default ListPatients;
