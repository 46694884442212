import React, { lazy, Suspense, useEffect } from 'react';
import 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import { useSpeechSynthesis } from 'react-speech-kit';
import internalAPI from 'internalAPI';

// lazy components
const StepCovid = lazy(() => import('./components/StepCovid'));
const ActionsButtons = lazy(() => import('./components/ActionsButtons'));
const SteepAddStudio = lazy(() => import('./components/SteepAddStudio'));
const SteepAddDomicilio = lazy(() => import('./components/SteepAddDomicilio'));
const StepProgramVisit = lazy(() => import('./components/StepProgramVisit'));

const useStyles = makeStyles(theme => ({
    box: {
        borderRadius: 20,
        backgroundColor: 'rgba(255,255,255, 1)',
        paddingTop: 58,
        paddingBottom: 76,
        overflowY: 'scroll',
        maxWidth: '100%',
        [theme.breakpoints.up('md')]: {
            width: '66vw',
            height: '90vh',
        },
    },
    list: {
        backgroundColor: 'rgba(255,255,255,1)',
        borderRadius: 16,
        border: `solid 0.5px rgba(255,255,255,0.2)`,
        width: '100%',
        minWidth: 200,
        marginBottom: 16,
        maxHeight: 300,
        boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
        overflowY: 'scroll',
        transitionDuration: 200,
        "&:focus,&:hover": {
            backgroundColor: 'rgba(255,255,255,1)',
            border: `solid 1px ${theme.palette.primary.main}`,
            boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 16,
        },
    },
    input2: {
        backgroundColor: 'rgba(255,255,255,1)',
        borderRadius: 16,
        border: `solid 0.5px rgba(255,255,255,0.2)`,
        padding: 8,
        width: '100%',
        minWidth: 200,
        marginBottom: 16,
        height: 'auto',
        boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
        transitionDuration: 200,
        "&:focus,&:hover": {
            backgroundColor: 'rgba(255,255,255,1)',
            border: `solid 1px ${theme.palette.primary.main}`,
            boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 16,
            height: 50,
        },
    },
    button: {
        backgroundColor: 'rgba(255,255,255,1)',
        borderRadius: 16,
        border: `solid 0.5px rgba(255,255,255,0.2)`,
        padding: 8,
        width: '100%',
        minWidth: 200,
        height: 'auto',
        boxShadow: '0px 0px 4px rgba(100,100,100,0.5)',
        transitionDuration: 200,
        "&:focus,&:hover": {
            backgroundColor: 'rgba(255,255,255,1)',
            border: `solid 1px ${theme.palette.primary.main}`,
            boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 16,
            height: 50,
        },
    },
    title: {
        paddingBottom: 16
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    },
    avatar: {
        display: 'flex',
        height: 50,
        width: 50,
        alignSelf: 'center',
        position: 'absolute',
        top: 16,
        left: 20
    },
}));
const Delivery = ({ covidSelector = true, homeCare = true }) => {
    const classes = useStyles();
    const [deliveryData, setDeliveryData] = React.useState({ orders: [{}] });
    const [activeStep, setActiveStep] = React.useState(0);
    const [audio, setAudio] = React.useState(false);
    const [selectorData, setSelectorData] = React.useState({ id: 7, estudios: [], texto: '' });
    const { speak, speaking, cancel } = useSpeechSynthesis();

    const onChange = ({ address_components, geometry = {} }) => {
        const data = { ...deliveryData };
        (address_components || []).map((item) => {
            if (item.types.length)
                switch (item.types[0]) {
                    case 'street_number': {
                        data.streetNumber = item.long_name
                        break;
                    }
                    case 'route': {
                        data.street = item.long_name
                        break;
                    }
                    case 'neighborhood': {
                        data.neighborhood = item.long_name
                        break;
                    }
                    case 'locality': {
                        data.locality = item.long_name
                        break;
                    }
                    case 'administrative_area_level_1': {
                        data.state = item.long_name
                        break;
                    }
                    case 'postal_code': {
                        data.postalCode = item.long_name
                        break;
                    }
                    case 'country': {
                        data.country = item.long_name
                        break;
                    }
                    default: {
                        break;
                    }
                }
        });
        if (geometry.location) {
            data.location = geometry.location;
        }
        setDeliveryData(data)

    }
    const selectorStructure = [
        {
            id: 7,
            vc_name: '¿Deseas guía auditiva?',
            options: [
                { vc_name: 'Si', id_enlace: 1, info: '', estudio: [3970], action: () => setAudio(true) },
                { vc_name: 'No', id_enlace: 1, info: '', estudio: [4336] },

            ]
        },
        {
            id: 1,
            vc_name: '¿Sabes qué estudio de COVID-19 necesitas?',
            finish: true,
            options: [
                { vc_name: 'PCR SARS-CoV-2', id_enlace: null, info: '(Primera opción de diagnóstico, resultados en menos de 24 h.)', estudio: [3970] },
                { vc_name: 'Antígeno SARS-CoV-2', id_enlace: null, info: '(Prueba rápida, resultado en 30 minutos, recomendado a pacientes con sintomas.', estudio: [4336] },
                { vc_name: 'Anticuerpos SARS-CoV-2', id_enlace: 2, info: '', estudio: [4336] },
                { vc_name: 'Ayúdame a elegir', id_enlace: 3, info: '', estudio: [] },

            ]
        },
        {
            id: 2,
            vc_name: 'Estas son las prueba de Anticuerpos para COVID-19 disponibles',
            finish: true,
            options: [
                { vc_name: 'IgG e IgM (cuantitativa)', id_enlace: null, info: '', estudio: [4086] },
                { vc_name: 'IgG (prueba rápida)', id_enlace: null, info: '', estudio: [4016] },
            ]
        },
        {
            id: 3,
            vc_name: '¿Qué necesitas saber?',
            options: [
                { vc_name: 'Si tengo COVID-19', subTitle: '(Detección del virus)', id_enlace: 4, info: '', estudio: [] },
                { vc_name: 'Si generé anticuerpos', subTitle: '(Post infección del virus)', id_enlace: 5, info: '', estudio: [] },
            ]
        },
        {
            id: 4,
            vc_name: 'Estas dos pruebas te ayudarán',
            finish: true,
            options: [
                { vc_name: 'PCR SARS-CoV-2', subTitle: '(Recomendada, más eficaz, resultado en menos de 24 h.)', id_enlace: null, info: '', estudio: [3970] },
                { vc_name: 'Antígeno SARS-CoV-2', subTitle: '(Más rapida, resultados en 30 minutos.)', id_enlace: null, info: '', estudio: [4336] },
            ]
        },
        {
            id: 5,
            vc_name: 'Tenemos estas opciones de detección de Anticuerpos para COVID-19',
            finish: true,
            options: [
                { vc_name: 'IgG e IgM (cuantítativa)', subTitle: '(Anticuerpos completos)', id_enlace: null, info: '', estudio: [4086] },
                { vc_name: 'IgG (prueba rápida)', subTitle: '(Anticuerpos de memoria)', id_enlace: null, info: '', estudio: [4016] },
            ]
        }
    ];
    
    useEffect(() => {
        const s = selectorStructure.find(e => e.id === selectorData.id);
        if (audio && !!s) {
            console.log('hola', s)
            speak({ text: s.vc_name, voice: window.speechSynthesis.getVoices().filter(e => e.lang === 'es-MX')[0] || undefined })
        }
    }, [selectorData])

    const selected = selectorStructure.find(e => e.id === selectorData.id);

    async function getDates() {
        const response = await internalAPI.homeCare.getDates({});
        console.log('response', response)
    }
    useEffect(() => {
        console.log('responseddd')
        getDates();
    }, [])

    const onclickOption = option => {
        console.log("holqaaa");
        if (option.id_enlace) {
            setSelectorData({
            id: option.id_enlace,
            estudios: [...selectorData.estudios, ...(option.estudios || [])]
            });
        } else if (selected.finish) {
            setSelectorData({
            id: null,
            estudios: [...selectorData.estudios, ...(option.estudios || [])]
            });
            setActiveStep(activeStep + 1);
        }
        option.action && option.action();
    };

    const listStepper = [
        covidSelector && {
            title: selected ? selected.vc_name : 'Elige el mejor estudio para ti',
            content: (<Suspense fallback={<div>cargando...</div>}>            
                <StepCovid
                    classes={classes}
                    onclickOption={onclickOption}
                    selected={selected}
                />
            </Suspense>),
            key: 'selecto',
        },
        {
            title: 'Selecciona tus estudios?',
            content: <Suspense fallback={<div>cargando...</div>}>
                <SteepAddStudio
                    orders={deliveryData.orders}
                    onClickBtn={() => {
                        console.log('chhange person.');
                        return;
                        // const newData = { ...deliveryData };
                        // newData.orders.push({});
                        // setDeliveryData({ ...newData })
                    }}
                    onChange={(e,index) => {
                        const newData = { ...deliveryData };
                        newData.orders[index].estudios = e;
                        setDeliveryData({ ...newData })
                    }}
                />
            </Suspense>,
            key: 0,
        },
        !!homeCare && {
            title: 'Especifica tu domicilio',
            content: <Suspense fallback={<div>cargando...</div>}>
                <SteepAddDomicilio
                    deliveryData={deliveryData}
                    onChange={onChange}
                    onChangeLocation={(l) => setDeliveryData({ ...deliveryData, location: l })}
                />
            </Suspense>,
            key: 1,
        },
        {
            title: 'Programa la visita a tu domicilio',
            content: <Suspense fallback={<div>cargando...</div>}>
                <StepProgramVisit
                    classes={classes}
                    deliveryData={deliveryData}
                    setDeliveryData={setDeliveryData}
                />
            </Suspense>,
            key: 2,
        },
    ];

    return (<form onSubmit={(e) => {
        e.preventDefault();
        setActiveStep(activeStep + 1);
    }} >
        <Box p={1} className={classes.box}>
            
            <Avatar src={'/images/logos/logo-login.svg'} aria-label="recipe" className={classes.avatar} />

            <Stepper activeStep={activeStep} orientation="vertical" style={{ backgroundColor: 'transparent' }}>
                {/* mapper of steppers */}
                {listStepper.filter(e=>!!e).map(({key,title,content},index)=>(<Step onClick={()=>setActiveStep(index)} key={key}>
                    <StepLabel>{title}</StepLabel>
                    <StepContent style={{ width: '100%',overflowY: 'scroll', maxHeight: '100%', }}>
                        <Box p={1}> {content} </Box>
                    </StepContent>
                </Step>))}
            </Stepper>
            
            {/* actions btn */}
            <Suspense fallback={<div>cargando...</div>}>
                <ActionsButtons
                    onBack={() => setActiveStep(activeStep - 1 || 0)}
                    onNext={() => setActiveStep(activeStep + 1)}
                />
            </Suspense>
            
        </Box >
    </form>
    )
}

export default Delivery;