
import { Box, debounce, Fab, IconButton, makeStyles, Paper } from '@material-ui/core';
import { AccessibilityNewOutlined, FileCopy, FileCopyOutlined, Hearing, VolumeOff } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import dataDemo from './dataDemo.json'
import { useSpeechSynthesis } from 'react-speech-kit';
import Models3D from 'components/Models3D';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab';

var es = require("apexcharts/dist/locales/es.json")
const useStyles = makeStyles(theme => ({
    slider: {
        backgroundColor: 'rgba(200,200,200,0.0)',
        borderRadius: 10,
        border: `solid 0.5px rgba(200,200,200,0.2)`,
        padding: 8,
        transitionDuration: 1000,
        width: '100%',
        overflow: 'hidden',
        "&:focus,&:hover": {
            width: '80%',
            boxShadow: '0px 4px 8px rgba(100,100,100,0.5)',
        }
    }
}));

const state = {

    series: [{
        name: 'series1',
        data: [31, 40, 28, 51, 42, 109, 100]
    }, {
        name: 'series2',
        data: [11, 32, 45, 32, 34, 52, 41]
    }],
    options: {
        title: {
            text: undefined,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '14px',
                fontWeight: 'bold',
                fontFamily: undefined,
                color: '#263238'
            },
        },
        legend: {
            show: false
        },
        markers: {
            size: 4,
            colors: ["lightGray"],
            strokeColors: "#fff",
            strokeWidth: 2,
            hover: {
                size: 7,
            }
        },
        stroke: {
            width: [7,7,1],
            curve: 'smooth'
        },
        colors: ['#7adec0'],
        chart: {
            height: 350,
            type: 'line',
            locales: [es],
            defaultLocale: 'es',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            }
        },
        xaxis: {
            type: 'datetime',
            categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        },
        dataLabels: {
            enabled: true,
            offsetY: -8,
            style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: ['black']
            },

            background: {
                enabled: false,
                foreColor: '#fff',
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#fff',
                opacity: 0.9,
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45
                }
            }
        },
        tooltip: {
            x: {
                format: 'dd/MMMM/yyyy'
            },
        },
    },
}


const getData = () => {
    const groups = [];
    let texting = `Se realizan estudios de laboratorio: \n`;

    dataDemo.map((i, index) => {
        const maxs = []
        const mins = []
        const valores = []
        const categories = []
        let speech = `Se ${i.resultados.length === 1 ? 'tiene un' : `tienen ${i.resultados.length}`} registros de ${i.estudio}, `;
        texting = `${texting}${i.estudio}`
        i.resultados.map((e, index) => {
            const item = (e.analitos.resultados[0] || {}) || {};
            const valRef = (e.analitos.resultados[0] || {}).valref || {};
            const valor = item.resultado * 1
            const minVR = valRef.vr_min * 1
            const maxVR = valRef.vr_max * 1
            const texto = valRef.txt
            const fecha = new Date(e.fecha) * 1;
            console.log(fecha);
            categories.push(fecha)
            speech = `${speech} ${index === 0 ? 'uno ' : 'otro'} el ${new Date(e.fecha).toLocaleDateString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} con un resultado de ${valor} ${item.unidad}`
            switch (true) {
                case (valor > maxVR && maxVR !== NaN): {
                    speech = `${speech} el cual está por encima del rango de referencia, `
                    if (index + 1 === i.resultados.length) {
                        texting = `${texting}: ${valor} ${item.unidad} (↑), `
                    }
                    break;
                }
                case (valor < minVR && minVR !== NaN): {
                    speech = `${speech} el cual está por debajo del rango de referencia, `
                    if (index + 1 === i.resultados.length) {
                        texting = `${texting}: ${valor} ${item.unidad} (↓), `
                    }
                    break;
                }
                case (valor > minVR && valor < maxVR && minVR !== NaN && maxVR !== NaN): {
                    speech = `${speech} el cual está dentro del rango de referencia, `
                    if (index + 1 === i.resultados.length) {
                        texting = `${texting}: ${valor} ${item.unidad} (✔), `
                    }
                    break;
                }
                default: {
                    if (index + 1 === i.resultados.length) {
                        texting = `${texting}: ${valor} ${item.unidad}, `
                    }
                    break;
                }
            }
            if (minVR !== undefined && minVR !== NaN) {
                mins.push(minVR)
            }
            if (maxVR !== undefined && maxVR !== NaN) {
                maxs.push(maxVR)
            }
            if (valor !== undefined && valor !== NaN) {
                valores.push(valor)
            }
        })
        const series = [];
        const options = { ...state.options }
        // if (maxs.length) {
        //     series.push({
        //         name: 'Max.',
        //         data: maxs,

        //     })
        // }
        if (valores.length) {
            series.push({
                name: i.estudio,
                data: valores
            })
        }
        // if (mins.length) {
        //     series.push({
        //         name: 'Min.',
        //         data: mins,
        //     })
        // }
        if (categories.length) {
            options.xaxis.categories = categories;
        }
        let colors = [];
        let stops = [];
        switch (true) {
            case (!!(mins.length && maxs.length)): {
                colors = ['#ff3636', '#7adec0', '#ff3636'];
                stops = []
                break;
            }
            case (mins.length): {
                colors = ['#ff3636', '#7adec0'];
                stops = [0, mins[0]]
                break;
            }
            case (maxs.length): {
                colors = ['#7adec0', '#ff3636'];
                stops = [0, maxs[0]]
                break;
            }
            default: {
                colors = ['#7adec0'];
                stops = []
            }
        }
        console.log('cllores', colors, stops)
        groups.push({
            nombre: i.estudio,
            series,
            speech,
            options: {
                ...options,
                title: { text: i.estudio },
                xaxis: {
                    type: 'datetime',
                    // tickAmount: 10,
                    categories: [...categories]
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        gradientToColors: [...colors],
                        shadeIntensity: 1,
                        type: 'vertical',
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [...stops]
                    },
                }
            }
        });
    })
    console.log('holaaaaa', groups)

    return { groups, texting };
}
const Charts = () => {
    console.log('holaaaaa')
    const classes = useStyles();
    const [showAnatomy, setShowAnatomy] = useState(false);
    const [items, setItems] = useState([]);
    const [open, setOpen] = useState(false);
    const { speak, speaking, cancel } = useSpeechSynthesis();
    useEffect(() => {
        setItems(getData().groups);
    }, [])
    
    const getNote = async () => {
        await navigator.clipboard.writeText(getData().texting);
    }
    const actions = [
        { icon: <FileCopyOutlined />, name: 'Copiar', action: getNote },
        { icon: <AccessibilityNewOutlined />, name: 'Avatar', action: () => setShowAnatomy(!showAnatomy) },
        // { icon: <PrintOutlined />, name: 'Print' },
        // { icon: <ShareIcon />, name: 'Share' },
        // { icon: <FavoriteIcon />, name: 'Like' },
    ];
    
    return (
        <Box>
            {
                items.map((item, index) => {
                    console.log('item.series', item.series)
                    return (
                        <Paper key={index} id="chart" style={{ borderRadius: 10, boxShadow: '1px 1px 5px rgba(200,200,200,0.5)', marginBottom: 16, padding: 8 }}>
                            <ReactApexChart options={item.options} series={item.series} type={item.options.chart.type} height={350} />
                            <Box style={{ position: 'absolute', top: 8, right: 8, zIndex: 5 }}>
                                <IconButton style={{ marginRight: 5 }} size='small' onClick={() => {
                                    if (!speaking) {
                                        console.log('window.speechSynthesis.getVoices()', window.speechSynthesis.getVoices().filter(e => e.lang === 'es-MX'))
                                        speak({ text: item.speech, voice: window.speechSynthesis.getVoices().filter(e => e.lang === 'es-MX')[0] || undefined })
                                    } else {
                                        cancel()
                                    }
                                }}>
                                    {
                                        !speaking ?
                                            <Hearing /> :
                                            <VolumeOff />
                                    }
                                </IconButton>
                            </Box>
                        </Paper>
                    )
                })
            }
            {/* {showAnatomy &&
                <Models3D />
            } */}
            <SpeedDial
                ariaLabel="SpeedDial example"
                hidden={false}
                icon={<SpeedDialIcon />}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                direction={'right'}
                style={{ position: 'fixed', bottom: 16, left: 16, zIndex: 1000 }}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => {
                            setOpen(false)
                            action.action && action.action()
                        }}
                    />
                ))}
            </SpeedDial>
            <Fab
                color="primary"
                aria-label="add"
                onClick={getNote}
                style={{ position: 'fixed', bottom: 16, left: 16, zIndex: 1000 }}
            >
                <FileCopy />
            </Fab>
        </Box>
    );
}

export default Charts;